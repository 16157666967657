import styled from "styled-components";
import '../../assets/font.css'
import { Icon } from "../Main/styles";

export const SideBarWrapper = styled.div`
  width: 224px;
  background-color: #2E2559;
  color: #898989;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 2px 2px 8px 2px #74747476;
`
export const SideBarTitle = styled.div`
  width: 100%;
  height: 60px;
  font-size: 20px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #010101;
  font-weight: 700;
`

export const SideBarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
    padding: 14px 0;
`
export const Menu = styled.div`
  width: 100%;
  height: 42px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  cursor: pointer;
  transition: all.3s;
  ${Icon} {
    color: #878787;
  }
  
  &.selected {
    background-color: #fff;
    color: #878787;
    ${Icon} {
      color: #878787;
    }
  }
`
// MENU HOVER
//   &:hover {
//   background-color: #fff;
//   color: #878787;
//   ${Icon} {
//     color: #878787;
//   }
// }