import styled from "styled-components";

interface DBTableProps {
  border: string;
  color: string;
}

export const DBFilterWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 2px 2px 8px 2px #74747476;
  padding: 28px;
  .ant-table-content {
    font-family: 'Pretendard';
    .ant-table-thead > tr > th {
      text-align: center;
    }
    .name, .tel, .date, .manager, .platform, .path {
      text-align: center;
    }
    .money {
      text-align: right;
    }
  }
  .DBTable {
    .ant-table-tbody > tr > td {
      padding: 0;
      vertical-align: middle;
      > div {
        height: 43px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .BoardTable {
    .ant-table-thead > tr > th {
      &:first-child {
        width: 50px;
      }
    }
    .ant-table-tbody > tr {
      &.answered {
        color: red !important;
        font-weight: 600 !important;
        text-decoration: line-through !important;
        > .ant-table-cell-row-hover {
          background-color: #db969692;
        }
      }
      &.hold {
        color: blue !important;
        font-weight: 600 !important;
        > .ant-table-cell-row-hover {
          background-color: #96b2db92;
        }
      }
    }
    .ant-table-tbody > tr > td {
      &:nth-child(4) {
        width: 230px;
        text-decoration: underline;
        cursor: pointer;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        &.request {
          width: 230px;
          justify-content: flex-start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .ant-pagination {
    justify-content: center;
  }
`
export const DBFilterCheckboxArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const DBFilterDateArea = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const Checkboxs = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
export const CheckboxUnit = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  label {
    font-size: 14px;
    font-weight: 500;
    user-select: none;
  }
`
export const CheckboxColor = styled.div`
  width: 16px;
  height: 16px;
`
export const GotoStatus = styled.button`
  padding: 8px 12px;
  color: #fff;
  font-family: 'Pretendard';
  background-color: #1F75D1;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
`
export const DateArea = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  label {
    font-weight: 500;
    min-width: 60px;
    user-select: none;
    text-align: center;
  }
  .ant-select-selector {
    font-family: 'Pretendard';
    min-width: 100px;
  }
  .searchAddress {
    background-color: #d1d0d0;
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    min-width: 80px;
    border: none;
    font-family: 'Pretendard';
  }
`
export const DBTableBtn = styled.button<DBTableProps>`
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid ${props => props.border || 'black'};
  color: ${props => props.color || 'black'};
  padding: 8px 12px;
  font-family: 'Pretendard';
  font-weight: 500;
  transition: all.3s;
  &:hover {
    background-color: ${props => props.border || 'black'};
    color: #fff;
  }
`
export const DBTableExcelBtn = styled.button`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: white;
  background-color: #0800A8;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  transition: all.3s;
  &:hover {
    background-color: green;
    color: #fff;
  }
`
export const DBTableResultTxt = styled.p`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #8b8a8a;
  span {
    font-size: 22px;
    color: red;
    font-weight: 700;
  }
`