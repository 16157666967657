import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";


export const HomeTownSignUpModal = ({setIsSignUpModalOpen, setIsCustomerSignUpModalOpen, setIsRealEstateSignUpModalOpen}) => {
    const [cardSelected, setCardSelected] = React.useState('customerCard');



    const NextStep = () => {
        if (cardSelected === 'customerCard') {
            setIsCustomerSignUpModalOpen(true);
        } else {
            setIsRealEstateSignUpModalOpen(true);
        }
        setIsSignUpModalOpen(false);
    }

    return (
        <>
            <Blacked onClick={() => setIsSignUpModalOpen(false)}  />
            <ModalWrapper>
                <ModalHeader>
                    <ModalTitle>회원가입</ModalTitle>
                    <CloseIconSVG as={ModalCloseIcon} onClick={() => {setIsSignUpModalOpen(false)}  }/>
                </ModalHeader>

                <Title>고객님의 유형을 선택해주세요</Title>

                <ItemSection>
                    <CardItem onClick={() => {setCardSelected('customerCard')}} Active={cardSelected === 'customerCard'}>
                        <CardSVG as={CustomerCard}/>
                        <CardTitle>광고를 원하는 고객님</CardTitle>
                    </CardItem>

                    <CardItem onClick={() => {setCardSelected('realestateCard')}} Active={cardSelected === 'realestateCard'}>
                        <CardSVG as={RealEstateCard}/>
                        <CardTitle>부동산중개사무소</CardTitle>
                    </CardItem>
                </ItemSection>

                <NextButton id={'recaptcha-container'} onClick={() => NextStep()}>
                    다음
                </NextButton>
            </ModalWrapper>
        </>
    )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    min-width: 580px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Title = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
    color: #000000;
    text-align: center;
    margin-top: 20px;
`

const ItemSection = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
`

const CardSVG = styled.svg`
    width: 256px;
`

const CardItem = styled.div<{Active:boolean}>`
    width: 256px;
    height: 220px;
    border: ${(props) => props.Active ? '1px solid #530DAD' : '1px solid #0000000D'};
    background: ${(props) => props.Active ? '#F5EEFF' : '#FFFFFF'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   
    gap: 16px;
    cursor: pointer;
`

const CardTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #000000;
`

const NextButton = styled.div`
    width: 100px;
    height: 40px;
    border-radius: 2px;
    background-color: #530DAD;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
`