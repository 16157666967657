import useSWR from 'swr'
import {useToken} from "../../../app/hooks";
import API from "../../../utils/api";


const KEY = '/user/info' // 사용자 목록 조회

export function useUserInfo() {
	const { data: token } = useToken()
	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { userInfo: data, mutateUserInfo: mutate };
}

async function fetchAPI(KEY:string, token:any,) {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return response.result?.result || {};
	} catch (error) {
		console.error('Failed to fetch UserInfo:', error);
		return [];
	}
}