import React from "react";
import styled from "styled-components";
import { HomeTownRoundCheckbox } from "../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";

const OrderFilter = ({ type = "", order, setOrder }) => {
  return (
    <RoundCheckWrapper>
      <HomeTownRoundCheckbox
        text={"최신순"}
        checked={order === "latest"}
        onClick={() => {
          setOrder("latest");
        }}
      />
      <HomeTownRoundCheckbox
        text={"과거순"}
        checked={order === "oldest"}
        onClick={() => {
          setOrder("oldest");
        }}
      />
    </RoundCheckWrapper>
  );
};

const RoundCheckWrapper = styled.div`
  display: flex;
  gap: 14px;
`;

export default React.memo(OrderFilter);
