import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useUserList } from "../../../CRM/hooks/Tosme/UserManage/GetUserList";
import { Display } from "../../../CRM/organism/Main/styles";
import { UserManageFilter } from "./UserManageFilter";
import { UserManageTable } from "./UserManageTable";

export const UserManage = () => {
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [categoryItem, setCategoryItem] = useState("광고주");

  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<"latest" | "oldest">("latest");
  const [type, setType] = useState<"ad" | "agent">("ad");
  const [status, setStatus] = useState<0 | 1>(1);
  const [searchWord, setSearchWord] = useState("");

  const { userList, mutateUserList, isLoading, total } = useUserList(
    page,
    pageSize,
    type,
    status,
    searchWord,
    order
  );

  useEffect(() => {
    if(categoryItem == "광고주"){
      setType("ad");
      setStatus(1);
    }else if(categoryItem == "매체주"){
      setType("agent");
      setStatus(1);
    } else if(categoryItem == "회원신청목록"){
      setType("agent");
      setStatus(0);
    }
  }, [categoryItem]);

  useEffect(() => {
    console.log('selectedRows', selectedRows)
  }, [selectedRows]);

  return (
    <Display>
      <SelectCategory>
        <SelectItem
          onClick={() => setCategoryItem("광고주")}
          Active={categoryItem === "광고주"}
        >
          광고주
        </SelectItem>
        <SelectItem
          onClick={() => setCategoryItem("매체주")}
          Active={categoryItem === "매체주"}
        >
          매체주
        </SelectItem>
        <SelectItem
          onClick={() => setCategoryItem("회원신청목록")}
          Active={categoryItem === "회원신청목록"}
        >
          회원신청목록
        </SelectItem>
      </SelectCategory>

      <UserManageFilter
        setSearchWord={setSearchWord}
        setOrder={setOrder}
        order={order}
        mutateUserList={mutateUserList}
        categoryItem={categoryItem}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
      />

      <UserManageTable
        orderList={userList}
        mutateUserList={mutateUserList}
        categoryItem={categoryItem}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        total={total}
      />
    </Display>
  );
};

const SelectCategory = styled.div`
  display: flex;
  gap: 20px;
`;

const SelectItem = styled.div<{ Active: boolean }>`
  font-weight: ${(props) => (props.Active ? "700" : "500")};
  font-size: 24px;
  line-height: 28.64px;
  font-family: Pretendard, sans-serif;
  color: ${(props) => (props.Active ? "#2E2559" : "#00000066")};
  text-decoration: ${(props) => (props.Active ? "underline" : "none")};
  cursor: pointer;
`;
