import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/agent/setting' // 광고목록 조회

const fetchKey = () =>
	`${M_KEY}`;

export function useAgentSettingList() {
	const { data: token } = useToken();

	const KEY = fetchKey();


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate, isLoading } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { agentSettingList: data?.list, mutateAgentSettingList: mutate, total: data?.total, isLoading };
}

async function fetchAPI(KEY:string, token:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Agent Setting List:', error);
		return { list: [], total: 0 };
	}
}