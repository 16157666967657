import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {ReactComponent as DownloadIcon} from "../assets/DownloadIcon.svg";
import {ReactComponent as LeftArrowIcon} from "../assets/LeftArrowIcon.svg";
import {ReactComponent as RightArrowIcon} from "../assets/RightArrowIcon.svg";
import {useAgentInquireInfo} from "../../../hooks/Agent/InquireList/GetAgentInquireInfo";
import {useAgentInquireAnswerInfo} from "../../../hooks/Agent/InquireList/GetAgentInquireAnswerInfo";
import {Input, message, Select} from "antd";
import API from "../../../../utils/api";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";
import TextArea from "antd/es/input/TextArea";
import {useToken} from "../../../../app/hooks";
import {useAgentMessageInfo} from "../../../hooks/Agent/InquireList/GetAgentMessageInfo";
import {useAdminPaymentADList} from "../../../hooks/Admin/PaymentManage/GetAdminPaymentADList";
import {useAdminPaymentAgentList} from "../../../hooks/Admin/PaymentManage/GetAdminPaymentAgentList";
import {useUserList} from "../../../../CRM/hooks/Tosme/UserManage/GetUserList";

export const AdminMessageDetail = ({setMessageItemId, messageItemId, setIsSendMessage, isSendMessage, mutateAdminMessageList}) => {
  // 상태값
  const [fileList, setFileList] = React.useState<any>([]);
  const [localFileList, setLocalFileList] = React.useState<any>([]);
  const [content, setContent] = React.useState<any>('');
  const [title, setTitle] = React.useState<any>('');
  const [target, setTarget] = React.useState<any>('');
  const [targetType, setTargetType] = React.useState<any>(1);
  const [userList, setUserList] = React.useState<any>([]);
  const [receiver, setReceiver] = React.useState<any>(null);
  const [deleteFileList, setDeleteFileList] = React.useState<any>([]);

  //유틸리티
  const [fileInputKey, setFileInputKey] = useState(Date.now()); // 파일 초기화
  const [userType, setUserType] = useState<'ad' | 'agent'>('ad')

  // 데이터 조회
  const {agentMessageInfo, mutateAgentMessageInfo} = useAgentMessageInfo(messageItemId);
  const {userList: senderUser, mutateUserList} = useUserList(
    1,
    1000,
    userType,
    1,
    '',
    "latest"
  );
  const {data: token} = useToken();


  useEffect(() => {
    setLocalFileList(agentMessageInfo?.fileList || []);
    setContent(agentMessageInfo?.content || '');
    setTitle(agentMessageInfo?.title || '');
    setTargetType(agentMessageInfo?.targetType || '');
    setReceiver(Number(agentMessageInfo?.target) || null)
    // setReceiver(Number(agentMessageInfo?.target) || '');
  }, [agentMessageInfo]);



  useEffect(() => {
      if(targetType === 1) {
        setUserType('agent');
      } else {
        setUserType('ad');
      }

  }, [targetType]);


  const postSendMessage = () => {
    const shouldEdit = window.confirm(`메세지를 전송하시겠습니까?`);
    if (shouldEdit) {
      API.post(`/board/message/list`, token + '', {
        target :receiver ? String(receiver) : '',
        title,
        content,
        fileList
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success('전송되었습니다.');
            setIsSendMessage(false);
            mutateAdminMessageList()
          } else {
            message.error(res.result.message || '실패하였습니다.')
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }


  const putEditMessage = () => {
    const shouldEdit = window.confirm(`메세지를 수정하시겠습니까?`);
    if (shouldEdit) {
      API.put(`/board/message/list/${messageItemId}`, token + '', {
       datas: {
         target: receiver ? String(receiver) : '',
         title,
         content,
         fileList,
         deleteFileList
       }
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success('전송되었습니다.');
            setMessageItemId(null);
            mutateAdminMessageList();
          } else {
            message.error(res.result.message || '실패하였습니다.')
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }


  const UploadData = (file, fileType) => {
    if (!file) {
      message.error('파일을 선택하세요');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);


    API.postImage('POST', '/content?type=user', '', formData)
      .then((res) => {
        if (res.message === 'success') {
          message.success('파일이 추가되었습니다.');
          console.log(res)

          const url = res?.result?.url;
          const name = res?.result?.name;

          setFileList([...fileList, {name, fileType, url}])
          setFileInputKey(Date.now())
        } else {
          message.error('파일이 잘못되었습니다');
        }
      })
      .catch((err) => {
        message.error('업로드 중 오류가 발생했습니다');
        console.log('Upload Data ERROR', err)
      });
  };

  // const DeleteFileListData = (name, index) => {
  //   const shouldEdit = window.confirm(`${name}을 삭제하시겠습니까?`);
  //   if (shouldEdit) {
  //     setDeleteFileList([...deleteFileList, fileList[index].urlId]);
  //     setFileList((prevFileList) => prevFileList.filter((_, i) => i !== index));
  //   }
  // };


  const handleDownload = async (url, name) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.style.display = 'none';
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      message.error('다운로드에 실패하였습니다.');
    }
  };

  const handleDownloadAll = async (fileList) => {
    try {
      await Promise.all(
        fileList.map(file => handleDownload(MEDIA_URL + file.url, file.name))
      );
      message.success('모든 파일을 저장하였습니다.');
    } catch (error) {
      message.error('모든 파일 저장에 실패하였습니다.');
    }
  };


  const CommercialStateItem = [
    {
      name: '매체주',
      value: 1
    },
    {
      name: '광고주',
      value: 2
    },
  ]


  return (
    <>
      <Blacked onClick={() => {
        setMessageItemId(null)
        setIsSendMessage(false)
      }}/>
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>발신내역 상세</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {
            setMessageItemId(null)
            setIsSendMessage(false)
          }}/>
        </ModalHeader>

        <Table>
          <tbody>
          <TableRow>
            <Th>
              <ThLabel>
                제목
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              <InputText value={title} onChange={(e) => setTitle(e.target.value)}/>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                구분
              </ThLabel>
            </Th>
            <Td>
              <StyledSelect value={targetType} onChange={(e) => setTargetType(e as number)}>
                {CommercialStateItem?.map((item, index) => (
                  <Select.Option value={item.value}>{item.name}</Select.Option>
                ))}
              </StyledSelect>
            </Td>

            <Th>
              <ThLabel>
                수신자
              </ThLabel>
            </Th>
            <Td>
              <StyledSelect value={receiver} onChange={(e) => setReceiver(e as number)}>
                {senderUser?.map((item, index) => (
                  <Select.Option value={item.userId}>{item.name}</Select.Option>
                ))}
              </StyledSelect>
            </Td>
          </TableRow>
          {!isSendMessage &&
            <TableRow>
              <Th>
                <ThLabel>
                  작성일
                </ThLabel>
              </Th>
              <Td>
                <InputText value={agentMessageInfo?.createdAt || '-'} disabled/>
              </Td>

              <Th>
                <ThLabel>
                  수신여부
                </ThLabel>
              </Th>
              <Td>
                <InputText value={'-'} disabled/>
              </Td>
            </TableRow>
          }
          <TableRow>
            <Th>
              <ThLabel>
                첨부파일
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              <div>
                <div
                  style={{display: 'flex', fontWeight: '500', fontSize: 14, lineHeight: '16.71px', marginBottom: 12}}>
                  {`${fileList?.length || 0}건`}
                  {/*<div style={{fontWeight: '300', color: '#00000066'}}>(1.7MB) |</div>*/}
                  <div onClick={() => handleDownloadAll(fileList)} style={{
                    fontWeight: '400',
                    color: '#125DE6CC',
                    textDecoration: 'underline',
                    marginLeft: 6,
                    cursor: 'pointer'
                  }}>모두저장
                  </div>
                </div>

                {/*{fileList?.map((item, index) => (*/}
                {/*  <div style={{*/}
                {/*      backgroundColor: '#FAFAFC',*/}
                {/*      width: 592,*/}
                {/*      height: 29,*/}
                {/*      padding: '6px 8px',*/}
                {/*      display: 'flex',*/}
                {/*      justifyContent: 'space-between',*/}
                {/*      alignItems: 'center',*/}
                {/*      marginBottom: 4*/}
                {/*  }}>*/}
                {/*      <div>{item?.name}</div>*/}
                {/*      <DownloadSVG as={DownloadIcon} onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>*/}
                {/*  </div>*/}
                {/*))}*/}

                <FileInput key={fileInputKey} type="file" onChange={({target: {files}}) => {
                  files?.length && UploadData(files[0], 0);

                }}/>
                {localFileList?.map((item: any, index: number) => (
                  <DescText>
                    {item?.name}
                    <DownloadSVG as={DownloadIcon} style={{marginLeft: 10}}
                                 onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setLocalFileList(prev => {
                        const prevIndex =  prev.findIndex(i => i.urlId === item?.urlId);
                        prev.splice(prevIndex, 1);

                        return prev
                      });
                    }}/>
                  </DescText>
                ))}

                {fileList?.map((item: any, index: number) => (
                  <DescText>
                    {item?.name}
                    <DownloadSVG as={DownloadIcon} style={{marginLeft: 10}}
                                 onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setFileList(prev => {
                        const prevIndex =  prev.findIndex(i => i.urlId === item?.urlId);
                        prev.splice(prevIndex, 1);

                        return prev
                      });
                    }}/>
                  </DescText>
                ))}
              </div>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                내용
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              <TextArea value={content} onChange={e => setContent(e.target.value)}/>
            </Td>
          </TableRow>
          </tbody>
        </Table>


        {/*<ContentsTitle>답변작성</ContentsTitle>*/}

        {/*<Table>*/}
        {/*    <tbody>*/}
        {/*    <TableRow>*/}
        {/*        <ThGrey>*/}
        {/*            <ThLabel>*/}
        {/*                등록일*/}
        {/*            </ThLabel>*/}
        {/*        </ThGrey>*/}
        {/*        /!*<Td colSpan={3}>{agentInquireAnswerInfo?.createdAt}</Td>*!/*/}
        {/*    </TableRow>*/}
        {/*    <TableRow>*/}
        {/*        <ThGrey>*/}
        {/*            <ThLabel>*/}
        {/*                첨부파일*/}
        {/*            </ThLabel>*/}
        {/*        </ThGrey>*/}
        {/*        <Td colSpan={3}>*/}
        {/*            <div>*/}
        {/*                /!*<div style={{display: 'flex', fontWeight: '500', fontSize: 14, lineHeight: '16.71px', marginBottom: 12}}>*!/*/}
        {/*                /!*    {`${fileList?.length || 0}건`}*!/*/}
        {/*                /!*    /!*<div style={{fontWeight: '300', color: '#00000066'}}>(1.7MB) |</div>*!/*!/*/}
        {/*                /!*    <div onClick={() => handleDownloadAll(agentInquireInfo?.fileList)} style={{fontWeight: '400', color: '#125DE6CC', textDecoration: 'underline', marginLeft: 6, cursor: 'pointer'}}>모두저장</div>*!/*/}
        {/*                /!*</div>*!/*/}

        {/*                /!*{fileList?.map((item, index) => (*!/*/}
        {/*                /!*  <div style={{*!/*/}
        {/*                /!*      backgroundColor: '#FAFAFC',*!/*/}
        {/*                /!*      width: 592,*!/*/}
        {/*                /!*      height: 29,*!/*/}
        {/*                /!*      padding: '6px 8px',*!/*/}
        {/*                /!*      display: 'flex',*!/*/}
        {/*                /!*      justifyContent: 'space-between',*!/*/}
        {/*                /!*      alignItems: 'center',*!/*/}
        {/*                /!*      marginBottom: 4*!/*/}
        {/*                /!*  }}>*!/*/}
        {/*                /!*      <div>{item?.name}</div>*!/*/}
        {/*                /!*      <DownloadSVG as={DownloadIcon} onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>*!/*/}
        {/*                /!*  </div>*!/*/}
        {/*                /!*))}*!/*/}

        {/*                <FileInput key={fileInputKey} type="file" onChange={({target: {files}}) => {*/}
        {/*                    files?.length && UploadData(files[0], 0);*/}

        {/*                }}/>*/}
        {/*                {fileList?.map((item: any, index: number) => (*/}
        {/*                  <DescText>*/}
        {/*                      {item?.name}*/}
        {/*                      <DownloadSVG as={DownloadIcon} style={{marginLeft: 10}} onClick={() => handleDownload(MEDIA_URL + item?.url, item?.name)}/>*/}
        {/*                      <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => DeleteFileListData(item?.name, index)} />*/}
        {/*                  </DescText>*/}
        {/*                ))}*/}
        {/*            </div>*/}
        {/*        </Td>*/}
        {/*    </TableRow>*/}
        {/*    <TableRow>*/}
        {/*        <ThGrey>*/}
        {/*            <ThLabel>*/}
        {/*                내용*/}
        {/*            </ThLabel>*/}
        {/*        </ThGrey>*/}
        {/*        <Td colSpan={3}>*/}
        {/*            /!*<div style={{minHeight: 120, display: 'flex', justifyContent: 'space-between', whiteSpace: 'pre-wrap'}}>*!/*/}
        {/*            /!*    {agentInquireAnswerInfo?.content}*!/*/}
        {/*            /!*</div>*!/*/}
        {/*            <TextArea value={content} onChange={e => setContent(e.target.value)} />*/}
        {/*        </Td>*/}
        {/*    </TableRow>*/}
        {/*    </tbody>*/}
        {/*</Table>*/}

        <ButtonsWrapper>
          {/*<PrevNextText>*/}
          {/*    <ArrowSVG as={LeftArrowIcon}/>*/}
          {/*    이전글*/}
          {/*</PrevNextText>*/}

          {messageItemId &&
            <CancelButton onClick={() => putEditMessage()}>
              수정
            </CancelButton>
          }

          {isSendMessage &&
            <CancelButton onClick={() => postSendMessage()}>
              등록
            </CancelButton>
          }

          {/*<PrevNextText>*/}
          {/*    다음글*/}
          {/*    <ArrowSVG as={RightArrowIcon}/>*/}
          {/*</PrevNextText>*/}
        </ButtonsWrapper>
      </ModalWrapper>
    </>
  )
}

const StyledSelect = styled(Select)`
    width: 170px;
    height: 36px;
    border-radius: 4px;

`


const InputText = styled(Input)`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border-radius: 4px;
    display: flex;

    &::placeholder {
        color: #535353;
    }
`;

const DeleteItemIconSVG = styled.svg`
    width: 12px;
    cursor: pointer;
    margin-left: 10px;
`


const DescText = styled.div`
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #000000;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const FileInput = styled.input`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 808px;
    width: 780px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThGrey = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F5F5F5;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;

`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;


const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`


const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #0000004D;
    color: #125DE6;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const DownloadSVG = styled.svg`
    cursor: pointer;
    width: 16px;
`

const PrevNextText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #00000099;
    cursor: pointer;
`

const ArrowSVG = styled.svg`
    width: 20px;
`

const ContentsTitle = styled.div`
    width: 100%;
    margin-top: 20px;
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    display: flex;
    gap: 8px;
`