import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/admin/payment/return/list' // 광고목록 조회

const fetchKey = (page, maxData, date, status, searchWord) =>
	`${M_KEY}?${page && 'page=' + page}${maxData && '&maxData=' + maxData}${date && '&date=' + date}${status > 0 ? '&status=' + status : ''}${searchWord && '&searchWord=' + searchWord}`;

export function useAdminPaymentReturnList(page, maxData, date, type, searchWord) {
	const { data: token } = useToken();

	const KEY = fetchKey(page, maxData, date, type, searchWord);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate, isLoading } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { adminPaymentReturnList: data?.list, mutateAdminPaymentReturnList: mutate, total: data?.total, isLoading };
}

async function fetchAPI(KEY:string, token:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Admin Payment Return List:', error);
		return { list: [], total: 0 };
	}
}