import { Spin } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import {FullImageList} from "../../FullImageList";

export const Six = ({ item, num }) => {
  const [zoom, setZoom] = React.useState(1);

  const ref = React.useRef<HTMLDivElement>(null);

  const [loading, setLoading] = React.useState(true);
  const [ok, setOk] = React.useState(false);

  useEffect(() => {
    if (item) {
        if( ref.current){
        setLoading(false);
        }
    }
  }, [item, ref.current]);

  useEffect(() => {
    if(ok){ return; }
    if (!loading) {
      //높이

      const height =  ref.current?.offsetHeight || 520;
      let zoom =  520 / height;
      //setZoom(Math.min(zoom, 1));
      setOk(true);
    }
  }, [loading, ok]);

  // useEffect(() => {
  //   console.log('item', item)
  // }, [item]);

  
  if (loading) {
      return (
        <SixTextContainer ref={ref} zoom={zoom}>
          <Spin size="large" />
        </SixTextContainer>
      );
  }

  return (
    <>
      {item[num]?.saleInfo?.saleType ?
        <SixTextContainer ref={ref} zoom={zoom}>
          <SixTitle textlength={item[num]?.saleInfo?.saleType?.length}>
            {item[num]?.saleInfo?.saleType}
            <SixColorTitle>{item[num]?.saleInfo?.transactionType}</SixColorTitle>
          </SixTitle>
          <SixAddress>{item[num]?.saleInfo?.address}</SixAddress>
          <SixPrice>{item[num]?.saleInfo?.price}</SixPrice>
          <SixLabel textlength={item[num]?.saleInfo?.size?.length}>
            {item[num]?.saleInfo?.size}
          </SixLabel>
        </SixTextContainer>
        :
        <SixTextContainer ref={ref} zoom={zoom}>
        <FullImageList
          imagelist={item[num]?.imageUrlList}
          isWidth={true}
          setItem={item}
          width={"620px"}
          type={'six'}
          num={num}
        />
        </SixTextContainer>
      }
    </>
  );
};

const SixTextContainer = styled.div<{ zoom? }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 620px;
  height: 520px;
  border: 6px solid #222222;
    overflow: hidden;

  & div {
    zoom: ${(props) => props.zoom || 1};
  }
`;

const SixTitle = styled.div<{ textlength? }>`
  font-weight: 700;
  font-size: 78px;
  line-height: 78px;
  display: flex;
  gap: 12px;
  margin-bottom: "48px";
`;

const SixColorTitle = styled.div`
  color: #f33636;
`;

const SixAddress = styled.div`
  font-weight: 800;
  font-size: 46px;
  line-height: 49.51px;
  margin-bottom: 34px;
`;

const SixPrice = styled.div`
  font-weight: 800;
  font-size: 56px;
  line-height: 63.56px;
  color: #125de6;
  margin-bottom: 29px;
`;

const SixLabel = styled.div<{ textlength? }>`
  font-weight: 700;
  font-size: 46px;
  line-height: 49.51px;
  //line-height: 49.51px;
  color: #000000cc;
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
  white-space: pre-wrap;
`;

const SixValue = styled.div`
  font-weight: 800;
  color: #000000;
`;
