import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {ApplicationOneStep} from "./blocks/ApplicationOneStep";
import {PreviewMonitor} from "./blocks/PreviewMonitor";
import {numberWithCommas} from "../../../utils/ciutils";


export const CustomerCommercialTable = ({setIsSelectItem, isSelectItem, selectedRows, setSelectedRows, isAllChecked, setIsAllChecked,
                                          advertiserADList,
                                          mutateAdvertiserADList,
                                          setAdIdValue,
                                          setIsOneStepModal,
                                          advertiserADInfo,
                                          setIsThreeStepModal,
                                          setIsFourthStepModal,
                                          total,
                                          page,
                                          setPage,
                                          pageSize,
                                          setPageSize, isLoading
                                        }) => {

  const [orderId, setOrderId] = useState(null);

  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = advertiserADList?.length > 0 && advertiserADList?.every(it => selectedRows?.includes(it.adId));
    setIsAllChecked(allSelected);
  }, [selectedRows, advertiserADList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = advertiserADList?.map(db => db.adId);
    setSelectedRows(e.target.checked ? ids : []);
  };



  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const columns = [
    {
      dataIndex: '',
      title: isSelectItem ?
        ( <Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.adId)} key={data?.adId} onChange={(e) => handleCheckboxChange(e, data?.adId)} />
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.adId || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고명',
      render: (value, data) => {
        return (
          <div className='email' onClick={() => {
            console.log('data', data);

            if (!data?.requestedAt) {
              setAdIdValue(data?.adId);
              setIsOneStepModal(true);
            } else if(data?.status == '승인요청') {
              setAdIdValue(data?.adId);
              setIsThreeStepModal(true);
            } else {
              setAdIdValue(data?.adId);
              setIsFourthStepModal(true);
            }
          }} style={{
            justifyContent: 'center',
            display: 'flex',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}>{data?.title || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '요청일',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.requestedAt || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고기간',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.period || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고',
      render: (value, data) => {
        return (
          <div className='date' onClick={() => {
            setIsPreviewModal(true);
            setPreviewId(data?.adId);
          }} style={{justifyContent: 'center', display: 'flex', color: '#9747FF', cursor: 'pointer'}}>미리보기</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고료',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(data?.price)+' 원'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '진행현황',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{data?.status || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex', gap: 5}}>
            {data?.statusList?.map((item, index) => (
              <div>{`${item.title} ${item.cnt}`}</div>
            ))}
          </div>
        )
      }
    },
    {
      dataIndex: 'tempStatus',
      title: '임시저장상태',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value === 1 ? '임시저장중' : '-'}</div>
        )
      }
    },
  ];

  return (
    <DBFilterWrapper>

      <StyleTable columns={columns}
                  dataSource={advertiserADList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />


      {isPreviewModal &&
        <PreviewMonitor setIsPreviewModal={setIsPreviewModal} itemId={previewId} advertiserADList={advertiserADList}/>}
    </DBFilterWrapper>
  )
}

export default React.memo(CustomerCommercialTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
