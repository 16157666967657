import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import * as PAGE from "./pagesImport";
import { GlobalStyle } from "../styles/global-styles";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { useToken } from "./hooks";
import { LogoutPage } from "./pages/AuthenticationPages/LogoutPage/index";
import { HomeTown } from "../CRM/organism/Main";
import { DigitalSignage } from "./organisms/DigitalSignage";
import { SaleDigitalSignage } from "./organisms/SaleDigitalSignage";
import { BothDigitalSignage } from "./organisms/BothDigitalSignage";
import { Helmet } from "react-helmet-async";
import { useUserInfo } from "../HomeTown/hooks/Auth/GetUserInfo";

export function MainRoutes() {
  const { data: token } = useToken();
  const { userInfo, mutateUserInfo } = useUserInfo();

  return (
    <BrowserRouter>
      <Helmet>
        <title>
          울동네
          {userInfo?.is_agent === 1 ? " - 매체주" : userInfo?.is_ad === 1 ? " - 광고주" : userInfo?.is_admin === 1 ? " - 관리자" : ''}
        </title>
      </Helmet>
      <Routes>
        <Route
          path="/"
          element={token ? <Navigate to="/admin" /> : <PAGE.LoginPage />}
        />
        <Route path="/admin/login" element={<PAGE.LoginPage />} />
        <Route path="/admin/logout" element={<LogoutPage />} />
        <Route path="/admin/*" element={<HomeTown />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="/tv"
          element={token ? <DigitalSignage /> : <PAGE.LoginPage />}
        />
        <Route
          path="/digitalSignage"
          element={token ? <DigitalSignage /> : <PAGE.LoginPage />}
        />
        {/*<Route path="/saleDigitalSignage" element={<SaleDigitalSignage />} />*/}
        {/*<Route path="/bothDigitalSignage" element={<BothDigitalSignage />} />*/}
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
