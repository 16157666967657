import {Input, message, Select} from 'antd'
import 'dayjs/locale/ko'
import React, { useState } from 'react'
import styled from "styled-components"
import {
	DBFilterDateArea,
	DBFilterWrapper
} from '../../../CRM/organism/DB/styles'
import OrderFilter from '../../Common/OrderFilter'
import { ReactComponent as SearchIcon } from "./assets/SearchIcon.svg"
import API from "../../../utils/api";
import {useToken} from "../../../app/hooks";


export const UserManageFilter = ({order, setOrder, setSearchWord, mutateUserList, categoryItem, setIsSelectItem, isSelectItem, selectedRows, setSelectedRows, isAllChecked, setIsAllChecked}) => {
	const [inputSearchWord, setInputSearchWord] = useState('')

	// 데이터 조회
	const {data: token} = useToken();


	const IsSearch = () => {
		setSearchWord(inputSearchWord);
		mutateUserList();
	}


	const CommercialStateItem = [
		{
			name: '회원',
			value : 0
		},
		{
			name: '탈퇴',
			value : 0
		},
	]


	const putAdminUser = (type, value, name) => {
		const shouldEdit = window.confirm(`${name}하시겠습니까?`);
		if(shouldEdit) {
		  API.put('/admin/user/list', token + '', {
				ids: selectedRows,
				type,
				value,
		  })
		    .then((res: any) => {
		      if (res.result.success) {
		        message.success('수정되었습니다');
						setSelectedRows([]);
		        mutateUserList();
		        // setOrderId(null);
		        // setMembershipEdit(false);
		      } else {
						message.error(res.result.message || '실패하였습니다');
					}
		    })
		    .catch((err: any) => {
		      message.error('실패하였습니다');
		    })
		}
	}


	const deleteAdminUser = () => {
		const shouldEdit = window.confirm('삭제하시겠습니까?');
		if(shouldEdit) {
			API.delete(`/admin/user/list?ids=` + selectedRows?.join(','), token + '', {})
				.then((res: any) => {
					if (res.result.success) {
						message.success('삭제되었습니다');
						mutateUserList();
						// setOrderId(null);
						// setMembershipEdit(false);
					} else {
						message.error(res.result.message || '실패하였습니다');
					}
				})
				.catch((err: any) => {
					message.error('실패하였습니다');
				})
		}
	}



	return (
		<DBFilterWrapper style={{ backgroundColor: '#FAFAFC' }}>

			{isSelectItem ?
				<DBFilterDateArea
					style={{justifyContent: 'space-between', backgroundColor: '#E3E3E3', height: 60, padding: '0 16px'}}>
					<SelectItemCount>
						{`${selectedRows.length}건 선택`}
						{categoryItem === '광고주' &&
							<ButtonsWrapper>
								<ItemButton onClick={() => putAdminUser('premium', 1, '유료처리')}>유료처리</ItemButton>
								<ItemButton onClick={() => putAdminUser('premium', 0, '무료처리')}>무료처리</ItemButton>
								<ItemButton style={{color: '#0c88ea'}} onClick={() => putAdminUser('resign', 0, '복귀')}>복귀</ItemButton>
								<ItemButton style={{color: '#FF6C4B'}} onClick={() => putAdminUser('resign', 1, '탈퇴')}>탈퇴</ItemButton>
								<ItemButton style={{color: '#FF6C4B'}} onClick={() => deleteAdminUser()}>삭제</ItemButton>
							</ButtonsWrapper>
						}

						{categoryItem === '매체주' &&
							<ButtonsWrapper>
								<ItemButton onClick={() => putAdminUser('premium', 1, '유료처리')}>유료처리</ItemButton>
								<ItemButton onClick={() => putAdminUser('premium', 0, '무료처리')}>무료처리</ItemButton>
								<ItemButton onClick={() => putAdminUser('role', 1, '외부권한으로 변경')}>외부권한</ItemButton>
								<ItemButton onClick={() => putAdminUser('role', 2, '매물권한으로 변경')}>매물권한</ItemButton>
								<ItemButton onClick={() => putAdminUser('role', 3, '외부+매물권한으로 변경')}>외부+매물권한</ItemButton>
								<ItemButton style={{color: '#0c88ea'}} onClick={() => putAdminUser('resign', 0, '복귀')}>복귀</ItemButton>
								<ItemButton style={{color: '#FF6C4B'}} onClick={() => putAdminUser('resign', 1, '탈퇴')}>탈퇴</ItemButton>
								<ItemButton style={{color: '#FF6C4B'}} onClick={() => deleteAdminUser()}>삭제</ItemButton>
							</ButtonsWrapper>
						}

						{categoryItem === '회원신청목록' &&
							<ButtonsWrapper>
								<ItemButton onClick={() => putAdminUser('accept', 1, '가입승인')}>가입승인</ItemButton>
							</ButtonsWrapper>
						}
					</SelectItemCount>

					<SearchWrapper>
						<ItemButton  onClick={() => {
							setIsSelectItem(false);
						}}>
							취소
						</ItemButton>
					</SearchWrapper>
				</DBFilterDateArea>
				:
				<DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
					<OrderFilter type={categoryItem === '회원신청목록' ? '회원신청목록' : '회원'}  order={order} setOrder={setOrder} />

					<SearchWrapper>
						<AllCheckButton onClick={() => setIsSelectItem(true)}>
							일괄처리
						</AllCheckButton>

						{/*{categoryItem !== '회원신청목록' &&*/}
						{/*	<StyledSelect value={'전체 상태'}>*/}
						{/*		{CommercialStateItem?.map((item, index) => (*/}
						{/*			<Select.Option value={item.name}>{item.name}</Select.Option>*/}
						{/*		))}*/}
						{/*	</StyledSelect>*/}
						{/*}*/}

						<StyleInput placeholder={'검색'} value={inputSearchWord} onChange={e => setInputSearchWord(e.target.value as string)} suffix={<SearchIconSVG as={SearchIcon}/>} />

						<SearchButton onClick={() => IsSearch()}>
							조회
						</SearchButton>
					</SearchWrapper>
				</DBFilterDateArea>
			}

		</DBFilterWrapper>
	)
}



const RoundCheckWrapper = styled.div`
	display: flex;
	gap: 14px;
`

const SearchWrapper = styled.div`
	display: flex;
	gap: 6px;
`

const AllCheckButton = styled.div`
	border: 1px solid #FF6C4B;
	width: 92px;
	height: 36px;
	font-weight: 400;
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FF6C4B;
	cursor: pointer;
`

const SearchButton = styled.div`
	background-color: #FF6C4B;
	width: 66px;
	height: 36px;
	border-radius: 4px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 17.9px;
	cursor: pointer;
`

const StyledSelect = styled(Select)`
	width: 170px;
	height: 36px;
	border-radius: 4px;
	
`


const StyleInput = styled(Input)`
	width: 238px;
	height: 36px;

	.ant-input::placeholder {
		color: #535353; /* 원하는 색상으로 변경 */
	}
`

const SearchIconSVG = styled.svg`
	width: 20px;
`

const SelectItemCount = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 6px;
`

const ItemButton = styled.div`
    padding: 9px 20px;
    min-width: 36px;
    background: #FFFFFF;
    border: 1px solid #0000004D;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`