import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Display} from "../../../CRM/organism/Main/styles";
import {useAgentSettingList} from "../../hooks/Agent/SettingAgent/GetAgentSettingList";
import {Checkbox, message, Select} from "antd";
import API from "../../../utils/api";
import {useToken} from "../../../app/hooks";

export const SettingAgent = () => {
  // 상태값
  const [itemId, setItemId] = useState(null);


  // 데이터 조회
  const {agentSettingList, mutateAgentSettingList} = useAgentSettingList();
  const {data: token} = useToken();

  useEffect(() => {
    console.log('agentSettingList', agentSettingList)
    setItemId(agentSettingList?.find(item => item.status === 1)?.itemId)

  }, [agentSettingList]);

  const postSettingSaleTime = () => {
    const shouldEdit = window.confirm(`매물 광고 시간을 수정하시겠습니까?`);
    if(shouldEdit) {
      API.post('/agent/setting', token + '', {
        itemId
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success('수정되었습니다');
            mutateAgentSettingList();
          } else {
            message.error(res.result.message || '실패하였습니다');
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다');
        })
    }
  }

  return(
    <Display>
      <TableTitle>
        <ChangeFeeButton onClick={() => postSettingSaleTime()}>
          수정적용
        </ChangeFeeButton>
      </TableTitle>

      <Table>
        <tbody>
        {agentSettingList?.map(item => (
          <TableRow>
            <Th>
              <ThLabel>
                {item?.title}
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents>
                <Checkbox checked={itemId === item?.itemId} onClick={() => setItemId(item?.itemId)} />
              </TdRowContents>
            </Td>
          </TableRow>
        ))}
        {/*<TableRow>*/}
        {/*  <Th>*/}
        {/*    <ThLabel>*/}
        {/*      매물*/}
        {/*    </ThLabel>*/}
        {/*  </Th>*/}
        {/*  <Td>*/}
        {/*    <TdRowContents>*/}

        {/*    </TdRowContents>*/}
        {/*  </Td>*/}
        {/*</TableRow>*/}
        {/*<TableRow>*/}
        {/*  <Th>*/}
        {/*    <ThLabel>*/}
        {/*      외부+매물*/}
        {/*    </ThLabel>*/}
        {/*  </Th>*/}
        {/*  <Td>*/}
        {/*    <TdRowContents>*/}
        {/*      */}
        {/*    </TdRowContents>*/}
        {/*  </Td>*/}
        {/*</TableRow>*/}
        </tbody>
      </Table>
    </Display>
  )
}

const TableTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
`


const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 30%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    justify-content: center;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    width: 78%;
    border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #000000;
`

const DescText = styled.div`
  font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #00000099;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const Input = styled.input`
    width: 50%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const ChangeFeeButton = styled.div`
    background-color: #2a29e6;
    color: white;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;

`