import React, {useEffect} from "react";
import {useUserInfo} from "../hooks/Auth/GetUserInfo";
import {useLocation, useNavigate} from "react-router-dom";

export const CallbackPage = () => {
  const { userInfo, mutateUserInfo } = useUserInfo();
  const navigate = useNavigate();
  const {state} = useLocation();

  // const currentUrl = window.location.href;
  //
  useEffect(() => {
    console.log('state', state)
  }, [state]);

  useEffect(() => {
    setTimeout(() => {
      if((userInfo?.is_agent === 1) && (state === 'https://myhometown-ad.co.kr/tv')){
        navigate('/tv')
      }else if( userInfo?.is_agent === 1) {
        navigate('/admin/saleProperty')
      } else if (userInfo?.is_ad === 1) {
        navigate('/admin/customerCommercial')
      } else {
        navigate('/admin/userManage')
      }
    }, 1000)
  }, [userInfo]);

  return(
    <>
    </>
  )
}