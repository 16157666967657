import { Input, message, Table } from "antd";
import React, { useEffect } from 'react';
import styled from "styled-components";
import { Blacked } from "../../../../CRM/components/styles";
import { useToken } from "../../../../app/hooks";
import { HomeTownRoundCheckbox } from "../../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import { ReactComponent as ModalCloseIcon } from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import API from "../../../../utils/api";
import { formatPhoneNumber } from '../../../../utils/ciutils';
import { useAdminUserADList } from "../../../hooks/Admin/UserManage/GetAdminUserADList";
import { useAdminUserInfo } from "../../../hooks/Admin/UserManage/GetAdminUserInfo";
import { PreviewMonitor } from "./PreviewMonitor";


export const CustomerDetail = ({customerId, setCustomerId, mutateUserList}) => {
  // 상태값
  const [premium, setPremium] = React.useState(0);
  const [premiumChangeDate, setPremiumChangeDate] = React.useState('');

  // 유틸리티
  const [commercialId, setCommercialId] = React.useState(null);

  // 데이터 조회
  const {adminUserInfo, mutateAdminUserInfo} = useAdminUserInfo(customerId);
  const {adminUserADList, mutateAdminUserADList} = useAdminUserADList(1, 1000, customerId);
  const {data: token} = useToken();


  useEffect(() => {
    console.log('adminUserADList', adminUserADList)
  }, [adminUserADList]);


  useEffect(() => {
    console.log('adminUserInfo', adminUserInfo)
    setPremium(adminUserInfo?.premium);
    setPremiumChangeDate(adminUserInfo?.premiumChangeDate)
  }, [adminUserInfo]);


  const putEditUser = () => {
    const shouldEdit = window.confirm('수정하시겠습니까?');
    if(shouldEdit) {
      API.put(`/admin/user/list/${customerId}`, token + '', {
      datas: {
        premium,
        premiumChangeDate
      }
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success('수정되었습니다');
            setCustomerId(null);
            mutateUserList();
          } else {
            message.error(res.result.message || '실패하였습니다');
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다');
        })
    }
  }


  const columns = [
    {
      dataIndex: 'adId',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'title',
      title: '광고명',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setCommercialId(data?.adId)} style={{
            justifyContent: 'center',
            display: 'flex',
            // textDecoration: 'underline',
            // cursor: 'pointer'
          }}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'period',
      title: '광고기간',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'agentCount',
      title: '매체',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{`${value || 0}곳`}</div>
        )
      }
    }
  ];


  return (
    <>
      <Blacked onClick={() => setCustomerId(null)}/>
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>광고주 상세</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {
            setCustomerId(null)
          }}/>
        </ModalHeader>

        <ContentsTitle>회원정보</ContentsTitle>
        <CustomTable>
          <tbody>
          <TableRow>
            <Th>
              <ThLabel>
                회원명
              </ThLabel>
            </Th>
            <Td colSpan={3}>{adminUserInfo?.name || '-'}</Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                아이디
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              {adminUserInfo?.email || '-'}
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                비밀번호
              </ThLabel>
            </Th>
            <Td colSpan={3}>{adminUserInfo?.password || '-'}</Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                업체명
              </ThLabel>
            </Th>
            <Td>
              {adminUserInfo?.store || '-'}
            </Td>
            <Th>
              <ThLabel>
                업종
              </ThLabel>
            </Th>
            <Td>
              {adminUserInfo?.businessType || '-'}
            </Td>
          </TableRow>
          {/*<TableRow>*/}
          {/*  <Th>*/}
          {/*    <ThLabel>*/}
          {/*      지역*/}
          {/*    </ThLabel>*/}
          {/*  </Th>*/}
          {/*  <Td colSpan={3}>*/}
          {/*    {adminUserInfo?.region || '-'}*/}
          {/*  </Td>*/}
          {/*</TableRow>*/}
          <TableRow>
            <Th>
              <ThLabel>
                주소
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              {adminUserInfo?.address || '-'}
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                세부주소
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              {adminUserInfo?.detailAddress || '-'}
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                휴대폰
              </ThLabel>
            </Th>
            <Td>
              {formatPhoneNumber(adminUserInfo?.phone) || '-'}
            </Td>
            <Th>
              <ThLabel>
                가입일
              </ThLabel>
            </Th>
            <Td>
              {adminUserInfo?.cratedAt || '-'}
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                계좌번호
              </ThLabel>
            </Th>
            <Td colSpan={3}>
              {adminUserInfo?.account || '-'}
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                사용자 관리
              </ThLabel>
            </Th>
            <Td>
              <div style={{display: 'flex', gap: 14}}>
                <HomeTownRoundCheckbox text={'유료'} checked={premium === 1} onClick={() => {
                  setPremium(premium === 1 ? 0 : 1)
                }}/>
                <HomeTownRoundCheckbox text={'무료'} checked={premium === 0} onClick={() => {
                  setPremium(premium === 1 ? 0 : 1)
                }}/>
              </div>
            </Td>
            <Th>
              <ThLabel>
                유료전환예정일
              </ThLabel>
            </Th>
            <Td>
              <InputText placeholder={'ex. 2024-01-01'} value={premiumChangeDate} onChange={(e) => setPremiumChangeDate(e.target.value)}  />
            </Td>
          </TableRow>
          </tbody>
        </CustomTable>

        <ContentsTitle>진행중인 광고 <ContentsSubTitle>{`${adminUserADList?.length || 0}건`}</ContentsSubTitle></ContentsTitle>

        <StyleTable columns={columns}
                    dataSource={adminUserADList}
                    pagination={false}
        />


        <ButtonsWrapper>
          <CancelButton onClick={() => putEditUser()}>
            수정
          </CancelButton>
        </ButtonsWrapper>
      </ModalWrapper>

      {/*{commercialId !== null && <PreviewMonitor setCommercialId={setCommercialId} commercialId={commercialId} />}*/}

    </>
  )
}


const InputText = styled(Input)`
    width: 160px;
    height: 28px;
    padding-left: 10px;
    border-radius: 4px;
    display: flex;

    &::placeholder {
        color: #535353;
    }
`;

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 600px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const CustomTable = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;

`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    // width: 78%;
    border: 1px solid #ccc;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
`;

const ContentsTitle = styled.div`
    width: 100%;
    margin-top: 20px;
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    display: flex;
    gap: 8px;
`

const ContentsSubTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.75px;
    color: #000000CC;
`

const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 6px;
`

const SignUpButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const StyleTable = styled(Table)`
    width: 532px;

    .ant-table-thead > tr > th {
        background-color: #F5F5F5;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
        text-align: center;
    }
`