import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/admin/user/list' // 광고목록 조회

const fetchKey = (page, maxData, userId) =>
	`${M_KEY}/${userId}/ad?${page && 'page=' + page}${maxData && '&maxData=' + maxData}`;

export function useAdminUserADList(page=1, maxData=1000, userId) {
	const { data: token } = useToken();

	const KEY = fetchKey(page, maxData, userId);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { adminUserADList: data?.list, mutateAdminUserADList: mutate, total: data?.total };
}

async function fetchAPI(KEY:string, token:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Admin User AD List:', error);
		return { list: [], total: 0 };
	}
}