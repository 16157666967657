import React, {useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {Select, Table} from "antd";
import {useAgentRefundDetailList} from "../../../hooks/Agent/Calculation/GetAgentRefundDetailList";
import { numberWithCommas } from '../../../../utils/ciutils';


export const CommercialFeeList = ({setItemId, itemId}) => {
  const {agentRefundDetailList, mutateAgentRefundDetailList} = useAgentRefundDetailList(itemId)


  const columns = [
    // {
    //   dataIndex: '',
    //   title: 'No',
    //   render: (value, data, index) => {
    //     return (
    //       <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.adId}</div>
    //     )
    //   }
    // },
    {
      dataIndex: 'store',
      title: '광고주',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'title',
      title: '광고명',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    },
    {
      dataIndex: 'period',
      title: '광고기간',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고료',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(data?.price) + '원'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '수수료(30%)',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(data?.fee) + ' 원'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고수익',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(data?.totalPrice)+ ' 원'}</div>
        )
      }
    },
  ];


    return (
      <>
          <Blacked onClick={() => setItemId(null)}/>
          <ModalWrapper>
              <ModalHeader>
                  <ModalTitle>광고료내역</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setItemId(null)}  }/>
              </ModalHeader>

            <StyleTable columns={columns}
                        dataSource={agentRefundDetailList}
                        bordered={true}

            />

              <ButtonsWrapper>
                  <CancelButton onClick={() => setItemId(null)}>
                      확인
                  </CancelButton>
              </ButtonsWrapper>
          </ModalWrapper>
      </>
    )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 553px;
    min-width: 580px;
    max-width: 800px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Contents = styled.div`
    margin-top: 45px;
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
    color: #000000;
    white-space: pre-wrap;
    text-align: center;
`

const ButtonsWrapper = styled.div`
    margin-top: 36px;
    display: flex;
    gap: 6px;
`

const SignUpButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const StyleTable = styled(Table)`
    width: 100%;
    margin-top: 21.5px;
 .ant-table-thead > tr > th {
    background-color: #F5F5F5;
    color: #00000099;
     font-size: 14px;
     font-weight: 500;
     line-height: 16.71px;
  }
`