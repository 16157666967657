import React, {useEffect, useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {NoticeFilter} from "./NoticeFilter";
import {NoticeTable} from "./NoticeTable";
import { useOrderList } from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAgentNoticeList} from "../../hooks/Agent/Notice/GetAgentNoticeList";
import {useUserInfo} from "../../hooks/Auth/GetUserInfo";


export const Notice = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [date, setDate] = useState(''); // 2024-01-01의 형태
  const [type, setType] = useState(0); // 1: 매체주 | 2: 광고주
  const [searchWord, setSearchWord] = useState('');


  const { agentNoticeList, mutateAgentNoticeList, total, isLoading } = useAgentNoticeList(page, pageSize, date, type, searchWord)
  const { userInfo } = useUserInfo();

  useEffect(() => {
    setType(Number(userInfo?.userType))
  }, [userInfo]);


  return(
    <Display>
      <NoticeTable
        agentNoticeList={agentNoticeList}
        mutateAgentNoticeList={mutateAgentNoticeList}
        total={total}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        isLoading={isLoading}
      />
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard ,sans-serif;
`

