import React, {useEffect, useState} from 'react'
import {
	DBFilterDateArea,
	DBFilterWrapper,
	DBTableExcelBtn,
	DateArea,
	GotoStatus,
} from '../../../CRM/organism/DB/styles'
import { Select, Input, ConfigProvider, DatePicker, Upload, message } from 'antd'
import { RangePickerProps } from 'rc-picker/es/RangePicker.d'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import locale from 'antd/lib/locale/ko_KR'
import { Icon } from '../../../CRM/organism/Main/styles'
import { IoSearch } from 'react-icons/io5'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {ReactComponent as SearchIcon} from "./assets/SearchIcon.svg";
import {useAdvertiserTotalInfo} from "../../hooks/Advertiser/Deposit/GetAdvertiserTotalnfo";


export const DepositFilter = ({setType, mutateAdvertiserTransactionList, setRefundModal}) => {
	const [inputType, setInputType] = useState(0)
	const [filterValueInput, setFilterValueInput] = useState('')


	// 데이터 조회
	const {advertiserTotalInfo} = useAdvertiserTotalInfo()

	useEffect(() => {
		console.log('advertiserTotalInfo', advertiserTotalInfo)
	}, [advertiserTotalInfo]);


	const IsSearch = () => {
		setType(inputType)
	}


	const CommercialStateItem = [
		{
			name: '구분 전체',
			value : 0
		},
		{
			name: '충전',
			value : 1
		},
		{
			name: '사용',
			value : 2
		},
		{
			name: '환급',
			value : 3
		},
	]

	const formatCurrency = (number) => {
		return new Intl.NumberFormat('ko-KR', {
			currency: 'KRW',
		}).format(number);
	};


	return (
		<DBFilterWrapper style={{ backgroundColor: '#FAFAFC' }}>

			<DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
				<RoundCheckWrapper>
					<Title>
						예치금
						<BoldTitle>{`${formatCurrency(advertiserTotalInfo?.amount || 0)}원`}</BoldTitle>
					</Title>

					<ListBadge onClick={() => setRefundModal(true)}>
						환급신청
					</ListBadge>
				</RoundCheckWrapper>

				<SearchWrapper>
					<StyledSelect value={inputType} onChange={(e) => setInputType(e as number)}>
						{CommercialStateItem?.map((item, index) => (
							<Select.Option value={item.value}>{item.name}</Select.Option>
						))}
					</StyledSelect>

					<SearchButton onClick={() => IsSearch()}>
						조회
					</SearchButton>
				</SearchWrapper>
			</DBFilterDateArea>
		</DBFilterWrapper>
	)
}

const Title = styled.div`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	font-family: Pretendard, sans-serif;
	display: flex;
	align-items: center;
	gap: 6px;
`

const BoldTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	font-family: Pretendard, sans-serif;
`

const ListBadge = styled.div`
	background-color: #F5F5F5;
	width: 56px;
	height: 25px;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #00000099;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	font-family: Pretendard, sans-serif;
	cursor: pointer;
`


const RoundCheckWrapper = styled.div`
	display: flex;
	gap: 14px;
`

const SearchWrapper = styled.div`
	display: flex;
	gap: 6px;
`



const SearchButton = styled.div`
	background-color: #FF6C4B;
	width: 66px;
	height: 36px;
	border-radius: 4px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 17.9px;
	cursor: pointer;
`

const StyledSelect = styled(Select)`
	width: 170px;
	height: 36px;
	border-radius: 4px;
	
`
