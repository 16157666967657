import { Checkbox, message, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useToken } from "../../../app/hooks";
import { DBFilterWrapper } from '../../../CRM/organism/DB/styles';
import API from "../../../utils/api";
import { CommercialCancel } from "./blocks/CommercialCancel";
import { CommercialConfirm } from "./blocks/CommercialConfirm";
import { CommercialDetail } from './blocks/CommercialDetail';
import { PreviewMonitor } from "./blocks/PreviewMonitor";
import { numberWithCommas } from '../../../utils/ciutils';



export const CommercialTable = ({setIsSelectItem, isSelectItem, selectedRows, setSelectedRows, isAllChecked, setIsAllChecked, page, setPage, pageSize, setPageSize, agentADList, mutateAgentADList, total, isLoading}) => {
  const [itemId, setItemId] = useState(null);
  const [modalItemId, setModalItemId] = useState(null);
  const [previewId, setPreviewId] = useState(null);

  // 유틸리티
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);

  // 데이터 조회
  const { data: token } = useToken();


  useEffect(() => {
    const allSelected = agentADList?.length > 0 && agentADList?.every(it => selectedRows?.includes(it.adId));
    setIsAllChecked(allSelected);
  }, [selectedRows, agentADList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = agentADList?.map(db => db.adId);
    setSelectedRows(e.target.checked ? ids : []);
  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const permitCommercial = (status) => {
    const shouldEdit = window.confirm(`광고를 ${status === 1 ? '승인' : '거절'}하시겠습니까?`);
    if (shouldEdit) {
      if (selectedRows?.length == 0) {
        message.error('선택한 유저가 없습니다.')
      } else {
        API.post('/agent/ad/list/permit', token + '', {
          ids: selectedRows,
          status,
          rejectReason: ''
        })
          .then((res: any) => {
            if (res.result.success) {
              message.success(`${status === 1 ? '승인' : '거절'}하였습니다.`);
              setSelectedRows([]);
              mutateAgentADList();
            }
          })
          .catch((err: any) => {
            message.error('실패하였습니다.')
          })
      }
    }
  }




  const columns = [
    {
      dataIndex: '',
      title: isSelectItem ?
        ( <Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.adId)} key={data?.adId} onChange={(e) => handleCheckboxChange(e, data?.adId)} />
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.adId || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고주',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{data?.store || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고명',
      render: (value, data, index) => {
        return (
          <div onClick={() => setItemId(data?.adId)} className='No.' style={{textDecoration: 'underline', cursor: 'pointer', justifyContent: 'center', display: 'flex'}}>{data?.title || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '요청일',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.requestedAt || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고기간',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.period || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '이미지',
      render: (value, data) => {
        return (
          <div className='date' onClick={() => {
            setPreviewId(data?.adId);
            setIsPreviewModal(true);
          }} style={{justifyContent: 'center', display: 'flex', color: '#9747FF', cursor: 'pointer'}}>미리보기</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고료',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(data?.price)} 원</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '진행현황',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{data?.status || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '처리',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex', gap: 6}}>
            {
              !data.acceptedAt && !data.rejectReason &&
              < >
                <div onClick={() => {
                  setIsConfirmModal(true)
                  setModalItemId(data?.adId);
                }} style={{
                  width: 50,
                  height: 26,
                  backgroundColor: '#530DAD',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}>승인
                </div>
                <div onClick={() => {
                  setIsCancelModal(true)
                  setModalItemId(data?.adId);
                }} style={{
                  width: 50,
                  height: 26,
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  border: '1px solid #000000',
                  borderRadius: 2
                }}>거절
                </div>
              </>
            }

            {data?.acceptedAt &&
             <div>{`승인 / ${data?.acceptedAt}`}</div>
            }

            {data?.rejectReason &&
              <div>{`사유 / ${data?.rejectReason}`}</div>
            }
          </div>
      )
      }
    },
  ];

  return (
    <DBFilterWrapper>

      <StyleTable columns={columns}
                  dataSource={agentADList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

      {itemId !== null && <CommercialDetail itemId={itemId} setItemId={setItemId} mutate={mutateAgentADList}/>}

      {isConfirmModal &&
        <CommercialConfirm itemId={modalItemId} setIsConfirmModal={setIsConfirmModal} mutate={mutateAgentADList}/>}

      {isCancelModal && <CommercialCancel itemId={modalItemId} setIsCancelModal={setIsCancelModal} mutate={mutateAgentADList} />}

      {isPreviewModal &&  <PreviewMonitor setIsPreviewModal={setIsPreviewModal} itemId={previewId} agentADList={agentADList}  />}

    </DBFilterWrapper>
  )
}


const StyleTable = styled(Table)`
 .ant-table-thead > tr > th {
    background-color: #F4F2FF;
    color: #00000099;
     font-size: 14px;
     font-weight: 500;
     line-height: 16.71px;
  }
`
