import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {DepositFilter} from "./DepositFilter";
import {DepositTable} from "./DepositTable";
import { useOrderList } from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {DepositRecharge} from "./blocks/DepositRecharge";
import {RefundRequest} from "./blocks/RefundRequest";
import {useAdvertiserTransactionList} from "../../hooks/Advertiser/Deposit/GetAdvertiserTransactionList";


export const Deposit = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [type, setType] = useState(0); // 1: 충전 | 2: 사용 | 3: 환급

  // 유틸리티
  const [rechargeModal, setRechargeModal] = useState(false)
  const [refundModal, setRefundModal] = useState(false)


  const { advertiserTransactionList, mutateAdvertiserTransactionList, total, isLoading } = useAdvertiserTransactionList(page, pageSize, type)


  return(
      <>
          <Display>
              <RegistSection>
                  <RegistSaleButton onClick={() => setRechargeModal(true)}>예치금 충전</RegistSaleButton>
              </RegistSection>

              <DepositFilter
                setType={setType}
                mutateAdvertiserTransactionList={mutateAdvertiserTransactionList}
                  setRefundModal={setRefundModal}
              />
              <DepositTable
                advertiserTransactionList={advertiserTransactionList}
                mutateAdvertiserTransactionList={mutateAdvertiserTransactionList}
                total={total}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                isLoading={isLoading}
              />
          </Display>

          {rechargeModal && <DepositRecharge setRechargeModal={setRechargeModal} mutateAdvertiserTransactionList={mutateAdvertiserTransactionList}/>  }
          {refundModal && <RefundRequest setRefundModal={setRefundModal} mutateAdvertiserTransactionList={mutateAdvertiserTransactionList}/>  }
      </>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard ,sans-serif;
`

