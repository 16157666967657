import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {InquireListFilter} from "./InquireListFilter";
import {InquireListTable} from "./InquireListTable";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {OneToOneDetail} from "./blocks/OneToOneDetail";
import {useAgentInquireList} from "../../hooks/Agent/InquireList/GetAgentInquireList";
import {useAgentMessageList} from "../../hooks/Agent/InquireList/GetAgentMessageList";


export const InquireList = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [date, setDate] = useState(''); // 2024-01-01의 형태
  const [type, setType] = useState(0); // 1: 매체주 | 2: 광고주
  const [searchWord, setSearchWord] = useState('');

  // 유틸리티
  const [isOTOModal, setIsOTOModal] = useState(false);
  const [categoryItem, setCategoryItem] = useState('문의내역');


  const {agentInquireList, mutateAgentInquireList, total:inquireTotal, isLoading:isLoadingInquire} = useAgentInquireList(page, pageSize, date, type, searchWord)
  const {agentMessageList, mutateAgentMessageList, total:messageTotal, isLoading:isLoadingMessage} = useAgentMessageList(page, pageSize, date, type, searchWord)


  return (
    <>
      <Display>
        <RegistSection>
          <SelectCategory>
            <SelectItem onClick={() => setCategoryItem('문의내역')} Active={categoryItem === '문의내역'}>문의내역</SelectItem>
            <SelectItem onClick={() => setCategoryItem('관리자메시지')} Active={categoryItem === '관리자메시지'}>관리자메시지</SelectItem>
          </SelectCategory>

          {categoryItem === '문의내역' &&  <RegistSaleButton onClick={() => setIsOTOModal(true)}>문의하기</RegistSaleButton> }
        </RegistSection>

        <InquireListTable
          agentInquireList={agentInquireList}
          mutateAgentInquireList={mutateAgentInquireList}
          categoryItem={categoryItem}
          agentMessageList={agentMessageList}
          mutateAgentMessageList={mutateAgentMessageList}
          inquireTotal={inquireTotal}
          messageTotal={messageTotal}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isLoadingInquire={isLoadingInquire}
          isLoadingMessage={isLoadingMessage}
        />
      </Display>

      {isOTOModal && <OneToOneDetail setIsOTOModal={setIsOTOModal} mutateAgentInquireList={mutateAgentInquireList}/>}
    </>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`


const SelectCategory = styled.div`
    display: flex;
    gap: 20px;
`

const SelectItem = styled.div<{ Active: boolean }>`
    font-weight: ${(props) => props.Active ? '700' : '500'};
    font-size: 24px;
    line-height: 28.64px;
    font-family: Pretendard, sans-serif;
    color: ${(props) => props.Active ? '#2E2559' : '#00000066'};
    text-decoration: ${(props) => props.Active ? 'underline' : 'none'};
    cursor: pointer;
`
