import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {CommercialFeeList} from "./blocks/CommercialFeeList";
import { numberWithCommas } from '../../../utils/ciutils';


export const CalculationTable = ({agentRefundList, mutateAgentRefundList, total, page, setPage, pageSize, setPageSize, isLoading}) => {

  // 유틸리티
  const [itemId, setItemId] = useState(null)

  // 데이터 조회


  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = agentRefundList?.length > 0 && agentRefundList?.every(it => selectedRows?.includes(it.refundId));
    setIsAllChecked(allSelected);
  }, [selectedRows, agentRefundList]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      dataIndex: '',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.refundId || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '계좌정보',
      render: (value, data) => {
        return (
          <div className='email' onClick={() => setItemId(data?.refundId)}
               style={{
                 textDecoration: 'underline',
                 cursor: 'pointer',
                 justifyContent: 'center',
                 display: 'flex'
               }}>{data?.account || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '신청금액',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(data?.amount)+' 원'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '신청일',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.createdAt || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='type' style={{
            justifyContent: 'center',
            display: 'flex'
          }}>{data?.status === 1 ? data?.status : `${data?.status} ${data?.refundAt || ''}`}</div>
        )
      }
    },
  ];

  return (
    <DBFilterWrapper>
      <DBFilterCheckboxArea>

      </DBFilterCheckboxArea>
      <StyleTable columns={columns}
                  dataSource={agentRefundList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />


      {itemId !== null && <CommercialFeeList setItemId={setItemId} itemId={itemId}/>}
    </DBFilterWrapper>
  )
}

export default React.memo(CalculationTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
