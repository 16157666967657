import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {PreviewMonitor} from "./blocks/PreviewMonitor";
import {SalePreviewMonitor} from "./blocks/SalePreviewMonitor";



export const ContentsManageTable = ({
                                      adminContentsList,
                                      mutateAdminContentsList,
                                      total,
                                      page,
                                      setPage,
                                      pageSize,
                                      setPageSize,
                                      isLoading,
                                      setIsSelectItem,isSelectItem, selectedRows, setSelectedRows, isAllChecked, setIsAllChecked
                                    }) => {
  const [itemId, setItemId] = useState(null);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [isSalePreviewModal, setSaleIsPreviewModal] = useState(false);

  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = adminContentsList?.length > 0 && adminContentsList?.every(it => selectedRows?.includes(it.adId));
    setIsAllChecked(allSelected);
  }, [selectedRows, adminContentsList]);



  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = adminContentsList?.map(db => db.adId);
    setSelectedRows(e.target.checked ? ids : []);
  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const customerColumns = [
    {
      dataIndex: '',
      title: isSelectItem ?
        ( <Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.adId)} key={data?.adId} onChange={(e) => handleCheckboxChange(e, data?.adId)} />
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.adId || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: 'type',
      title: '구분',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'title',
      title: '제목',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'status',
      title: '상태',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '파일',
      render: (value, data) => {
        return (
          <div className='type' onClick={() => {
            // if(data?.type === '광고'){
            //   setIsPreviewModal(true);
            //   setItemId(data?.adId)
            // } else {
            //   setSaleIsPreviewModal(true);
            //   setItemId(data?.adId)
            // }
              setIsPreviewModal(true);
              setItemId(data?.adId)
          }} style={{justifyContent: 'center', display: 'flex', color: '#9747FF', cursor: 'pointer'}}>미리보기</div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '등록일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value || '-'}</div>
        )
      }
    },
  ];


  return (
    <DBFilterWrapper>

      <StyleTable columns={customerColumns}
                  dataSource={adminContentsList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

      {/*{orderId !== null && <OrderEdit orderIdd={orderId} setOrderIdd={setOrderId} mutate={mutateOrderList} orderList={orderList} />}*/}
      {isPreviewModal &&
        <PreviewMonitor setIsPreviewModal={setIsPreviewModal} itemId={itemId} adminContentsList={adminContentsList}/>}
      {isSalePreviewModal &&  <SalePreviewMonitor setIsPreviewModal={setSaleIsPreviewModal} itemId={itemId} agentSaleItem={null}  />}


    </DBFilterWrapper>
  )
}

export default React.memo(ContentsManageTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
