import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {AdminInquireFilter} from "./AdminInquireFilter";
import {AdminInquireTable} from "./AdminInquireTable";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAdminNoticeList} from "../../hooks/Admin/AdminNotice/GetAdminNoticeList";
import {useAdminInquiryList} from "../../hooks/Admin/AdminInquire/GetAdmininquiryList";
import {useAdminMessageList} from "../../hooks/Admin/AdminInquire/GetAdminMessageList";


export const AdminInquire = () => {
  //필터링
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [date, setDate] = useState('');
  const [type, setType] = useState<0 | 1 | 2>(0);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<'latest' | 'oldest'>('latest');

  // 유틸리티
  const [categoryItem, setCategoryItem] = useState('수신내역');
  const [isSendMessage, setIsSendMessage] = useState(false);


  // 데이터 조회
  const {
    adminInquiryList,
    mutateAdminInquiryList, total: inquiryTotal, isLoading:isLoadingInquiry
  } = useAdminInquiryList(page, pageSize, date, type, searchWord, order);
  const {adminMessageList, mutateAdminMessageList, total:messageTotal, isLoading:isLoadingMessage} = useAdminMessageList(page, pageSize, date, type, searchWord, order)


  return (
    <Display>

      <RegistSection>
        <SelectCategory>
          <SelectItem onClick={() => setCategoryItem('수신내역')} Active={categoryItem === '수신내역'}>수신내역</SelectItem>
          <SelectItem onClick={() => setCategoryItem('발신내역')} Active={categoryItem === '발신내역'}>발신내역</SelectItem>
        </SelectCategory>

        {categoryItem === '발신내역' &&  <RegistSaleButton onClick={() => setIsSendMessage(true)}>메세지 보내기</RegistSaleButton>}
      </RegistSection>


      <AdminInquireFilter
        categoryItem={categoryItem}
        setSearchWord={setSearchWord}
        setOrder={setOrder}
        order={order}
        setType={setType}
        mutateAdminInquiryList={mutateAdminInquiryList}
      />

      <AdminInquireTable
        categoryItem={categoryItem}
        adminInquiryList={adminInquiryList}
        mutateAdminInquiryList={mutateAdminInquiryList}
        adminMessageList={adminMessageList}
        setIsSendMessage={setIsSendMessage}
        isSendMessage={isSendMessage}
        mutateAdminMessageList={mutateAdminMessageList}
        inquiryTotal={inquiryTotal}
        messageTotal={messageTotal}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        isLoadingInquiry={isLoadingInquiry}
        isLoadingMessage={isLoadingMessage}
      />
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`


const SelectCategory = styled.div`
    display: flex;
    gap: 20px;
`

const SelectItem = styled.div<{ Active: boolean }>`
    font-weight: ${(props) => props.Active ? '700' : '500'};
    font-size: 24px;
    line-height: 28.64px;
    font-family: Pretendard, sans-serif;
    color: ${(props) => props.Active ? '#2E2559' : '#00000066'};
    text-decoration: ${(props) => props.Active ? 'underline' : 'none'};
    cursor: pointer;
`
