import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/agent/refund/list' // 정산목록 조회

const fetchKey = (page, maxData, status) =>
	`${M_KEY}?${page && 'page=' + page}${maxData && '&maxData=' + maxData}${status > 0 ? '&status=' + status : ''}`;


export function useAgentRefundList(page, maxData, status) {
	const { data: token } = useToken();

	const KEY = fetchKey(page, maxData, status);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate, isLoading } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { agentRefundList: data?.list, mutateAgentRefundList: mutate, total: data?.total, isLoading  };
}

async function fetchAPI(KEY:string, token:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Agent Refund List:', error);
		return { list: [], total: 0 };
	}
}