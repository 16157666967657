import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";


const KEY = '/agent/progress/list/summary' // 매물목록조회 요약

export function useAgentOutputSummaryList() {
	const { data: token } = useToken()
	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { agentOutputSummaryList: data, mutateAgentOutputSummaryList: mutate };
}

async function fetchAPI(KEY:string, token:any,) {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return response.result?.result?.list || {};
	} catch (error) {
		console.error('Failed to fetch Agent Output Summary List:', error);
		return [];
	}
}