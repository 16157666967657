import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/advertiser/agent/list' // 광고목록 조회

const fetchKey = (agentId) =>
	`${M_KEY}/${agentId}`;

export function useAdvertiserAgentIDList(agentId) {
	const { data: token } = useToken();

	const KEY = fetchKey(agentId);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { advertiserAgentIDList: data, mutateAdvertiserAgentIDList: mutate, };
}

async function fetchAPI(KEY:string, token:any) {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return response.result?.result || {}
	} catch (error) {
		console.error('Failed to fetch Advertiser Agent ID List:', error);
		return { list: [], total: 0 };
	}
}