import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {Select, Table} from "antd";
import {HomeTownRoundCheckbox} from "../../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {useAdminUserInfo} from "../../../hooks/Admin/UserManage/GetAdminUserInfo";
import {useAdminUserADList} from "../../../hooks/Admin/UserManage/GetAdminUserADList";
import {useAdminPaymentDepositInfo} from "../../../hooks/Admin/PaymentManage/GetAdminPaymentDepositInfo";
import {useAdminPaymentDetailList} from "../../../hooks/Admin/PaymentManage/GetAdminPaymentDetailList";
import { formatPhoneNumber, numberWithCommas } from '../../../../utils/ciutils';


export const PaymentDetail = ({userId, setUserId, categoryItem}) => {
    const [cardSelected, setCardSelected] = React.useState('customerCard');
    const [commercialId, setCommercialId] = React.useState(null);

    // 데이터 조회
    const {adminPaymentDepositInfo, mutateAdminPaymentDepositInfo} = useAdminPaymentDepositInfo(userId);
    const {adminPaymentDetailList, mutateAdminPaymentDetailList} = useAdminPaymentDetailList(categoryItem === '광고주' && 'ad' || categoryItem === '매체주' && 'agent' || categoryItem === '환급신청내역' && 'return' ,1, 1000, userId);

    // useEffect(() => {
    //
    //
    //     console.log('adminUserADList', adminUserADList)
    // }, [adminUserADList]);


    const columns = [
        {
            dataIndex: 'paymentId',
            title: 'No',
            render: (value, data, index) => {
                return (
                  <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
                )
            }
        },
        {
            dataIndex: 'type',
            title: '구분',
            render: (value, data, index) => {
                return (
                  <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
                )
            }
        },
        {
            dataIndex: 'content',
            title: '내역',
            render: (value, data) => {
                return (
                  <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
                )
            }
        },
        {
            dataIndex: 'amount',
            title: '금액',
            render: (value, data) => {
                return (
                  <div className='date' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(value)+' 원'}</div>
                )
            }
        },
        {
            dataIndex: 'createdAt',
            title: '날짜',
            render: (value, data) => {
                return (
                  <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{`${value || 0}`}</div>
                )
            }
        },
        {
            dataIndex: 'memo',
            title: '수단',
            render: (value, data) => {
                return (
                  <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{`${value || '-'}`}</div>
                )
            }
        },
    ];

    const realColumns = [
        {
            dataIndex: 'paymentId',
            title: 'No',
            render: (value, data, index) => {
                return (
                  <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
                )
            }
        },
        {
            dataIndex: 'optionName',
            title: '옵션',
            render: (value, data, index) => {
                return (
                  <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
                )
            }
        },
        {
            dataIndex: 'content',
            title: '내역',
            render: (value, data) => {
                return (
                  <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
                )
            }
        },
        {
            dataIndex: 'amount',
            title: '결제일',
            render: (value, data) => {
                return (
                  <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{`${value || 0}`}</div>
                )
            }
        },
        {
            dataIndex: 'createdAt',
            title: '날짜',
            render: (value, data) => {
                return (
                  <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{`${value || 0}`}</div>
                )
            }
        },
        {
            dataIndex: 'memo',
            title: '수단',
            render: (value, data) => {
                return (
                  <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{`${value || '-'}`}</div>
                )
            }
        },
    ];

    const refundColumns = [
        {
            dataIndex: 'paymentId',
            title: 'No',
            render: (value, data, index) => {
                return (
                  <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
                )
            }
        },
        {
            dataIndex: 'account',
            title: '계좌내역',
            render: (value, data, index) => {
                return (
                  <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
                )
            }
        },
        {
            dataIndex: 'amount',
            title: '금액',
            render: (value, data) => {
                return (
                  <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value || 0}</div>
                )
            }
        },
        {
            dataIndex: 'createdAt',
            title: '신청일',
            render: (value, data) => {
                return (
                  <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{`${value || '-'}`}</div>
                )
            }
        },
        {
            dataIndex: 'status',
            title: '상태',
            render: (value, data) => {
                return (
                  <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{`${value === 0 ? '환급필요' : '환급완료' || '-'}`}</div>
                )
            }
        },

    ];

    const OutColumn = () => {
        if(categoryItem === '광고주') {
            return columns
        } else if (categoryItem === '매체주') {
            return realColumns
        } else {
            return refundColumns
        }
    }

    return (
      <>
          <Blacked onClick={() => setUserId(null)}/>
          <ModalWrapper>
              <ModalHeader>
                  {categoryItem === '광고주' &&
                    <ModalTitle>광고주 결제내역</ModalTitle>
                  }

                  {categoryItem === '매체주' &&
                    <ModalTitle>매체주 결제내역</ModalTitle>
                  }

                  {categoryItem === '환급신청내역' &&
                    <ModalTitle>환급신청내역</ModalTitle>
                  }
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setUserId(null)}  }/>
              </ModalHeader>

              <ContentsTitle>회원정보</ContentsTitle>
              <CustomTable>
                  <tbody>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              회원명
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>{adminPaymentDepositInfo?.name || '-'}</Td>
                  </TableRow>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              아이디
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          {adminPaymentDepositInfo?.email || '-'}
                      </Td>
                  </TableRow>

                  <TableRow>
                      <Th>
                          <ThLabel>
                              상호명
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          {adminPaymentDepositInfo?.store || '-'}
                      </Td>
                  </TableRow>

                  <TableRow>
                      <Th>
                          <ThLabel>
                              휴대폰번호
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>{adminPaymentDepositInfo?.phone || '-'}</Td>
                  </TableRow>

                  {categoryItem === '광고주' &&
                    <TableRow>
                        <Th>
                            <ThLabel>
                                예치금
                            </ThLabel>
                        </Th>
                        <Td colSpan={3}>{adminPaymentDepositInfo?.deposit || '-'}</Td>
                    </TableRow>
                  }


                  </tbody>
              </CustomTable>

              <ContentsTitle>결제내역<ContentsSubTitle>{`${adminPaymentDetailList?.length || 0}건`}</ContentsSubTitle></ContentsTitle>

              <StyleTable columns={OutColumn()}
                          dataSource={adminPaymentDetailList}
                          pagination={false}
              />


              <ButtonsWrapper>
                  <CancelButton onClick={() => setUserId(null)}>
                      확인
                  </CancelButton>
              </ButtonsWrapper>
          </ModalWrapper>


      </>
    )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 700px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const CustomTable = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 25%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    
`


const Td = styled.td`
    padding: 10px;
    text-align: left;
    // width: 78%;
    border: 1px solid #ccc;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
`;

const ContentsTitle = styled.div`
    width: 100%;
    margin-top: 20px;
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    display: flex;
    gap: 8px;
`

const ContentsSubTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.75px;
    color: #000000CC;
`

const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 6px;
`

const SignUpButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const StyleTable = styled(Table)`
    width: 700px;
 .ant-table-thead > tr > th {
    background-color: #F5F5F5;
    color: #00000099;
     font-size: 14px;
     font-weight: 500;
     line-height: 16.71px;
     text-align: center;
  }
`