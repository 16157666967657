import React, {useEffect, useState} from 'react'
import {
	DBFilterDateArea,
	DBFilterWrapper,
} from '../../../CRM/organism/DB/styles'
import { Select, Input, ConfigProvider, DatePicker, Upload, message } from 'antd'
import 'dayjs/locale/ko'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {ReactComponent as SearchIcon} from "./assets/SearchIcon.svg";
import {useAgentOutputSummaryList} from "../../hooks/Agent/OutputManage/GetAgentOutputSummaryList";
import API from "../../../utils/api";
import {useToken} from "../../../app/hooks";


export const OutputManageFilter = ({setOrder, order, setSearchWord, setStatus, setType, setChangeOrder, changeOrder, progressList, mutateAgentOutputList}) => {
	// 상태값
	const [inputSearch, setInputSearch] = useState('')
	const [inputType, setInputType] = useState('')
	const [inputStatus, setInputStatus] = useState(0)

	// 데이터 조회
	const {agentOutputSummaryList, mutateAgentOutputSummaryList} = useAgentOutputSummaryList();
	const {data: token} = useToken();


	// const postChangeOrder = () => {
	// 	const shouldEdit = window.confirm(`순서를 변경하시겠습니까?`);
	// 	if(progressList.length === 0) {
	// 		message.error('순서가 변경된 사항이 없습니다.')
	// 	} else if (shouldEdit) {
	// 			API.post('/agent/progress/list', token + '', {
	// 				list: progressList
	// 			})
	// 				.then((res: any) => {
	// 					if (res.result.success) {
	// 						message.success(`순서가 변경되었습니다.`);
	// 						mutateAgentOutputList();
	// 						setChangeOrder(false);
	// 					} else {
	// 						message.error(res.result.message || '실패하였습니다');
	// 					}
	// 				})
	// 				.catch((err: any) => {
	// 					message.error('실패하였습니다.')
	// 				})
	// 	}
	// }


	const IsSearch = () => {
		setSearchWord(inputSearch);
		setStatus(inputStatus);
		setType(inputType);
	}


	const TransactionCategory = [
		{
			name: '전체 선택',
			value : ''
		},
		{
			name: '광고',
			value : 'ad'
		},
		{
			name: '매물',
			value : 'sale'
		},
	]

	const ProcessStateItem = [
		{
			name: '전체 상태',
			value : 0
		},
		{
			name: '광고중',
			value : 1
		},
		{
			name: '광고대기',
			value : 2
		},
		{
			name: '광고중(종료예정)',
			value : 3
		},
	]


	return (
		<DBFilterWrapper style={{ backgroundColor: '#FAFAFC' }}>
			<DBFilterDateArea style={{ justifyContent: 'space-between' }}>
				<ItemCardsWrapper>
					{agentOutputSummaryList?.map((item, index) => (
						<ItemCard>
							<CardTitle>
								{item?.title}
							</CardTitle>
							<CardCount>
								{item?.value}
							</CardCount>
						</ItemCard>
					))}
				</ItemCardsWrapper>
			</DBFilterDateArea>

			{changeOrder ?
				<DBFilterDateArea
					style={{justifyContent: 'space-between', backgroundColor: '#E3E3E3', height: 60, padding: '0 16px'}}>
					<SelectItemCount>
						<ButtonsWrapper>
							{/*<ItemButton*/}
							{/*	onClick={() => {*/}
							{/*		postChangeOrder();*/}
							{/*	}}*/}
							{/*>순서저장</ItemButton>*/}
						</ButtonsWrapper>
					</SelectItemCount>

					<SearchWrapper>
						<ItemButton onClick={() => {
							setChangeOrder(false);
						}}>
							취소
						</ItemButton>
					</SearchWrapper>
				</DBFilterDateArea>
				:
				<DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
					<RoundCheckWrapper>
						{/*<HomeTownRoundCheckbox text={'최신순'} checked={order === 'latest'} onClick={() => setOrder('latest')}/>*/}
						{/*<HomeTownRoundCheckbox text={'과거순'} checked={order === 'oldest'} onClick={() => setOrder('oldest')}/>*/}
					</RoundCheckWrapper>

					<SearchWrapper>
						<AllCheckButton onClick={() => {setChangeOrder(true)}}>
							순서변경
						</AllCheckButton>

						<StyledSelect value={inputType} onChange={e => setInputType(e as string)}>
							{TransactionCategory?.map((item, index) => (
								<Select.Option value={item.value}>{item.name}</Select.Option>
							))}
						</StyledSelect>

						<StyledSelect value={inputStatus} onChange={e => setInputStatus(e as number)}>
							{ProcessStateItem?.map((item, index) => (
								<Select.Option value={item.value}>{item.name}</Select.Option>
							))}
						</StyledSelect>

						<StyleInput placeholder={'검색'} suffix={<SearchIconSVG as={SearchIcon}/>} onChange={e => setInputSearch(e.target.value as string)}  />

						<SearchButton onClick={() => IsSearch()}>
							조회
						</SearchButton>
					</SearchWrapper>
				</DBFilterDateArea>
			}
		</DBFilterWrapper>
	)
}

const ItemCardsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`

const ItemCard = styled.div`
	box-shadow: 0px 0px 10px 0px #0000000D;
	border-radius: 4px;
	width: 146px;
	height: 88px;
	padding: 18px 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`

const CardTitle = styled.div`
	font-weight: 400;
	font-size: 15px;
	line-height: 17.9px;
	font-family: Pretendard, sans-serif;
`

const CardCount = styled.div`
 text-align: right;
	font-weight: 600;
	font-size: 20px;
	line-height: 23.87px;
	font-family: Pretendard, sans-serif;
	color: #F15151;
`

const RoundCheckWrapper = styled.div`
	display: flex;
	gap: 14px;
`

const SearchWrapper = styled.div`
	display: flex;
	gap: 6px;
`

const AllCheckButton = styled.div`
	border: 1px solid #FF6C4B;
	width: 92px;
	height: 36px;
	font-weight: 400;
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FF6C4B;
	cursor: pointer;
`

const SearchButton = styled.div`
	background-color: #FF6C4B;
	width: 66px;
	height: 36px;
	border-radius: 4px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 17.9px;
	cursor: pointer;
`

const StyledSelect = styled(Select)`
	width: 170px;
	height: 36px;
	border-radius: 4px;
	
`

const TemporarySave = styled.div`
	width: 146px;
	height: 41px;
	display: flex;
	border: 1px solid #00000033;
	box-shadow: 0px 0px 10px 0px #0000000D;
	align-items: center;
	justify-content: center;
	gap: 53px;
	border-radius: 4px;
	
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	font-weight: 400;
`

const TemporaryWrapper = styled.div`
	
`

const TemporaryCount = styled.div`
	font-weight: 500;
	color: #F15151;
`

const PastSaleText = styled.div`
	font-weight: 400;
	font-size: 15px;
	line-height: 17.9px;
	color: #00000099;
	text-decoration: underline;
	text-align: right;
	margin-top: 6px;
`

const StyleInput = styled(Input)`
	width: 238px;
	height: 36px;

	.ant-input::placeholder {
		color: #535353; /* 원하는 색상으로 변경 */
	}
`

const SearchIconSVG = styled.svg`
	width: 20px;
`

const SelectItemCount = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`

const ItemButton = styled.div`
    padding: 9px 20px;
    min-width: 36px;
    background: #FFFFFF;
    border: 1px solid #0000004D;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`
