import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";


const M_KEY = '/admin/payment/list' // 광고상세조회

const fetchKey = (id) =>
	`${M_KEY}/${id}/deposit`;

export function useAdminPaymentDepositInfo(id) {
	const { data: token } = useToken();

	const KEY = fetchKey(id);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { adminPaymentDepositInfo: data, mutateAdminPaymentDepositInfo: mutate};
}

async function fetchAPI(KEY:string, token:any)  {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return response.result?.resunt;
	} catch (error) {
		console.error('Failed to fetch Admin Payment Deposit Info:', error);
		return { };
	}
}