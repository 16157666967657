import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/agent/progress/list' // 출력관리 목록조회

const fetchKey = (page, maxData, type, status, order, searchWord) =>
	`${M_KEY}?${page && 'page=' + page}${maxData && '&maxData=' + maxData}${type && '&type=' + type}${status > 0 ? '&status=' + status : ''}${order && '&order=' + order}${searchWord && '&searchWord=' + searchWord}`;

export function useAgentOutputList(page, maxData, type, status, order, searchWord) {
	const { data: token } = useToken();

	const KEY = fetchKey(page, maxData, type, status, order, searchWord);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate, isLoading } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { agentOutputList: data?.list, mutateAgentOutputList: mutate, total: data?.total, isLoading };
}

async function fetchAPI(KEY:string, token:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Agent Output List:', error);
		return { list: [], total: 0 };
	}
}