import useSWR from "swr";
import API from "../../../../utils/api";
import { useToken } from "../../../../app/hooks";

const KEY = "/admin/user/list"; // 사용자 목록 조회

export function useUserList(
  page: number,
  maxData: number,
  type: "ad" | "agent",
  status: 0 | 1,
  searchWord: string,
  order: "latest" | "oldest"
) {
  const { data: token } = useToken();
  const fetcher = () =>
    fetchAPI(KEY, token, page, maxData, type, status, searchWord, order);
  const { data, mutate, isLoading } = useSWR(
    token
      ? `${KEY}${page}${maxData}${type}${status}${searchWord}${order}`
      : null,
    fetcher,
    { revalidateOnFocus: true }
  );

  return { userList: data?.list, mutateUserList: mutate, total: data?.total, isLoading };
}

async function fetchAPI(
  KEY: string,
  token: any,
  page: number,
  maxData: number,
  type: "ad" | "agent",
  status: 0 | 1,
  searchWord: string,
  order: "latest" | "oldest"
) {
  try {
    const url = `${KEY}?page=${page}&maxData=${maxData}&type=${type}&status=${status}&searchWord=${searchWord}&order=${order}`;
    const response = await API.get(`${url}`, token + "", {});
    return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
  } catch (error) {
    console.error("Failed to fetch user list:", error);
    return { list: [], total: 0 };
  }
}
