import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import styled from "styled-components";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";
import {useAgentSaleInfo} from "../../../hooks/Agent/SaleProperty/GetAgentSaleInfo";


export const PreviewMonitor = ({setIsPreviewModal, itemId, agentSaleItem}) => {
  // const [selectedItem, setSelectedItem] = React.useState<any>({});

  const {agentSaleInfo} = useAgentSaleInfo(itemId);

  // useEffect(() => {
  //   console.log('agentSaleList', agentSaleList);
  //   setSelectedItem(agentSaleList.find((item) => itemId === item.adId));
  // }, [agentSaleList]);


  useEffect(() => {
    console.log('agentSaleInfo', agentSaleInfo);
  }, [agentSaleInfo]);

  return (
    <>
      <Blacked onClick={() => setIsPreviewModal(false)}/>
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>미리보기</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {
            setIsPreviewModal(false)
          }}/>
        </ModalHeader>

        <Contents>
          <MonitorTitle>전면</MonitorTitle>
          {itemId ?
            <MonitorWrapper style={{display: 'flex'}}>
              <FullImg src={agentSaleInfo?.fileList && MEDIA_URL + agentSaleInfo?.fileList[0]?.url}/>
              <SaleWrapper>
                <SaleTitle>{`${agentSaleInfo?.type || ''}`}
                  <SaleTitleColor>{agentSaleInfo?.transactionType || ''}</SaleTitleColor>
                </SaleTitle>
                <SaleAddress>{`${agentSaleInfo?.address || ''}`}</SaleAddress>
                <SalePrice>{`${agentSaleInfo?.price || ''}`}</SalePrice>
                <SaleSize>{`${agentSaleInfo?.size || ''}`}</SaleSize>
                <SaleDescription>{`${agentSaleInfo?.desc || ''}`}</SaleDescription>
              </SaleWrapper>
            </MonitorWrapper>
          :
            <MonitorWrapper style={{display: 'flex'}}>
              <FullImg src={agentSaleItem?.fileList && MEDIA_URL + agentSaleItem?.fileList[0]?.url}/>
              <SaleWrapper>
                <SaleTitle>{`${agentSaleItem?.type || ''}`}
                  <SaleTitleColor>{agentSaleInfo?.transactionType || ''}</SaleTitleColor>
                </SaleTitle>
                <SaleAddress>{`${agentSaleItem?.address || ''}`}</SaleAddress>
                <SalePrice>{`${agentSaleItem?.price || ''}`}</SalePrice>
                <SaleSize>{`${agentSaleItem?.size || ''}`}</SaleSize>
                <SaleDescription>{`${agentSaleItem?.desc || ''}`}</SaleDescription>
              </SaleWrapper>
            </MonitorWrapper>
          }
        </Contents>

        <Contents>
          <MonitorTitle>2면</MonitorTitle>
          <MonitorWrapper style={{padding: 4}}>
            <div style={{display: "flex", justifyContent: "center", gap: 6}}>
              {itemId ?
                <div style={{display: "flex", border: '1px solid black', width: '198px'}}>
                  <TwoBigImg src={agentSaleInfo?.fileList && MEDIA_URL + agentSaleInfo?.fileList[0]?.url}/>
                  <TwoSaleWrapper>
                    <TwoSaleTitle>{`${agentSaleInfo?.type || ''}`}
                      <SaleTitleColor>{agentSaleInfo?.transactionType || ''}</SaleTitleColor>
                    </TwoSaleTitle>
                    <TwoSaleAddress>{`${agentSaleInfo?.address || ''}`}</TwoSaleAddress>
                    <TwoSalePrice>{`${agentSaleInfo?.price || ''}`}</TwoSalePrice>
                    <TwoSaleSize>{`${agentSaleInfo?.size || ''}`}</TwoSaleSize>
                    <TwoSaleDescription>{`${agentSaleInfo?.desc || ''}`}</TwoSaleDescription>
                  </TwoSaleWrapper>
                </div>
                :
                <div style={{display: "flex", border: '1px solid black', width: '198px'}}>
                  <TwoBigImg src={agentSaleItem?.fileList && MEDIA_URL + agentSaleItem?.fileList[0]?.url}/>
                  <TwoSaleWrapper>
                    <TwoSaleTitle>{`${agentSaleItem?.type || ''}`}
                      <SaleTitleColor>{agentSaleInfo?.transactionType || ''}</SaleTitleColor>
                    </TwoSaleTitle>
                    <TwoSaleAddress>{`${agentSaleItem?.address || ''}`}</TwoSaleAddress>
                    <TwoSalePrice>{`${agentSaleItem?.price || ''}`}</TwoSalePrice>
                    <TwoSaleSize>{`${agentSaleItem?.size || ''}`}</TwoSaleSize>
                    <TwoSaleDescription>{`${agentSaleItem?.desc || ''}`}</TwoSaleDescription>
                  </TwoSaleWrapper>
                </div>
              }
              <TwoBig/>
            </div>

            <div style={{display: "flex", justifyContent: "center", gap: 5, marginTop: 5}}>
              <TwoSmall/>
              <TwoSmall/>
              <TwoSmall/>
              <TwoSmall/>
            </div>
          </MonitorWrapper>
        </Contents>

        <Contents>
          <MonitorTitle>6면</MonitorTitle>
          <MonitorWrapper style={{padding: 4}}>
            <div style={{display: "flex", justifyContent: "center", gap: 3}}>
              {itemId ?
                <SixSaleWrapper>
                  <SixSaleTitle>{`${agentSaleInfo?.type || ''}`}
                    <SaleTitleColor>{agentSaleInfo?.transactionType || ''}</SaleTitleColor>
                  </SixSaleTitle>
                  <SixSaleAddress>{`${agentSaleInfo?.address || ''}`}</SixSaleAddress>
                  <SixSalePrice>{`${agentSaleInfo?.price || ''}`}</SixSalePrice>
                  <SixSaleSize>{`${agentSaleInfo?.size || ''}`}</SixSaleSize>
                </SixSaleWrapper>
              :
                <SixSaleWrapper>
                  <SixSaleTitle>{`${agentSaleItem?.type || ''}`}
                    <SaleTitleColor>{agentSaleInfo?.transactionType || ''}</SaleTitleColor>
                  </SixSaleTitle>
                  <SixSaleAddress>{`${agentSaleItem?.address || ''}`}</SixSaleAddress>
                  <SixSalePrice>{`${agentSaleItem?.price || ''}`}</SixSalePrice>
                  <SixSaleSize>{`${agentSaleItem?.size || ''}`}</SixSaleSize>
                </SixSaleWrapper>
              }
              <SixBox />
              <SixBox />
            </div>

            <div style={{display: "flex", justifyContent: "center", gap: 3, marginTop: 3}}>
              <SixBox />
              <SixBox />
              <SixBox />
            </div>
          </MonitorWrapper>
        </Contents>

      </ModalWrapper>
    </>
  )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 820px;
    width: 580px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Contents = styled.div`
    margin-top: 45px;
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
    color: #000000;
    white-space: pre-wrap;
    text-align: center;
`

const MonitorTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: pretendard, sans-serif;
    margin-bottom: 10px;
`

const MonitorWrapper = styled.div`
    border: 12.87px solid #000000;
    width: 437.61px;
    height: 257.42px;
    
`

const FullImg = styled.img`
    background-color: #D9D9D9;
    width: 50%;
    height: 100%;
`

const SaleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
`

const SaleTitle = styled.div`
    font-size: 20px;
    margin-bottom: 15px;
    display: flex;
`

const SaleTitleColor = styled.div`
    color: #f33636;
`

const SalePrice = styled.div`
    font-size: 19px;
    margin-bottom: 15px;
    color: #125de6;
`

const SaleAddress = styled.div`
    font-size: 10px;
    margin-bottom: 4px;
`

const SaleSize = styled.div`
    font-size: 12px;
    margin-bottom: 10px;
`

const SaleDescription = styled.div`
    font-size: 10px;
    margin-bottom: 4px;
    white-space: pre-wrap;
`



const TwoBigImg = styled.img`
    background-color: #D9D9D9;
    width: 50%;
    height: 142.01px;
`


const TwoSaleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
`

const TwoSaleTitle = styled.div`
    font-size: 10px;
    margin-bottom: 5;
    display: flex;
    align-items: center;
`

const TwoSalePrice = styled.div`
    font-size: 10px;
    margin-bottom: 5px;
    color: #125de6;
`

const TwoSaleAddress = styled.div`
    font-size: 6px;
`

const TwoSaleSize = styled.div`
    font-size: 7px;
    margin-bottom: 5px;
`

const TwoSaleDescription = styled.div`
    font-size: 5px;
    line-height: 7px;
    white-space: pre-wrap;
`






const TwoBig = styled.div`
    background-color: #D9D9D9;
    width: 198px;
    height: 142.01px;
`

const TwoSmall = styled.img`
    background-color: #D9D9D9;
    width: 96.96px;
    height: 77.01px;
`

const SixImg = styled.img`
    background-color: #D9D9D9;
    width: 133px;
    height: 111.55px;
`

const SixBox = styled.div`
    background-color: #D9D9D9;
    width: 133px;
    height: 111.55px;
`


const SixSaleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 133px;
    border: black 1px solid;
`

const SixSaleTitle = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
`

const SixSalePrice = styled.div`
    font-size: 10px;
    color: #125de6;
`

const SixSaleAddress = styled.div`
    font-size: 6px;
`

const SixSaleSize = styled.div`
    font-size: 7px;
`

const SixSaleDescription = styled.div`
    font-size: 4px;
    line-height: 6px;
    white-space: pre-wrap;
`
