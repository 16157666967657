import React, {useEffect, useState} from 'react'
import {
	DBFilterDateArea,
	DBFilterWrapper,
	DBTableExcelBtn,
	DateArea,
	GotoStatus,
} from '../../../CRM/organism/DB/styles'
import { Select, Input, ConfigProvider, DatePicker, Upload, message } from 'antd'
import { RangePickerProps } from 'rc-picker/es/RangePicker.d'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import locale from 'antd/lib/locale/ko_KR'
import { Icon } from '../../../CRM/organism/Main/styles'
import { IoSearch } from 'react-icons/io5'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {ReactComponent as SearchIcon} from "./assets/SearchIcon.svg";


export const AdminNoticeFilter = ({setSearchWord, setOrder, mutateAdminNoticeList, categoryItem, order}) => {
	const [inputSearchWord, setInputSearchWord] = useState('');


	const isSearch = () => {
		setSearchWord(inputSearchWord);
		mutateAdminNoticeList();
	}


	const CommercialStateItem = [
		{
			name: '전체',
			value : 0
		},
		{
			name: '지역',
			value : 0
		},
	]

	const OptionlStateItem = [
		{
			name: '기본',
			value : 0
		},
		{
			name: 'TV렌탈',
			value : 0
		},
		{
			name: '인터넷+TV렌탈',
			value : 0
		},
	]

	const PaymentStateItem = [
		{
			name: '전체',
			value : 0
		},
		{
			name: '월 결제',
			value : 0
		},
		{
			name: '자동이체',
			value : 0
		},
	]

	const RefundStateItem = [
		{
			name: '환급필요',
			value : 0
		},
		{
			name: '환급완료',
			value : 0
		},
	]


	return (
		<DBFilterWrapper style={{ backgroundColor: '#FAFAFC' }}>

			<DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
				<RoundCheckWrapper>
					<HomeTownRoundCheckbox text={'최신순'} checked={order === 'latest'} onClick={() => setOrder('latest')}/>
					<HomeTownRoundCheckbox text={'과거순'} checked={order === 'oldest'} onClick={() => setOrder('oldest')}/>
				</RoundCheckWrapper>

				<SearchWrapper>
					{/*<StyledSelect value={'대상 전체'}>*/}
					{/*	{CommercialStateItem?.map((item, index) => (*/}
					{/*		<Select.Option value={item.name}>{item.name}</Select.Option>*/}
					{/*	))}*/}
					{/*</StyledSelect>*/}


					<StyleInput placeholder={'검색'} value={inputSearchWord} onChange={e => setInputSearchWord(e.target.value as string)} suffix={<SearchIconSVG as={SearchIcon} />} />

					<SearchButton onClick={() => isSearch()}>
						조회
					</SearchButton>
				</SearchWrapper>
			</DBFilterDateArea>
		</DBFilterWrapper>
	)
}

const ItemCardsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`

const ItemCard = styled.div`
	box-shadow: 0px 0px 10px 0px #0000000D;
	border-radius: 4px;
	width: 146px;
	height: 88px;
	padding: 18px 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`

const CardTitle = styled.div`
	font-weight: 400;
	font-size: 15px;
	line-height: 17.9px;
	font-family: Pretendard, sans-serif;
`

const CardCount = styled.div`
 text-align: right;
	font-weight: 600;
	font-size: 20px;
	line-height: 23.87px;
	font-family: Pretendard, sans-serif;
	color: #F15151;
`

const RoundCheckWrapper = styled.div`
	display: flex;
	gap: 14px;
`

const SearchWrapper = styled.div`
	display: flex;
	gap: 6px;
`

const AllCheckButton = styled.div`
	border: 1px solid #FF6C4B;
	width: 92px;
	height: 36px;
	font-weight: 400;
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FF6C4B;
	cursor: pointer;
`

const SearchButton = styled.div`
	background-color: #FF6C4B;
	width: 66px;
	height: 36px;
	border-radius: 4px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 17.9px;
	cursor: pointer;
`

const StyledSelect = styled(Select)`
	width: 170px;
	height: 36px;
	border-radius: 4px;
	
`

const TemporarySave = styled.div`
	width: 146px;
	height: 41px;
	display: flex;
	border: 1px solid #00000033;
	box-shadow: 0px 0px 10px 0px #0000000D;
	align-items: center;
	justify-content: center;
	gap: 53px;
	border-radius: 4px;
	
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	font-weight: 400;
`

const TemporaryWrapper = styled.div`
	
`

const TemporaryCount = styled.div`
	font-weight: 500;
	color: #F15151;
`

const PastSaleText = styled.div`
	font-weight: 400;
	font-size: 15px;
	line-height: 17.9px;
	color: #00000099;
	text-decoration: underline;
	text-align: right;
	margin-top: 6px;
`

const StyleInput = styled(Input)`
	width: 238px;
	height: 36px;

	.ant-input::placeholder {
		color: #535353; /* 원하는 색상으로 변경 */
	}
`

const SearchIconSVG = styled.svg`
	width: 20px;
`