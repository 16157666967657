import React, { useEffect } from 'react'
import { useToken } from '../../../../app/hooks'
import { useNavigate } from 'react-router-dom'

export const LogoutPage = () => {
	const { mutate } = useToken('', false)
	const navigate = useNavigate()



	useEffect(() => {
		mutate(null)
		navigate('/tv')
	}, [])

	return null
}