import React, { useState, useEffect } from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {InquireDetail} from "./blocks/InquireDetail";
import {AdminMessage} from "./blocks/AdminMessage";


export const InquireListTable = ({agentInquireList, mutateAgentInquireList, categoryItem, agentMessageList, mutateAgentMessageList, page, setPage, pageSize, setPageSize, inquireTotal, messageTotal, isLoadingInquire, isLoadingMessage
                                 }) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [adminItemId, setAdminItemId] = useState(null);
  const { data: token } = useToken();



  useEffect(() => {
    const allSelected = agentInquireList?.length > 0 && agentInquireList?.every(it => selectedRows?.includes(it.postId));
    setIsAllChecked(allSelected);
  }, [selectedRows, agentInquireList]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      dataIndex: '',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.postId || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '제목',
      render: (value, data) => {
        return (
          <div className='email' onClick={() => setItemId(data?.postId)} style={{textDecoration: 'underline', cursor: 'pointer', justifyContent: 'center', display: 'flex'}}>{data?.title || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '첨부파일',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{`${data?.fileList?.length || 0}`}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '답변',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.answerStatus === 1 ? '답변완료' : '-' }</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '등록일',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.createdAt || '-'}</div>
        )
      }
    },
  ];

  const adminMessageColumns = [
    {
      dataIndex: '',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.postId || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '제목',
      render: (value, data) => {
        return (
          <div className='email' onClick={() => setAdminItemId(data?.postId)}  style={{textDecoration: 'underline', cursor: 'pointer', justifyContent: 'center', display: 'flex'}}>{data?.title || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '첨부파일',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{textDecoration: 'underline', cursor: 'pointer', justifyContent: 'center', display: 'flex'}}>{`${data?.fileList?.length || 0}`}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '등록일',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.createdAt || '-'}</div>
        )
      }
    },
  ];

  const OutColumn = () => {
    if(categoryItem === '문의내역') {
      return columns
    } else if (categoryItem === '관리자메시지') {
      return adminMessageColumns
    }
  }

  return (
    <DBFilterWrapper>

      <StyleTable columns={OutColumn()}
             dataSource={categoryItem === '문의내역' ? agentInquireList : agentMessageList}
             bordered={true}
                  loading={categoryItem === '문의내역' ? isLoadingInquire : isLoadingMessage}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: categoryItem === '문의내역' ? inquireTotal : messageTotal,
                  }}
                  onChange={handleTableChange}
      />

     {itemId !== null && <InquireDetail itemId={itemId} setItemId={setItemId}  />}
     {adminItemId !== null && <AdminMessage adminItemId={adminItemId} setAdminItemId={setAdminItemId}  />}

    </DBFilterWrapper>
  )
}

export default React.memo(InquireListTable);

const ColumnHeader = styled.div`
  
`

const StyleTable = styled(Table)`
 .ant-table-thead > tr > th {
    background-color: #F4F2FF;
    color: #00000099;
     font-size: 14px;
     font-weight: 500;
     line-height: 16.71px;
  }
`
