import React, {useEffect} from 'react';
import styled from "styled-components";
import {message, Select} from "antd";
import API from "../../../../utils/api";

export const SelectAddressItem = ({selectedFirstName, setSelectedFirstName, selectedSecondName, setSelectedSecondName, selectedThirdName, setSelectedThirdName}) => {
  const [firstItem, setFirstItem] = React.useState<any>([]); // 모든 특별/광역시, 도
  const [secondItem, setSecondItem] = React.useState<any>([]); // 모든 구
  const [thirdItem, setThirdItem] = React.useState<any>([]); // 모든 동
  const [selectedFirstCode, setSelectedFirstCode] = React.useState<any>('');
  const [selectedSecondCode, setSelectedSecondCode] = React.useState<any>('');


  useEffect(() => {
    getFirstAddress()
  }, []);


  const getFirstAddress = () => {
    API.get('', '', '', true, 'https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=*00000000').then((res) => {
      setFirstItem(res?.regcodes);
     // console.log(res);
    }).catch((err) => {
      message.error('주소 정보를 가져오는 것에 실패하였습니다.')
      console.log('GET FIRST ADDRESS ERROR => ', err);
    })
  }

  useEffect(() => {
    // console.log('firstItem?.find(item => item.name === selectedFirstName)', firstItem?.find(item => item.name === selectedFirstName)?.code.slice(0, 2))
    if(firstItem?.length > 0){
      setSelectedFirstCode(firstItem?.find(item => item.name === selectedFirstName)?.code.slice(0, 2));
    }
  }, [selectedFirstName]);

  useEffect(() => {
    // console.log('selectedFirstCode ', selectedFirstCode);
    if(selectedFirstCode?.length === 2){
      getSecondAddress(selectedFirstCode)
    }
  }, [selectedFirstCode]);


  const getSecondAddress = (code) => {
    API.get('', '', '', true, `https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=${code}*00000`).then((res) => {
      if(res?.regcodes?.length > 0){
        const newData = res?.regcodes;

        const filteredData = newData.filter((item) => {
          return item?.name?.split(' ')?.length !== 3;
        });

        filteredData?.map((item) => {
          // console.log(' item.name.split',  item.name.split(' '))
          if(item?.name?.split(' ')?.length === 2){
            item.name = item.name.split(' ')[1]
          }
        })

        filteredData.splice(0, 1);

        setSecondItem(filteredData);
      }
    }).catch((err) => {
      message.error('주소 정보를 가져오는 것에 실패하였습니다.')
      console.log('GET SECOND ADDRESS ERROR => ', err);
    })
  }

  useEffect(() => {
    if(secondItem?.length > 0){
      setSelectedSecondCode(secondItem?.find(item => item.name === selectedSecondName)?.code.slice(0, 4));
    }
  }, [selectedSecondName]);


  useEffect(() => {
    // console.log('selectedSecondCode ', selectedSecondCode);
    if(selectedSecondCode?.length === 4){
      getThirdAddress(selectedSecondCode)
    }
  }, [selectedSecondCode]);


  const getThirdAddress = (code) => {
    API.get('', '', '', true, `https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=${code}*&is_ignore_zero=true`).then((res) => {
      if(res?.regcodes?.length > 0){
        const newData = res?.regcodes;

        newData?.map(item => {
          // console.log(' item.name.split',  item.name.split(' '))
          // item.name = item.name.split(' ')[2]

          if(item?.name?.split(' ')?.length === 3){
            item.name = item.name.split(' ')[2]
          } else if(item?.name?.split(' ')?.length === 4) {
            item.name = item.name.split(' ')[2] + ' ' + item.name.split(' ')[3]
          }
        })


        setThirdItem(newData);
      }
    }).catch((err) => {
      message.error('주소 정보를 가져오는 것에 실패하였습니다.')
      console.log('GET THIRD ADDRESS ERROR => ', err);
    })
  }

  // useEffect(() => {
  //   console.log('thirdItem ', thirdItem);
  //
  // }, [thirdItem]);


  return (
    <Block>
      <StyledSelect placeholder={'특별광역시/도'} value={selectedFirstName} onChange={(e: any) => setSelectedFirstName(e)}>
        {firstItem?.map((item, index) => (
          <Select.Option value={item?.name} key={`firstItem_Address_${index}`}>{item?.name}</Select.Option>
        ))}
      </StyledSelect>
      <StyledSelect placeholder={'시/군/구'} value={selectedSecondName} onChange={(e: any) => setSelectedSecondName(e)}>
        {secondItem?.map((item, index) => (
          <Select.Option value={item?.name} key={`secondItem_Address_${index}`}>{item?.name}</Select.Option>
        ))}
      </StyledSelect>
      <StyledSelect placeholder={'읍/면/동'} value={selectedThirdName} onChange={(e: any) => setSelectedThirdName(e)}>
        {thirdItem?.map((item, index) => (
          <Select.Option value={item?.name} key={`thirdItem_Address_${index}`}>{item?.name}</Select.Option>
        ))}
      </StyledSelect>

      <ResetButton onClick={() => {
        setSelectedFirstName(null);
        setSelectedSecondName(null);
        setSelectedThirdName(null);
      }}>
        초기화
      </ResetButton>
    </Block>
  )
}


const Block = styled.div`
    display: flex;
    gap: 5px;
`

const StyledSelect = styled(Select)`
    width: 150px;
    
    &::placeholder {
        color: #535353;
    }
`

const ResetButton = styled.div`
    background-color: #3b39cc;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;

    font-size: 15px;
    font-weight: 600;

`