import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {AdminInquireDetail} from "./blocks/AdminInquireDetail";
import {AdminMessageDetail} from "./blocks/AdminMessageDetail";
import {useAdminUserInfo} from "../../hooks/Admin/UserManage/GetAdminUserInfo";
import {formatPhoneNumber} from "../../hooks/util/formatPhoneNumber";


export const AdminInquireTable = ({
                                    adminInquiryList,
                                    mutateAdminInquiryList,
                                    categoryItem,
                                    adminMessageList,
                                    isSendMessage,
                                    setIsSendMessage,
                                    mutateAdminMessageList,
                                    inquiryTotal,
                                    messageTotal,
                                    page,
                                    setPage,
                                    pageSize,
                                    setPageSize,
                                    isLoadingInquiry,
                                    isLoadingMessage
                                  }) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [messageItemId, setMessageItemId] = useState(null);
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = adminInquiryList?.length > 0 && adminInquiryList?.every(it => selectedRows?.includes(it.id));
    setIsAllChecked(allSelected);
  }, [selectedRows, adminInquiryList]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const customerColumns = [
    {
      dataIndex: 'postId',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'targetType',
      title: '구분',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'writer',
      title: '작성자',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'title',
      title: '제목',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setItemId(data?.postId)} style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex'
          }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '첨부파일',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.fileList?.length || 0}</div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '등록일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'answerStatus',
      title: '답변',
      render: (value, data) => {
        return (
          <div className='date'
               style={{justifyContent: 'center', display: 'flex'}}>{value === 0 ? '작성필요' : '작성완료'}</div>
        )
      }
    }
  ];

  const realEstateColumns = [
    {
      dataIndex: 'postId',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'targetType',
      title: '구분',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'addressee',
      title: '수신자',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'title',
      title: '제목',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setMessageItemId(data?.postId)} style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex'
          }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '첨부파일',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.fileList?.length || 0}</div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '등록일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '수신여부',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{'-'}</div>
        )
      }
    },
  ];


  const OutColumn = () => {
    if (categoryItem === '수신내역') {
      return customerColumns
    } else if (categoryItem === '발신내역') {
      return realEstateColumns
    }
  }

  const OutData = () => {
    if (categoryItem === '수신내역') {
      return adminInquiryList
    } else if (categoryItem === '발신내역') {
      return adminMessageList
    }
  }

  return (
    <DBFilterWrapper>

      <StyleTable columns={OutColumn()}
                  dataSource={OutData()}
                  bordered={true}
                  loading={categoryItem === '수신내역' ? isLoadingInquiry : isLoadingMessage}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: categoryItem === '수신내역' ? inquiryTotal : messageTotal,
                  }}
                  onChange={handleTableChange}
      />

      {itemId !== null &&
        <AdminInquireDetail setItemId={setItemId} itemId={itemId} mutateAdminInquiryList={mutateAdminInquiryList}/>}

      {messageItemId !== null && <AdminMessageDetail setMessageItemId={setMessageItemId} messageItemId={messageItemId}
                                                     setIsSendMessage={setIsSendMessage} isSendMessage={isSendMessage}
                                                     mutateAdminMessageList={mutateAdminMessageList}/>}
      {isSendMessage && <AdminMessageDetail setMessageItemId={setMessageItemId} messageItemId={messageItemId}
                                            setIsSendMessage={setIsSendMessage} isSendMessage={isSendMessage}
                                            mutateAdminMessageList={mutateAdminMessageList}/>}

    </DBFilterWrapper>
  )
}

export default React.memo(AdminInquireTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
