import React, {useEffect, useState} from 'react'
import {
	DBFilterDateArea,
	DBFilterWrapper,
} from '../../../CRM/organism/DB/styles'
import { Select, Input, DatePicker } from 'antd'
import 'dayjs/locale/ko'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {ReactComponent as SearchIcon} from "./assets/SearchIcon.svg";
import dayjs from "dayjs";


export const PaymentManageFilter = ({categoryItem, date, setDate, type, setType, searchWord, setSearchWord, order, setOrder, mutateAdminPaymentADList, agentType, setAgentType, mutateAdminPaymentAgentList, setStatus, mutateAdminPaymentReturnList, setIsSelectItem,isSelectItem, selectedRows}) => {
	const today = new Date();
	const formattedDate = today.toISOString().split('T')[0];

	const [inputSearchWord, setInputSearchWord] = useState('');
	const [inputType, setInputType] = useState(0);
	const [inputAgentType, setInputAgentType] = useState(0);
	const [inputStatus, setInputStatus] = useState(0);
	const [inputDate, setInputDate] = useState(formattedDate);


	const isSearch = () => {
		if(categoryItem === '광고주') {
			setSearchWord(inputSearchWord);
			setType(inputType);
			// setDate(inputDate);
			mutateAdminPaymentADList();
		} else if (categoryItem === '매체주') {
			setSearchWord(inputSearchWord);
			setAgentType(inputAgentType);
			mutateAdminPaymentAgentList();
		} else {
			setSearchWord(inputSearchWord);
			setStatus(inputStatus);
			mutateAdminPaymentReturnList()
		}

	}


	const CommercialStateItem = [
		{
			name: '구분 전체',
			value : 0
		},
		{
			name: '충전',
			value : 1
		},
		{
			name: '차감',
			value : 2
		},
	]

	const OptionlStateItem = [
		{
			name: '권한 전체',
			value : 0
		},
		{
			name: '외부+매물',
			value : 1
		},
		{
			name: '매물광고',
			value : 2
		},
		{
			name: '외부광고',
			value : 3
		},
	]

	const PaymentStateItem = [
		{
			name: '전체',
			value : 0
		},
		{
			name: '월 결제',
			value : 0
		},
		{
			name: '자동이체',
			value : 0
		},
	]

	const RefundStateItem = [
		{
			name: '상태 전체',
			value : 0
		},
		{
			name: '환급필요',
			value : 1
		},
		{
			name: '환급완료',
			value : 2
		},
	]


	return (
		<DBFilterWrapper style={{ backgroundColor: '#FAFAFC' }}>

			{isSelectItem ?
				<DBFilterDateArea
					style={{justifyContent: 'space-between', backgroundColor: '#E3E3E3', height: 60, padding: '0 16px'}}>
					<SelectItemCount>
						{`${selectedRows.length}건 선택`}
						{categoryItem === '환급신청내역' &&
							<ButtonsWrapper>
								<ItemButton >환급완료</ItemButton>
								{/*<ItemButton style={{color: '#FF6C4B'}} >탈퇴</ItemButton>*/}
								{/*<ItemButton style={{color: '#FF6C4B'}} >삭제</ItemButton>*/}
							</ButtonsWrapper>
						}
					</SelectItemCount>

					<SearchWrapper>
						<ItemButton  onClick={() => {
							setIsSelectItem(false);
						}}>
							취소
						</ItemButton>
					</SearchWrapper>
				</DBFilterDateArea>
				:
				<DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
					<RoundCheckWrapper>
						<HomeTownRoundCheckbox text={'최신순'} checked={order === 'latest'} onClick={() => setOrder('latest')}/>
						<HomeTownRoundCheckbox text={'과거순'} checked={order === 'oldest'} onClick={() => setOrder('oldest')}/>
					</RoundCheckWrapper>

					<SearchWrapper>

						{categoryItem === '환급신청내역' &&
							<AllCheckButton onClick={() => {setIsSelectItem(true)}}>
								일괄처리
							</AllCheckButton>
						}

						{/*<DatePicker placeholder={'날짜 선택'} format={'YYYY-MM-DD'} value={dayjs(inputDate, 'YYYY-MM-DD') } onChange={(value) => setInputDate(value?.format("YYYY-MM-DD") || '')}/>*/}

						{categoryItem === '광고주' &&
							<StyledSelect value={inputType} onChange={e => setInputType(e as number)}>
								{CommercialStateItem?.map((item, index) => (
									<Select.Option value={item.value}>{item.name}</Select.Option>
								))}
							</StyledSelect>
						}

						{categoryItem === '매체주' &&
							<StyledSelect value={inputAgentType} onChange={e => setInputAgentType(e as number)} >
								{OptionlStateItem?.map((item, index) => (
									<Select.Option value={item.value}>{item.name}</Select.Option>
								))}
							</StyledSelect>
						}

						{/*{categoryItem === '매체주' &&*/}
						{/*	<StyledSelect value={'결제 전체'}>*/}
						{/*		{PaymentStateItem?.map((item, index) => (*/}
						{/*			<Select.Option value={item.name}>{item.name}</Select.Option>*/}
						{/*		))}*/}
						{/*	</StyledSelect>*/}
						{/*}*/}

						{categoryItem === '환급신청내역' &&
							<StyledSelect value={inputStatus} onChange={e => setInputStatus(e as number)}>
								{RefundStateItem?.map((item, index) => (
									<Select.Option value={item.value}>{item.name}</Select.Option>
								))}
							</StyledSelect>
						}


						<StyleInput placeholder={'검색'} value={inputSearchWord} onChange={e => setInputSearchWord(e.target.value as string)} suffix={<SearchIconSVG as={SearchIcon} />}  />

						<SearchButton onClick={() => isSearch()}>
							조회
						</SearchButton>
					</SearchWrapper>
				</DBFilterDateArea>
			}

		</DBFilterWrapper>
	)
}

const ItemCardsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`

const ItemCard = styled.div`
	box-shadow: 0px 0px 10px 0px #0000000D;
	border-radius: 4px;
	width: 146px;
	height: 88px;
	padding: 18px 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`

const CardTitle = styled.div`
	font-weight: 400;
	font-size: 15px;
	line-height: 17.9px;
	font-family: Pretendard, sans-serif;
`

const CardCount = styled.div`
 text-align: right;
	font-weight: 600;
	font-size: 20px;
	line-height: 23.87px;
	font-family: Pretendard, sans-serif;
	color: #F15151;
`

const RoundCheckWrapper = styled.div`
	display: flex;
	gap: 14px;
`

const SearchWrapper = styled.div`
	display: flex;
	gap: 6px;
`

const AllCheckButton = styled.div`
	border: 1px solid #FF6C4B;
	width: 92px;
	height: 36px;
	font-weight: 400;
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FF6C4B;
	cursor: pointer;
`

const SearchButton = styled.div`
	background-color: #FF6C4B;
	width: 66px;
	height: 36px;
	border-radius: 4px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 17.9px;
	cursor: pointer;
`

const StyledSelect = styled(Select)`
	width: 170px;
	height: 36px;
	border-radius: 4px;
	
`

const TemporarySave = styled.div`
	width: 146px;
	height: 41px;
	display: flex;
	border: 1px solid #00000033;
	box-shadow: 0px 0px 10px 0px #0000000D;
	align-items: center;
	justify-content: center;
	gap: 53px;
	border-radius: 4px;
	
	font-family: Pretendard, sans-serif;
	font-size: 15px;
	line-height: 17.9px;
	font-weight: 400;
`

const TemporaryWrapper = styled.div`
	
`

const TemporaryCount = styled.div`
	font-weight: 500;
	color: #F15151;
`

const PastSaleText = styled.div`
	font-weight: 400;
	font-size: 15px;
	line-height: 17.9px;
	color: #00000099;
	text-decoration: underline;
	text-align: right;
	margin-top: 6px;
`

const StyleInput = styled(Input)`
	width: 238px;
	height: 36px;

	.ant-input::placeholder {
		color: #535353; /* 원하는 색상으로 변경 */
	}
`

const SearchIconSVG = styled.svg`
	width: 20px;
`

const SelectItemCount = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 6px;
`

const ItemButton = styled.div`
    padding: 9px 20px;
    min-width: 36px;
    background: #FFFFFF;
    border: 1px solid #0000004D;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`