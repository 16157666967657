import React, {useEffect, useState} from 'react'
import {
  DBFilterDateArea,
  DBFilterWrapper,
} from '../../../CRM/organism/DB/styles'
import {message, Select} from 'antd'
import 'dayjs/locale/ko'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {useAgentADSummaryList} from "../../hooks/Agent/Commercial/GetAgentADSummaryList";
import API from "../../../utils/api";
import {useToken} from "../../../app/hooks";


export const CommercialFilter = ({
                                   setIsSelectItem,
                                   isSelectItem,
                                   selectedRows,
                                   setSelectedRows,
                                   isAllChecked,
                                   setIsAllChecked,
                                   setOrder,
                                   order,
                                   setStatus,
                                   mutateAgentADList
                                 }) => {
  // 유틸리티
  const {data: token} = useToken();

  // 상태값
  const [inputStatus, setInputStatus] = useState(0)
  const [rejectReason, setRejectReason] = useState('')

  // 데이터 조회
  const {agentADSummaryList, mutateAgentADSummaryList} = useAgentADSummaryList();


  useEffect(() => {
    console.log('agentADSummaryList', agentADSummaryList)
  }, [agentADSummaryList]);


  const CommercialStateItem = [
    {
      name: '전체 상태',
      value: 0
    },
    {
      name: '광고대기',
      value: 1
    },
    {
      name: '승인완료',
      value: 2
    },
    {
      name: '광고거절',
      value: 3
    },
    {
      name: '재요청',
      value: 4
    },
    {
      name: '광고중',
      value: 5
    },
    {
      name: '광고종료',
      value: 6
    }
  ]

  const IsSearch = () => {
    setStatus(inputStatus)
  }

  const clickDelete = () => {
    const shouldEdit = window.confirm(`선택한 내역을 삭제하시겠습니까?`);
    if (shouldEdit) {
      if (selectedRows?.length == 0) {
        message.error('선택한 내역이 없습니다.')
      } else {
        API.delete('/agent/ad/list?ids=' + selectedRows?.join(','), token + '', {})
          .then((res: any) => {
            if (res.result.success) {
              message.success('삭제되었습니다.');
              setSelectedRows([]);
              mutateAgentADList();
            } else {
              message.error(res.result.message || '실패하였습니다');
            }
          })
          .catch((err: any) => {
            message.error('삭제에 실패하였습니다.')
          })
      }
    }
  }

  const permitCommercial = (status) => {
    const shouldEdit = window.confirm(`광고를 ${status === 1 ? '승인' : '거절'}하시겠습니까?`);
    if (shouldEdit) {
      if (selectedRows?.length == 0) {
        message.error('선택한 유저가 없습니다.')
      } else {
        API.post('/agent/ad/list/permit', token + '', {
          ids: selectedRows,
          status,
          rejectReason: '이곳 광고개제 정책에 맞지 않습니다.'
        })
          .then((res: any) => {
            if (res.result.success) {
              message.success(`${status === 1 ? '승인' : '거절'}하였습니다.`);
              setSelectedRows([]);
              mutateAgentADList();
            } else {
              message.error(res.result.message || '실패하였습니다');
            }
          })
          .catch((err: any) => {
            message.error('실패하였습니다.')
          })
      }
    }
  }


  const postApplyCalculation = () => {
    const shouldEdit = window.confirm(`정산 신청하시겠습니까?`);
    if (shouldEdit) {
      if (selectedRows?.length == 0) {
        message.error('선택한 내역이 없습니다.')
      } else {
        API.post('/agent/refund/list', token + '', {
          adIds:selectedRows
        })
          .then((res: any) => {
            if (res.result.success) {
              message.success('정산 신청되었습니다.');
              setSelectedRows([]);
              mutateAgentADList();
            } else {
              message.error(res.result.message || '실패하였습니다');
            }
          })
          .catch((err: any) => {
            message.error('실패하였습니다.')
          })
      }
    }
  }


  return (
    <DBFilterWrapper style={{backgroundColor: '#FAFAFC'}}>
      <DBFilterDateArea>
        {agentADSummaryList?.map((item, index) => (
          <ItemCard>
            <CardTitle>
              {item?.title}
            </CardTitle>
            <CardCount>
              {item?.value}
            </CardCount>
          </ItemCard>
        ))}
      </DBFilterDateArea>

      {isSelectItem ?
        <DBFilterDateArea
          style={{justifyContent: 'space-between', backgroundColor: '#E3E3E3', height: 60, padding: '0 16px'}}>
          <SelectItemCount>
            {`${selectedRows.length}건 선택`}
            <ButtonsWrapper>
              {/*<ItemButton*/}
              {/*  onClick={() => {*/}
              {/*    permitCommercial(1);*/}
              {/*  }}*/}
              {/*>광고승인</ItemButton>*/}

              {/*<Input placeholder={'거절사유 입력'} value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} />*/}

              <ItemButton
                onClick={() => {
                  permitCommercial(2);
                }}
              >광고거절</ItemButton>

              <ItemButton
                style={{color: '#0c88ea'}}
                onClick={() => {
                  postApplyCalculation();
                }}
              >정산신청</ItemButton>

              <ItemButton style={{color: '#FF6C4B'}} onClick={() => clickDelete()}>삭제</ItemButton>
            </ButtonsWrapper>
          </SelectItemCount>

          <SearchWrapper>
            <ItemButton onClick={() => {
              setIsSelectItem(false);
            }}>
              취소
            </ItemButton>
          </SearchWrapper>
        </DBFilterDateArea>

        :

        <DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
          <RoundCheckWrapper>
            <HomeTownRoundCheckbox text={'최신순'} checked={order === 'latest'} onClick={() => setOrder('latest')}/>
            <HomeTownRoundCheckbox text={'과거순'} checked={order === 'oldest'} onClick={() => setOrder('oldest')}/>
          </RoundCheckWrapper>

          <SearchWrapper>
            <AllCheckButton onClick={() => {
              setIsSelectItem(true);
            }}>
              일괄처리
            </AllCheckButton>

            <StyledSelect value={inputStatus} onChange={(e) => setInputStatus(e as number)}>
              {CommercialStateItem?.map((item, index) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
            </StyledSelect>

            <SearchButton onClick={() => IsSearch()}>
              조회
            </SearchButton>
          </SearchWrapper>
        </DBFilterDateArea>
      }


    </DBFilterWrapper>
  )
}

const ItemCard = styled.div`
    box-shadow: 0px 0px 10px 0px #0000000D;
    border-radius: 4px;
    width: 146px;
    height: 88px;
    padding: 18px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const CardTitle = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const CardCount = styled.div`
    text-align: right;
    font-weight: 600;
    font-size: 20px;
    line-height: 23.87px;
    font-family: Pretendard, sans-serif;
    color: #F15151;
`

const RoundCheckWrapper = styled.div`
    display: flex;
    gap: 14px;
`

const SearchWrapper = styled.div`
    display: flex;
    gap: 6px;
`

const AllCheckButton = styled.div`
    border: 1px solid #FF6C4B;
    width: 92px;
    height: 36px;
    font-weight: 400;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    line-height: 17.9px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF6C4B;
    cursor: pointer;
`

const SearchButton = styled.div`
    background-color: #FF6C4B;
    width: 66px;
    height: 36px;
    border-radius: 4px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    cursor: pointer;
`

const StyledSelect = styled(Select)`
    width: 170px;
    height: 36px;
    border-radius: 4px;

`

const SelectItemCount = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`

const ItemButton = styled.div`
    padding: 9px 20px;
    min-width: 36px;
    background: #FFFFFF;
    border: 1px solid #0000004D;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
`

const Input = styled.input`
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;