import axios from 'axios'
import { API_URL } from '../app/constants/APIKEYS'
export default class API {
	/**
	 * API발송 메소드, /v1 + url
	 */
	static async send(
		method: string,
		url: string,
		token: string | null,
		data: any,
		logout: boolean = true,
		apiurl?: string
	) {
		try {
			let headers = this.getHeaders(token)
			let options = this.getOptions(method, headers, data)

			let res = await fetch((apiurl ? apiurl : API_URL) + url, options)
				.then(res => {
					if (res.status === 401) {
						if (logout) {
							alert('로그인이 만료되었습니다')
							this.forceLogout()
						}
						throw new Error('로그인을 확인해 주세요')
					}

					if (res.status === 403) {
						throw new Error('접근권한이 없습니다')
					}

					return res.json()
				})
				.then(res => {
					if (!res.code) {
					}

					if (res.code >= 500) {
						throw new Error(res.result.error || '')
					}

					return res
				})

			return res
		} catch (e: any) {
			// console.log(e)
			// if (logout) this.forceLogout()
			console.log(e)
			throw new Error(e?.message)
		}
	}

	static async postFile(
		url: string,
		token: string | null,
		data: any,
		logout: boolean = true,
		apiurl?: string
	) {
		try {
			let headers = this.getHeaderToken(token)
			let options = this.getOptions('post', headers, data)

			let res = await fetch((apiurl ? apiurl : API_URL) + url, options)
				.then(res => {
					return res.json()
				})
				.then(res => {
					if (res.code === 503) {
						if (logout) this.forceLogout()
					}
					return res
				})
			return res
		} catch (e) {
			console.log(e)
			this.forceLogout()
		}
	}

	static async postFormdata(
		method: string,
		url: string,
		token: string | null,
		data: any,
		logout: boolean = true,
		apiurl?: string
	) {
		try {
			let res = await fetch((apiurl ? apiurl : API_URL) + url, {
				method: method,
				headers: { Authorization: 'Bearer ' + token },
				body: data,
			})
				.then(res => {
					if (res.status === 401) {
						if (logout) {
							alert('로그인이 만료되었습니다')
							this.forceLogout()
						}
						throw new Error('로그인을 확인해 주세요')
					}

					if (res.status === 403) {
						throw new Error('접근권한이 없습니다')
					}

					return res.json()
				})
				.then(res => {
					if (!res.code) {
					}

					if (res.code >= 500) {
						throw new Error(res.result.error || '')
					}

					return res
				})

			return res
		} catch (e: any) {
			// console.log(e)
			// if (logout) this.forceLogout()
			console.log(e)
			throw new Error(e?.message)
		}
	}

	static async postImage(
		method: 'POST' | 'PUT',
		url: string,
		token: string | null,
		data: any,
		apiurl?: string
	) {
		return this.postFormdata(method, url, token, data, true, apiurl)
	}

	static async get(
		url: string,
		token: string | null,
		data: any,
		logout: boolean = true,
		apiurl?: string
	) {
		return this.send('GET', url, token, data, logout, apiurl)
	}
	static async post(
		url: string,
		token: string | null,
		data: any,
		apiurl?: string
	) {
		return this.send('POST', url, token, data, true, apiurl)
	}


	static async postForm(
		url: string,
		token: string | null,
		data: any,
		apiurl?: string
	) {
		return this.send('POST', url, token, data, true, apiurl)
	}

	static async delete(
		url: string,
		token: string | null,
		data: any,
		apiurl?: string
	) {
		return this.send('DELETE', url, token, data, true, apiurl)
	}
	static async put(
		url: string,
		token: string | null,
		data: any,
		apiurl?: string
	) {
		return this.send('PUT', url, token, data, true, apiurl)
	}

	static async getPublic(url: string) {
		return this.send('GET', url, null, {})
	}
	static async postPublic(url: string, data: any) {
		return this.send('POST', url, null, data)
	}
	static async deletePublic(url: string, data: any) {
		return this.send('DELETE', url, null, data)
	}
	static async putPublic(url: string, data: any) {
		return this.send('PUT', url, null, data)
	}

	static async refreshToken(token: string) {
		try {
			let options = this.getOptions('POST', this.getHeaders(token), {
				token,
			})

			let res = await fetch(API_URL + '/auth/refresh', options)
				.then(res => {
					return res.json()
				})
				.then(res => {
					if (res.code !== 0) {
						return false
					}
					return res
				})
			return res
		} catch (e) {
			console.log(e)
			this.forceLogout()
		}
	}

	static getHeaders(token: string | null) {
		const headers: any = token
			? {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',

			}
			: {
				'Content-Type': 'application/json',
			}
		return headers
	}


	static getHeaderToken(token: string | null) {
		const headers: any = token
			? {
				Authorization: 'Bearer ' + token

			}
			: {
				Authorization: 'Bearer ',
			}
		return headers
	}

	static getOptions(method: string, headers: any, data?: any) {
		const options =
			method.toLocaleUpperCase() === 'GET'
				? {
					method: method,
					headers: headers,
				}
				: {
					method: method,
					headers: headers,
					body: JSON.stringify(data),
				}
		return options
	}

	static forceLogout() {
		window.location.href = '/admin/logout'
	}
}