import React, {useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import styled from "styled-components";
import {MEDIA_URL} from "../../../../app/constants/APIKEYS";
import {Document, Page, pdfjs} from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};


export const PreviewMonitor = ({setIsPreviewModal, itemId, advertiserADList}) => {
  const [selectedItem, setSelectedItem] = React.useState<any>([]);

  useEffect(() => {
    console.log('agentSaleList', advertiserADList);

    if(itemId){
      const newItems = advertiserADList?.filter(item => item.adId === itemId)[0].fileList;

      setSelectedItem(newItems);
    } else {
      setSelectedItem(advertiserADList)
    }
  }, [advertiserADList]);


  useEffect(() => {
    console.log('selectedItem', selectedItem);
  }, [selectedItem]);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <>
      <Blacked onClick={() => setIsPreviewModal(false)}/>
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>미리보기</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {
            setIsPreviewModal(false)
          }}/>
        </ModalHeader>

        <Contents>
          <MonitorTitle>전면</MonitorTitle>
          <MonitorWrapper style={{display: 'flex', justifyContent: 'center'}}>
            {selectedItem && selectedItem[0]?.url.endsWith('.pdf') ?
              <Document file={selectedItem?.fileList && MEDIA_URL + selectedItem[0]?.url} options={options} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} height={230} />
              </Document>
              :
              selectedItem && selectedItem[0]?.url.endsWith('.mp4') ?
                <video
                  src={selectedItem && MEDIA_URL + selectedItem[0]?.url}
                  width={'100%'}
                  height={'100%'}
                  controls={false}
                  autoPlay={false}
                />
                :
                <FullImg src={selectedItem && MEDIA_URL + selectedItem[0]?.url}/>
            }
          </MonitorWrapper>
        </Contents>

        <Contents>
          <MonitorTitle>2면</MonitorTitle>
          <MonitorWrapper style={{padding: 4}}>
            <div style={{display: "flex", justifyContent: "center", gap: 6}}>
              {selectedItem && selectedItem[0]?.url.endsWith('.pdf') ?
                <Document file={selectedItem && MEDIA_URL + selectedItem[0]?.url} options={options} onLoadSuccess={onDocumentLoadSuccess}>
                  <div style={{width: 198, display: 'flex', justifyContent: 'center', backgroundColor: '#D9D9D9'}}>
                    <Page pageNumber={pageNumber} height={142} />
                  </div>
                </Document>
                :
                selectedItem && selectedItem[0]?.url.endsWith('.mp4') ?
                  <div style={{width: 198, display: 'flex', justifyContent: 'center', height: 142.01, backgroundColor: '#D9D9D9'}}>
                    <video
                      src={selectedItem && MEDIA_URL + selectedItem[0]?.url}
                      width={'100%'}
                      height={'100%'}
                      controls={false}
                      autoPlay={false}
                    />
                  </div>
                  :
                  <TwoBigImg src={selectedItem && MEDIA_URL + selectedItem[0]?.url}/>
              }
              <TwoBig/>
            </div>

            <div style={{display: "flex", justifyContent: "center", gap: 5, marginTop: 5}}>
              <TwoSmall />
              <TwoSmall />
              <TwoSmall/>
              <TwoSmall />
            </div>
          </MonitorWrapper>
        </Contents>

        <Contents>
          <MonitorTitle>6면</MonitorTitle>
          <MonitorWrapper style={{padding: 4}}>
            <div style={{display: "flex", justifyContent: "center", gap: 3}}>
              {selectedItem && selectedItem[0]?.url.endsWith('.pdf') ?
                <Document file={selectedItem && MEDIA_URL + selectedItem[0]?.url} options={options}
                          onLoadSuccess={onDocumentLoadSuccess}>
                  <div style={{width: 133, display: 'flex', justifyContent: 'center', backgroundColor: '#D9D9D9'}}>
                    <Page pageNumber={pageNumber} height={111.55}/>
                  </div>
                </Document>
                :
                selectedItem && selectedItem[0]?.url.endsWith('.mp4') ?
                  <div style={{width: 133, display: 'flex', justifyContent: 'center', height: 111.55, backgroundColor: '#D9D9D9'}}>
                    <video
                      src={selectedItem && MEDIA_URL + selectedItem[0]?.url}
                      width={'100%'}
                      height={'100%'}
                      controls={false}
                      autoPlay={false}
                    />
                  </div>
                  :
                  <SixImg src={selectedItem && MEDIA_URL + selectedItem[0]?.url}/>
              }
              <SixBox/>
              <SixBox/>
            </div>

            <div style={{display: "flex", justifyContent: "center", gap: 3, marginTop: 3}}>
              <SixBox />
              <SixBox />
              <SixBox />
            </div>
          </MonitorWrapper>
        </Contents>

      </ModalWrapper>
    </>
  )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 820px;
    overflow-y: scroll;
    width: 580px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Contents = styled.div`
    margin-top: 45px;
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
    color: #000000;
    white-space: pre-wrap;
    text-align: center;
`

const MonitorTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: pretendard, sans-serif;
    margin-bottom: 10px;
`

const MonitorWrapper = styled.div`
    border: 12.87px solid #000000;
    width: 437.61px;
    height: 257.42px;
    
`

const FullImg = styled.img`
    background-color: #D9D9D9;
    width: 100%;
    height: 100%;
`

// const FullImgWrapper = styled.div`
//     width: 338.29px;
//     height: 231.68px;
// `

const TwoBigImg = styled.img`
    background-color: #D9D9D9;
    width: 198px;
    height: 142.01px;
`

const TwoBig = styled.div`
    background-color: #D9D9D9;
    width: 198px;
    height: 142.01px;
`

const TwoSmall = styled.img`
    background-color: #D9D9D9;
    width: 96.96px;
    height: 77.01px;
`

const SixImg = styled.img`
    background-color: #D9D9D9;
    width: 133px;
    height: 111.55px;
`

const SixBox = styled.div`
    background-color: #D9D9D9;
    width: 133px;
    height: 111.55px;
`
