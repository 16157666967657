import React, {useEffect, useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {CustomerCommercialFilter} from "./CustomerCommercialFilter";
import {CustomerCommercialTable} from "./CustomerCommercialTable";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {ApplicationOneStep} from "./blocks/ApplicationOneStep";
import {ApplicationTwoStep} from "./blocks/ApplicationTwoStep";
import {ApplicationThreeStep} from "./blocks/ApplicationThreeStep";
import {ApplicationFourthStep} from "./blocks/ApplicationFourthStep";
import {useAdvertiserADList} from "../../hooks/Advertiser/CustomerCommercial/GetAdvertiserADList";
import {useAdvertiserADInfo} from "../../hooks/Advertiser/CustomerCommercial/GetAdvertiserADInfo";
import {useRecoilState} from "recoil";
import {adIdAtom} from "../../../utils/recoil/Customercommercial";


export const CustomerCommercial = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState(0); // 1: 승인대기 | 2: 승인완료 | 3: 거절 | 4: 재요청 | 5: 진행중 | 6: 광고종료
  const [order, setOrder] = useState('latest'); // latest, oldest

  // 유틸리티
  const [isOneStepModal, setIsOneStepModal] = useState(false);
  const [isTwoStepModal, setIsTwoStepModal] = useState(false);
  const [isThreeStepModal, setIsThreeStepModal] = useState(false);
  const [isFourthStepModal, setIsFourthStepModal] = useState(false);

  // 첫번째 단계 상태값
  const [title, setTitle] = React.useState('');
  const [startedAt, setStartedAt] = React.useState('');
  const [endedAt, setEndedAt] = React.useState('');
  const [itemId, setItemId] = React.useState<any>(null);
  const [fileList, setFileList] = React.useState<any>([]);
  const [days, setDays] = useState(0);
  // const [adId, setAdId] = React.useState<any>(null);
  const [adIdValue, setAdIdValue ] = useRecoilState(adIdAtom);
  const [totalPrice, setTotalPrice] = React.useState<any>(0);

  // 두번째 단계 상태값
  const [agentIds, setAgentIds] = React.useState([]);

  // 유틸리티
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // 데이터 조회
  const {advertiserADList, mutateAdvertiserADList, total, isLoading} = useAdvertiserADList(page, pageSize, status, order)
  const {advertiserADInfo, mutateAdvertiserADInfo} = useAdvertiserADInfo(adIdValue)

  const extractDates = (period) => {
    const regex = /(\d{4}-\d{2}-\d{2})~(\d{4}-\d{2}-\d{2})/;
    const matches = period?.match(regex);
    if (matches) {
      setStartedAt(matches[1]);
      setEndedAt(matches[2]);
    }
  };

  // useEffect(() => {
  //   console.log('adId', adId)
  // }, [adId]);

  useEffect(() => {
    console.log('advertiserADInfo', advertiserADInfo)

    setTitle(advertiserADInfo?.title)
    setItemId(advertiserADInfo?.itemId)
    setDays(Number(advertiserADInfo?.period?.split('일')[0]))
    extractDates(advertiserADInfo?.period)
    setAgentIds( advertiserADInfo?.list?.map(item => Number(item.agentId)) || [])

  }, [advertiserADInfo]);

  const RegistCommercial = () => {
    setIsOneStepModal(true)

    setTitle('')
    setItemId('')
    setDays(0)
    setStartedAt('')
    setEndedAt('')
    setAgentIds( [])
    setAdIdValue(null);
  }


  return (
    <>
      <Display>
        <RegistSection>
          <OutputFileButton onClick={() => RegistCommercial()}>광고신청</OutputFileButton>
        </RegistSection>

        <CustomerCommercialFilter
          setIsSelectItem={setIsSelectItem}
          isSelectItem={isSelectItem}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setStatus={setStatus}
          setOrder={setOrder}
          order={order}
          mutateAdvertiserADList={mutateAdvertiserADList}
        />
        <CustomerCommercialTable
          setIsSelectItem={setIsSelectItem}
          isSelectItem={isSelectItem}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isAllChecked={isAllChecked}
          setIsAllChecked={setIsAllChecked}
          advertiserADList={advertiserADList}
          mutateAdvertiserADList={mutateAdvertiserADList}
          setAdIdValue={setAdIdValue}
          setIsOneStepModal={setIsOneStepModal}
          advertiserADInfo={advertiserADInfo}
          setIsFourthStepModal={setIsFourthStepModal}
          setIsThreeStepModal={setIsThreeStepModal}
          total={total}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isLoading={isLoading}
        />
      </Display>

      {isOneStepModal &&
        <ApplicationOneStep
          setIsOneStepModal={setIsOneStepModal}
          setIsTwoStepModal={setIsTwoStepModal}
          title={title}
          setTitle={setTitle}
          startedAt={startedAt}
          setStartedAt={setStartedAt}
          endedAt={endedAt}
          setEndedAt={setEndedAt}
          itemId={itemId}
          setItemId={setItemId}
          fileList={fileList}
          setFileList={setFileList}
          days={days}
          setDays={setDays}
          setAdIdValue={setAdIdValue}
          setTotalPrice={setTotalPrice}
          mutateAdvertiserADList={mutateAdvertiserADList}
          totalPrice={totalPrice}
          adIdValue={adIdValue}
        />}

      {isTwoStepModal &&
        <ApplicationTwoStep
          setIsTwoStepModal={setIsTwoStepModal}
          setIsOneStepModal={setIsOneStepModal}
          setIsThreeStepModal={setIsThreeStepModal}
          adIdValue={adIdValue}
          totalPrice={totalPrice}
          days={days}
          startedAt={startedAt}
          endedAt={endedAt}
          mutateAdvertiserADList={mutateAdvertiserADList}
          agentIds={agentIds}
          setAgentIds={setAgentIds}
          mutateAdvertiserADInfo={mutateAdvertiserADInfo}
        />}

      {isThreeStepModal &&
        <ApplicationThreeStep
          setIsTwoStepModal={setIsTwoStepModal}
          setIsThreeStepModal={setIsThreeStepModal}
          setIsFourthStepModal={setIsFourthStepModal}
          advertiserADInfo={advertiserADInfo}
          adIdValue={adIdValue}
          mutateAdvertiserADList={mutateAdvertiserADList}
        />}


      {isFourthStepModal &&
        <ApplicationFourthStep
          setIsFourthStepModal={setIsFourthStepModal}
          advertiserADInfo={advertiserADInfo}
        />}
    </>
  )
}


const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`


const OutputFileButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

