import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {ContentsManageFilter} from "./ContentsManageFilter";
import {ContentsManageTable} from "./ContentsManageTable";
import {useOrderList} from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAdminContentsList} from "../../hooks/Admin/ContentsManage/GetAdminContentList";
import {RegistContents} from "./blocks/RegistContents";
import {useAgentContentsList} from "../../hooks/Agent/SelfCommercial/GetAgentContentList";
import API from "../../../utils/api";
import {message} from "antd";
import {useToken} from "../../../app/hooks";


export const SelfCommercial = () => {
  // 필터링
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [searchWord, setSearchWord] = useState('');
  const [order, setOrder] = useState<'latest' | 'oldest'>('latest');
  const [status, setStatus] = useState<0 | 1 | 2>(0);

  // 유틸리티
  const [registContentsModal, setRegistContentsModal] = useState(false);
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // 데이터 조회
  const {agentContentsList, mutateAgentContentsList, total, isLoading} = useAgentContentsList(page, pageSize, status, searchWord, order)
  const {data:token} = useToken()


  const checkSale = () => {
    API.get('/agent/sale/check', token + '' , {}).then((res) => {
      console.log(res?.result?.success);

      if(res?.result?.success) {
        setRegistContentsModal(true)
      } else {
        message.error(res?.result?.message ||'등록할 수 없습니다.')
      }

    }).catch((err:any) => {
      message.error('등록할 수 없습니다.', err)
    })
  }


  return (
    <Display>
      <RegistSection>
        <RegistSaleButton onClick={() => checkSale()}>자체광고 등록</RegistSaleButton>
      </RegistSection>


      <ContentsManageFilter
        setSearchWord={setSearchWord}
        setOrder={setOrder}
        order={order}
        setStatus={setStatus}
        mutateAgentContentsList={mutateAgentContentsList}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
      />

      <ContentsManageTable
        agentContentsList={agentContentsList}
        mutateAgentContentsList={mutateAgentContentsList}
        total={total}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        isLoading={isLoading}
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
      />

      {registContentsModal && <RegistContents setRegistContentsModal={setRegistContentsModal} mutateAgentContentsList={mutateAgentContentsList} />}
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const RegistSaleButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`
