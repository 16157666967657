import {MEDIA_URL} from "app/constants/APIKEYS";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Text} from "styles/reactnative";
import {Two} from "./DigitalSignage/Two";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const FullImageList = ({
                                imagelist,
                                isWidth = false,
                                width = "",
                                height = "",
                                type = 'full',
                                setItem, num, setIntervalTime
                              }: any) => {
  const timerref = React.useRef<any>(null);
  const videoNodeRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<any>();

  const [current, setCurrent] = useState<any>(0);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (imagelist?.length > 1) {
      timerref.current = setInterval(() => {
        setCurrent((prev) => (prev === imagelist.length - 1 ? 0 : prev + 1));
        setRetryCount(0);  // 슬라이드가 넘어갈 때 재시도 카운트를 리셋
      }, 2000);
    }

    return () => {
      if (timerref.current) {
        clearInterval(timerref.current);
      }
    };
  }, [imagelist]);


  // useEffect(() => {
  //
  //   console.log('imagelist', imagelist)
  //
  // }, [imagelist]);


  const currentMedia = encodeURI(imagelist?.[current]);


  useEffect(() => {

    if(setIntervalTime) {
      if(setItem[num]?.sec === 6){
        setIntervalTime(6000);
      } else if (setItem[num]?.sec === 12) {
        setIntervalTime(12000);
      }
    }

    // console.log('setItem', setItem[num])

  }, [setItem]);


  useEffect(() => {
    if (videoNodeRef.current && currentMedia.includes("mp4")) {
      // 기존 플레이어 제거
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = undefined;
      }

      videoNodeRef.current.src = `${MEDIA_URL}${currentMedia}`;

      // 새로 video.js 인스턴스 생성
      playerRef.current = videojs(videoNodeRef.current, {
        autoplay: true,
        controls: false,
        loop: true,
        muted: true,
        preload: "none",
        width: type === 'full' ? 1920 : type === 'six' ? width : 928,
        height: type === 'full' ? 1080 : type === 'six' ? height : 610,
      });

      playerRef.current.on('error', () => {
        const error = playerRef.current.error();
        // alert(`VideoJS error: ${error.message}`);

        if (retryCount < 99999999) {
          setRetryCount(retryCount + 1);
          setTimeout(() => {
            playerRef.current.src({src: `${MEDIA_URL}${currentMedia}`, type: "video/mp4"});
            playerRef.current.play();
          }, 1000);
        }

        if (error.code === 4) {
          alert("The video format or codec may not be supported by your browser or Chromecast.");
        }
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = undefined;
      }
    };
  }, [currentMedia]);


  useEffect(() => {
    const checkAndReloadVideo = () => {
      if (playerRef.current) {

        const videoElement = playerRef.current.tech(true).el();
        if (videoElement.readyState < 3) {
          playerRef.current.load();
          playerRef.current.play();
        }
      }
    };

    const interval = setInterval(checkAndReloadVideo, 2000);

    return () => clearInterval(interval);
  }, [currentMedia]);


  return (
    <>
      {(currentMedia + "")?.includes("mp4") ? (

        <div data-vjs-player>
          <video
            key={`${currentMedia}-${current}-${num}`}
            ref={videoNodeRef}
            className="video-js vjs-default-skin"
            playsInline
          />
        </div>

        // <ReactPlayer
        //   key={`${currentMedia}-${current}-${Math.random()}-${forceUpdate}`}  // 강제 업데이트 트리거
        //   ref={videoRef}
        //   url={MEDIA_URL + currentMedia}
        //   playing
        //   loop
        //   muted
        //   playsinline
        //   width={type === 'full' ? "100%" : "928px"}
        //   height={type === 'full' ? "100%" : "610px"}
        //   config={{
        //     file: {
        //       attributes: {
        //         autoPlay: true,
        //         muted: true,
        //         playsInline: true,
        //       },
        //     },
        //   }}
        // />
      ) : (
        <>
          {type === 'two' ?
            <>
              <TwoTopSaleImgWrapper IsSaleType={setItem[num]?.saleInfo?.saleType}>
                <TwoSaleImage
                  src={imagelist && MEDIA_URL + currentMedia}
                  isWidth={isWidth}
                  w={width}
                  h={height}
                />
              </TwoTopSaleImgWrapper>
              {setItem[num]?.saleInfo?.saleType && <Two item={setItem} num={num} type={"top"}/>}
            </>
            :
            type === 'six' ?
              <FullSaleImage
                src={imagelist && MEDIA_URL + currentMedia}
                isWidth={isWidth}
                w={width}
                h={height}
              />
              :
              currentMedia ? (
                setItem[0]?.saleInfo?.saleType ?
                  <>
                    <FullImageWrapper>
                      <TwoSaleImage
                        src={imagelist && MEDIA_URL + currentMedia}
                        isWidth={isWidth}
                        w={width}
                        h={height}
                      />
                    </FullImageWrapper>

                    <FullTextContainer>
                      <FullTitle>
                        {setItem[0]?.saleInfo?.saleType}
                        <FullColorTitle>
                          {setItem[0]?.saleInfo?.transactionType}
                        </FullColorTitle>
                      </FullTitle>
                      <FullAddress>
                        {setItem[0]?.saleInfo?.address}
                      </FullAddress>
                      <FullPrice>{setItem[0]?.saleInfo?.price}</FullPrice>
                      <FullLabel>{setItem[0]?.saleInfo?.size}</FullLabel>
                      <FullDesc>{setItem[0]?.saleInfo?.desc}</FullDesc>
                    </FullTextContainer>
                  </>
                  :
                  <FullSaleImage
                    src={imagelist && MEDIA_URL + currentMedia}
                    isWidth={isWidth}
                    w={width}
                    h={height}
                  />
              ) : (
                <Text>
                  No Images.
                </Text>
              )}
        </>
      )}
    </>
  );
};

// const FullSaleImage = styled.img`
//   width: 100%;
//     height: 100%;
//   position: relative;
// `;

const FullSaleImage = styled.img<{ isWidth: any; w: any; h: any }>`
    width: ${(props) => (props.isWidth ? props.w : "100%")};
    height: ${(props) => (props.isWidth ? "100%" : props.h)};
    position: relative;
`;


const TwoSaleImage = styled.img<{ isWidth: any; w: any; h: any }>`
    width: ${({w}) => w ? w : '100%'};
    height: ${({h}) => h ? h : '100%'};
    position: relative;
`;

const TwoTopSaleImgWrapper = styled.div<{ IsSaleType: boolean }>`
    width: ${({IsSaleType}) => IsSaleType ? '463px' : '926px'};
    height: 610px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`;

const FullImageWrapper = styled.div`
    width: 50%;
    height: 975px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

`;

const FullTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 983px;
`;

const FullTitle = styled.div`
    font-weight: 700;
    font-size: 110px;
    line-height: 110px;
    display: flex;
    gap: 20px;
    margin-bottom: 93px;
`;

const FullColorTitle = styled.div`
    color: #f33636;
`;

const FullAddress = styled.div`
    font-weight: 800;
    font-size: 60px;
    line-height: 45.4px;
    margin-bottom: 42px;
`;

const FullPrice = styled.div`
    font-weight: 800;
    font-size: 80px;
    line-height: 90.8px;
    color: #125de6;
    margin-bottom: 38px;
`;

const FullLabel = styled.div`
    font-weight: 700;
    font-size: 60px;
    line-height: 70.59px;
    color: #000000cc;
    display: flex;
    gap: 20px;
    white-space: pre-wrap;
`;

const FullValue = styled.div`
    font-weight: 800;
    color: #000000;
`;

const FullDesc = styled.div`
    font-weight: 700;
    font-size: 60px;
    line-height: 61.78px;
    color: #000000cc;
    margin-top: 93px;
    white-space: pre-wrap;
    text-align: center;
`;