import React, {startTransition, Suspense, useEffect, useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {DatePicker, Input, message, Select, TimePicker} from "antd";
import {HomeTownRoundCheckbox} from "../../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import TextArea from "antd/es/input/TextArea";
import {ReactComponent as RightArrowIcon} from "../assets/RightArrowIcon.svg";
import {PreviewMonitor} from "./PreviewMonitor";
import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps'
import {TwoStepListItem} from "../atom/TwoStepListItem";
import {RealEstateInfo} from "./RealEstateInfo";
import {useAdvertiserAgentList} from "../../../hooks/Advertiser/CustomerCommercial/GetAdvertiserAgentList";
import API from "../../../../utils/api";
import {useToken} from "../../../../app/hooks";
import {SelectAddressItem} from "../../MediaList/blocks/SelectAddressItem";
import {numberWithCommas} from "../../../../utils/ciutils";


export const ApplicationTwoStep = ({setIsTwoStepModal, setIsOneStepModal, setIsThreeStepModal, totalPrice, days, startedAt, endedAt, mutateAdvertiserADList, agentIds, setAgentIds, mutateAdvertiserADInfo, adIdValue}) => {
  // 유틸리티
  const [userLocation, setUserLocation] = useState({ lat: 37.5665, lng: 126.9780 }); // Default to Seoul
  const [realEstateId, setRealEstateId] = useState(null);
  const [dataMarkers, setDataMarkers] = useState<any>([]);
  const [isPreviewModal, setIsPreviewModal] = React.useState(false);
  const [fAddress, setFAddress] = useState('');

  // 주소 검색
  const [selectedFirstName, setSelectedFirstName] = React.useState<any>(null);
  const [selectedSecondName, setSelectedSecondName] = React.useState<any>(null);
  const [selectedThirdName, setSelectedThirdName] = React.useState<any>(null);

  //데이터 조회
  const {advertiserAgentList, mutateAdvertiserAgentList} = useAdvertiserAgentList(1, 999999999, fAddress)
  const {data: token} = useToken();
  const navermaps = useNavermaps();


  useEffect(() => {
    console.log('adIdValue', adIdValue)
  }, []);


  useEffect(() => {
    getUserLocation();
  }, []);


  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          // getAddressFromCoords(latitude, longitude);
          console.log(position)
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };


  const PostAdvertiserAgent = () => {
    const shouldEdit = window.confirm(`임시저장하시겠습니까?`);
    if (shouldEdit) {
      API.post('/advertiser/ad/list/agent', token + '', {
        adId: adIdValue,
        price : totalPrice * agentIds.length,
        agentIds
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(`저장하였습니다.`);
            setIsTwoStepModal(false);
            mutateAdvertiserADList();
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }

  const SubmitAdvertiserAgent = () => {
    const shouldEdit = window.confirm(`등록하시겠습니까?`);
    if (shouldEdit) {
      API.post('/advertiser/ad/list/submit', token + '', {
        adId: adIdValue,
        price : totalPrice * agentIds.length,
        agentIds
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(`등록하였습니다.`);
            mutateAdvertiserADList();
            mutateAdvertiserADInfo()
            setIsThreeStepModal(true);
            setIsTwoStepModal(false);
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.')
        })
    }
  }


  useEffect(() => {
    advertiserAgentList?.forEach((item, index) => {
      if (item?.address) handleGeocode(item?.address);
    });
  }, [advertiserAgentList]);


  const handleGeocode = (fullAddress: string) => {
    navermaps.Service.geocode({
      query: fullAddress
    }, (status, response) => {
      console.log(status);
      if (status === navermaps.Service.Status.OK) {
        const result = response.v2.addresses[0];
        const lat = parseFloat(result?.y);
        const lng = parseFloat(result?.x);
        setDataMarkers( prev => [...prev, { lat, lng }]);
        // console.log('Coordinates:', { lat, lng });
      } else {
        console.error('Geocoding failed:', response);
      }
    });
  };

  const setLocation = (fullAddress: string) => {
    navermaps.Service.geocode({
      query: fullAddress
    }, (status, response) => {
      if (status === navermaps.Service.Status.OK) {
        const result = response.v2.addresses[0];
        const lat = parseFloat(result?.y);
        const lng = parseFloat(result?.x);
        if(lat && lng){
          setUserLocation({ lat: lat, lng: lng });
        }
      } else {
        console.error('Geocoding failed:', response);
        message.error('해당 주소는 검색할 수 없습니다.')
      }
    });
  };


  useEffect(() => {
    const fullAddress = `${selectedFirstName || ''} ${selectedSecondName || ''} ${selectedThirdName || ''}`;
    // console.log('fullAddress', fullAddress)

    if (selectedFirstName || selectedSecondName || selectedThirdName) {
      setLocation(fullAddress);
    }
    setFAddress(fullAddress);
  }, [selectedFirstName, selectedSecondName, selectedThirdName]);


  // const handleGeocode = () => {
  //   if (window?.naver && window?.naver?.maps) {
  //     window?.naver?.maps?.Service?.geocode({
  //       query: address
  //     }, (status, response) => {
  //       if (status === window.naver.maps.Service.Status.OK) {
  //         const result = response.v2.addresses[0];
  //         // const lat = parseFloat(result.y);
  //         // const lng = parseFloat(result.x);
  //         // setCoordinates({ lat, lng });
  //         console.log('window.naver.maps', result)
  //       } else {
  //         console.error('Geocoding failed:', response);
  //       }
  //     });
  //   }
  // };

  // const getAddressFromCoords = (lat, lng) => {
  //   if (window.naver && window.naver.maps) {
  //     const geocoder = new window.naver.maps.Service();
  //     geocoder.reverseGeocode({
  //       coords: new window.naver.maps.LatLng(lat, lng),
  //       orders: [
  //         window.naver.maps.Service.OrderType.ADDR,
  //         window.naver.maps.Service.OrderType.ROAD_ADDR
  //       ].join(',')
  //     }, (status, response) => {
  //       if (status === window.naver.maps.Service.Status.OK) {
  //         const address = response.v2.address.jibunAddress || response.v2.address.roadAddress;
  //         setAddress(address);
  //       } else {
  //         console.error("Failed to get address from coordinates");
  //       }
  //     });
  //   }
  // };


  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Blacked onClick={() => {setIsTwoStepModal(false);}}/>
      <ModalWrapper>
        <ModalHeader>
          <div style={{padding: 12}}/>
          <ModalTitle>광고신청</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {setIsTwoStepModal(false);}  }/>
        </ModalHeader>

        <ProcessWrapper>
          <StepWrapper>
            <StepNumber>1</StepNumber>
            <StepTitle>광고내용입력</StepTitle>
          </StepWrapper>

          <ArrowSVG as={RightArrowIcon} />

          <StepWrapper>
            <StepNumber Active={true}>2</StepNumber>
            <StepTitle Active={true}>광고매체선택</StepTitle>
          </StepWrapper>

          <ArrowSVG as={RightArrowIcon} />

          <StepWrapper>
            <StepNumber>3</StepNumber>
            <StepTitle>신청완료 및 승인대기</StepTitle>
          </StepWrapper>

          <ArrowSVG as={RightArrowIcon} />

          <StepWrapper>
            <StepNumber>4</StepNumber>
            <StepTitle>승인완료 및 자동결제(예치금차감)</StepTitle>
          </StepWrapper>
        </ProcessWrapper>

        <AddressWrapper>
          <SelectAddressItem
            selectedFirstName={selectedFirstName}
            setSelectedFirstName={setSelectedFirstName}
            selectedSecondName={selectedSecondName}
            setSelectedSecondName={setSelectedSecondName}
            selectedThirdName={selectedThirdName}
            setSelectedThirdName={setSelectedThirdName}
          />
        </AddressWrapper>

        <ContentsSection>
          <MapDiv style={{ width: '620px', height: '680px' }}>
            <NaverMap
              center={userLocation}
              defaultZoom={12}
            >
              {dataMarkers?.map(marker => (
                <Marker
                  key={marker.id}
                  position={{ lat: marker.lat, lng: marker.lng }}
                />
              ))}
            </NaverMap>
          </MapDiv>

          <RightContents>
            <PredictValueWrapper>
              <CommercialText>
                예상 총 광고비
                <CommercialBoldText>{`${numberWithCommas(agentIds.length * totalPrice) || 0}원`}</CommercialBoldText>
              </CommercialText>

              <SubText>
                광고기간
                {days ?  <SubValue>{`${days}일 / ${startedAt} ~ ${endedAt}`}</SubValue> : <SubValue>-</SubValue>}
              </SubText>

              <SubText>
                광고비용
                <SubValue>{`매체 1곳당 ${numberWithCommas(totalPrice) || 0}원`}</SubValue>
              </SubText>
            </PredictValueWrapper>

            <ListHeader>
              {`${agentIds.length || 0}곳 선택`}

              {/*<RoundCheckWrapper>*/}
              {/*  <HomeTownRoundCheckbox checked={true} text={'인기순'} />*/}
              {/*  <HomeTownRoundCheckbox checked={false} text={'최신순'} />*/}
              {/*</RoundCheckWrapper>*/}
            </ListHeader>

            <ListContent>
              {advertiserAgentList?.map((item, index) => (
                <TwoStepListItem setRealEstateId={setRealEstateId} agentId={item?.agentId} address={item?.address} businessHours={item?.businessHours} imageUrl={item?.imageUrl} mediaInfo={item?.mediaInfo} store={item?.store} setAgentIds={setAgentIds} agentIds={agentIds} />
              ))}
            </ListContent>

          </RightContents>
        </ContentsSection>


        <ButtonWrapper>
          <CancelButton onClick={() => {
            setIsTwoStepModal(false);
            setIsOneStepModal(true);
          }}>이전</CancelButton>

          <TempSaveButton
            onClick={() => PostAdvertiserAgent()}>
            임시저장
          </TempSaveButton>

          <SignUpButton onClick={() => {
            SubmitAdvertiserAgent()
          }}>
            다음
          </SignUpButton>

          <CancelButton onClick={() => setIsTwoStepModal(false)}>취소</CancelButton>
        </ButtonWrapper>
      </ModalWrapper>

      {/*{isEditModal &&  <CommercialEdit setIsEditModal={setIsEditModal} />}*/}
      {/*{isPauseModal &&  <CommercialPause setIsPauseModal={setIsPauseModal} />}*/}
      {/*{isSuccessModal &&  <TransactionSuccess setIsSuccessModal={setIsSuccessModal} />}*/}
      {realEstateId !== null && <RealEstateInfo setRealEstateId={setRealEstateId} realEstateId={realEstateId} />}
      {/*{isPreviewModal && <PreviewMonitor setIsPreviewModal={setIsPreviewModal}/>}*/}
    </Suspense>
  )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 1200px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 35.8px;
    color: #2E2559;
`


const SignUpButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;



const ContentsSection = styled.div`
  display: flex;
    gap: 12px
`

const RightContents = styled.div`
    
`

const PredictValueWrapper = styled.div`
  width: 486px;
    height: 109px;
    padding-top: 16px;
    padding-left: 20px;
`

const CommercialText = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 21.48px;
    color: #530DAD;
    gap: 4px;
    margin-bottom: 16px;
`

const CommercialBoldText = styled.div`
    font-weight: 700;
`

const SubText = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    gap: 10px;
`

const SubValue = styled.div`

`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    gap: 6px;
`


const TempSaveButton = styled.div`
    width: 100px;
    padding: 11px;
    color: #FF6C4B;
    border: 1px solid #FF6C4B;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const CancelButton = styled.div`
    width: 100px;
    padding: 11px;
    border: 1px solid #000000;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const ProcessWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 45.5px;
    margin-bottom: 67.5px;
`

const ArrowSVG = styled.svg`

`

const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const StepNumber = styled.div<{Active?:boolean}>`
    background-color: ${(props) => props.Active ? '#2E1155' : '#BFBFBF' };
    border-radius: 50px;
    width: 18px;
    height: 18px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 14px;
    font-weight: bold;
`

const StepTitle = styled.div<{Active?:boolean}>`
    font-family: Pretendard, sans-serif;
    font-weight: ${(props) => props.Active ? '400' : '600' };
    font-size: 16px;
    line-height: 19.09px;
    color: ${(props) => props.Active ? '#000000' : '#000000B2' };
`


const ListHeader = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19.09px;
    margin-bottom: 10px;
    padding: 0px 12px;
    display: flex;
    justify-content: space-between;
`

const RoundCheckWrapper = styled.div`
    display: flex;
    gap: 14px;
`


const ListContent = styled.div`
    border-bottom: 1px solid #0000000D;
    border-top: 1px solid #0000000D;
    height: 504px;
    overflow: scroll;
`

const AddressWrapper = styled.div`
    width: 100%;
    margin-bottom: 5px;
    padding: 0 10px;
`