import React, {useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {Input, message, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import API from "../../../../utils/api";
import {useToken} from "../../../../app/hooks";

export const DepositRecharge = ({setRechargeModal, mutateAdvertiserTransactionList}) => {
    const [payType, setPayType] = React.useState(1);
    const [amount, setAmount] = React.useState(0);

    // 데이터 조회
    const {data: token} = useToken();

    const BadgeItem = [
        {name: '1만', value: 10000},
        {name: '3만', value: 30000},
        {name: '5만', value: 50000},
        {name: '10만', value: 100000},
    ]

    const PayInfo = [
      {
            name: '계좌이체',
            value: 1,
        },
        {
            name: '카드결제',
            value: 2
        },
        {
            name: '카카오페이',
            value: 3
        }
    ]


    const PostRecharge = () => {
        const shouldEdit = window.confirm(`예치금을 충전하시겠습니까?`);
        if (shouldEdit) {
            API.post('/advertiser/transaction/list', token + '', {
                type: 1,
                amount,
                payType
            })
              .then((res: any) => {
                  if (res.result.success) {
                      message.success(`충전하였습니다.`);
                      mutateAdvertiserTransactionList();
                      setRechargeModal(false);
                  } else {
                      message.error(res.result.message || '실패하였습니다');
                  }
              })
              .catch((err: any) => {
                  message.error('실패하였습니다.')
              })
        }
    }

    return (
      <>
          <Blacked onClick={() => setRechargeModal(null)}/>
          <ModalWrapper>
              <ModalHeader>
                  <ModalTitle>예치금 충전</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setRechargeModal(null)}  }/>
              </ModalHeader>

              <Table>
                  <tbody>
                  <TableRow>
                      <Th>
                          <ThLabel>
                              충전금액
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <div>
                              <InputText value={amount} onChange={e => setAmount(Number(e.target.value) || 0)} suffix={'원'} placeholder={'직접입력'} />
                              <BadgesWrapper>
                                  {BadgeItem.map((item, index) => (
                                      <Badge onClick={() => setAmount(item.value)}>{item.name}</Badge>
                                  ))}
                              </BadgesWrapper>
                          </div>
                      </Td>
                  </TableRow>

                  <TableRow>
                      <Th>
                          <ThLabel>
                              결제방법
                          </ThLabel>
                      </Th>
                      <Td colSpan={3}>
                          <StyledSelect value={payType} onChange={(e: any) => setPayType(e)}>
                              {PayInfo.map((item, index) => (
                                  <Select.Option value={item?.value}>
                                      {item?.name}
                                  </Select.Option>
                              ))}
                          </StyledSelect>
                      </Td>
                  </TableRow>
                  </tbody>
              </Table>

              <ButtonsWrapper>
                  <RegistButton onClick={() => PostRecharge()}>
                      결제하기
                  </RegistButton>
              </ButtonsWrapper>
          </ModalWrapper>
      </>
    )
}

const StyledSelect = styled(Select)`
    width: 160px;
    border: 1px solid #00000033;
    border-radius: 7px;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 808px;
    width: 580px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 20%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
    vertical-align: middle;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
`;

const InputText = styled(Input)`
    width: 160px;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;

    &::placeholder {
        color: #535353;
    }
`;


const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const RegistButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    background-color: #530DAD;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`

const BadgesWrapper = styled.div`
    display: flex;
    gap: 4px;
    margin-top: 10px;
`

const Badge = styled.div`
    background-color: #F5F5F5;
    padding: 2px 4px;
    cursor: pointer;
    font-family: Pretendard, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    color: #000000B2;
`
