import React, { useState } from "react";
import { Blacked } from "../../../../CRM/components/styles";
import { ReactComponent as ModalCloseIcon } from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import { ReactComponent as CustomerCard } from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import { ReactComponent as RealEstateCard } from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import { Input, message, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import API from "../../../../utils/api";
import { useToken } from "../../../../app/hooks";
import { Text } from "../../../../styles/reactnative";

export const RefundRequest = ({
  setRefundModal,
  mutateAdvertiserTransactionList,
}) => {
  const [amount, setAmount] = React.useState(0);
  const [reason, setReason] = React.useState("");

  // 데이터 조회
  const { data: token } = useToken();

  const PostRefund = () => {
    const shouldEdit = window.confirm(`예치금을 환급하시겠습니까?`);
    if (shouldEdit) {
      API.post("/advertiser/transaction/list", token + "", {
        type: 2,
        amount,
        reason,
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success(`환급하였습니다.`);
            mutateAdvertiserTransactionList();
            setRefundModal(false);
          } else {
            message.error(res.result.message || `실패하였습니다.`);
          }
        })
        .catch((err: any) => {
          message.error("실패하였습니다.");
        });
    }
  };

  return (
    <>
      <Blacked onClick={() => setRefundModal(null)} />
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>환급신청</ModalTitle>
          <CloseIconSVG
            as={ModalCloseIcon}
            onClick={() => {
              setRefundModal(null);
            }}
          />
        </ModalHeader>

        <Table>
          <tbody>
            <TableRow>
              <Th>
                <ThLabel>환급신청금액</ThLabel>
              </Th>
              <Td colSpan={3}>
                <div>
                  <InputText
                    suffix={"원"}
                    placeholder={"직접입력"}
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value) || 0)}
                  />
                </div>
              </Td>
            </TableRow>

            <TableRow>
              <Th>
                <ThLabel>사유입력</ThLabel>
              </Th>
              <Td colSpan={3}>
                <TextArea
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="사유작성"
                  value={reason}
                  style={{ width: "100%", height: 79, resize: "none" }}
                />
              </Td>
            </TableRow>
          </tbody>
        </Table>

        <Text style={{ marginTop: 20, fontSize: 12, color: "#00000099" }}>
          * 이체수수료를 제외한 환금액으로 회원가입시 등록한 계좌로 환급됩니다.
        </Text>

        <ButtonsWrapper>
          <RegistButton onClick={() => PostRefund()}>신청하기</RegistButton>

          <CancelButton onClick={() => setRefundModal(null)}>취소</CancelButton>
        </ButtonsWrapper>
      </ModalWrapper>
    </>
  );
};

const StyledSelect = styled(Select)`
  width: 160px;
  border: 1px solid #00000033;
  border-radius: 7px;
`;

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 808px;
  width: 580px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 8px;
  padding: 24px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CloseIconSVG = styled.svg`
  width: 24px;
  cursor: pointer;
`;

const ModalTitle = styled.div`
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21.48px;
  color: #2e2559;
`;

const Table = styled.table`
  width: 100%;
  margin-top: 8px;
  border-collapse: collapse;
  border: 1px solid #ccc;
`;

const TableRow = styled.tr`
  min-height: 40px;
`;

const Th = styled.th`
  padding: 10px;
  text-align: left;
  width: 20%;
  border: 1px solid #ccc;
  background-color: #f4f2ff;
  vertical-align: middle;
`;

const ThLabel = styled.div`
  font-family: Pretendard, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.71px;
  color: #00000099;
`;

const Td = styled.td`
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
`;

const InputText = styled(Input)`
  width: 160px;
  height: 28px;
  padding-left: 10px;
  border: 1px solid #2e2559;
  border-radius: 4px;
  display: flex;

  &::placeholder {
    color: #535353;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
`;

const RegistButton = styled.div`
  width: 100px;
  height: 40px;
  padding: 11px;
  background-color: #530dad;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 15px;
  line-height: 17.9px;
  font-family: Pretendard, sans-serif;
`;

const CancelButton = styled.div`
  width: 100px;
  height: 40px;
  padding: 11px;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 15px;
  line-height: 17.9px;
  font-family: Pretendard, sans-serif;
`;
