import React from 'react'
import { TopBarWrapper } from './styles';
import { FaBars } from 'react-icons/fa6';
import { Icon } from '../organism/Main/styles';
import { useLocation } from 'react-router-dom';

export const TopBar = ({ view, setView }) => {
  const location = useLocation()
  const { pathname } = location;
  const current = pathname.split("/admin")[1]

  return (
    <TopBarWrapper style={{width: view ? 'calc(100vw - 280px)' : '100vw'}}>
      <Icon onClick={() => setView(!view)}>
        <FaBars />
      </Icon>
      {current == '/commercial' && '광고관리'}
      {current == '/selfCommercial' && '자체광고'}
      {current == '/saleProperty' && '매물관리'}
      {current == '/outputManage' && '출력관리'}
      {current == '/transaction' && '사용료관리'}
      {current == '/calculation' && '광고수익관리'}
      {current == '/notice' && '공지사항'}
      {current == '/inquireList' && '1:1문의'}
      {current == '/settingAgent' && '환경설정'}

      {current == '/customerCommercial' && '광고신청'}
      {current == '/mediaList' && '광고처리스트'}
      {current == '/deposit' && '예치금관리'}

      {current == '/userManage' && '회원관리'}
      {current == '/commercialFee' && '광고요금관리'}
      {current == '/paymentManage' && '결제관리'}
      {current == '/calculationAdmin' && '광고수익관리'}
      {current == '/contentsManage' && '콘텐츠관리'}
      {current == '/noticeAdmin' && '공지사항'}
      {current == '/inquireAdmin' && '1:1문의'}
      {current == '/settingAdmin' && '환경설정'}

      {/*{current == '/branch' && '페이다 입출금'}*/}
      {/*{current == '/platform' && '플랫폼관리'}*/}
      {/*{current == '/board' && '업무요청게시판'}*/}
      {/*{current == '/employee' && '직원관리'}*/}
      {/*{current == '/branchManage' && '지점관리'}*/}
      {/*{current == '/teamManage' && '팀관리'}*/}
      {/*{current == '/setting' && '설정'}*/}
    </TopBarWrapper>
  )
}

export default React.memo(TopBar);