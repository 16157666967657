import { Spin } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import {FullImageList} from "../../FullImageList";

export const Two = ({ item, num, type, all=[], allok=false }) => {
  const [zoom, setZoom] = React.useState(1);

  const ref = React.useRef<HTMLDivElement>(null);

  const [loading, setLoading] = React.useState(true);
  const [ok, setOk] = React.useState(false);

  //item[num].saleInfo 제외
  let except:any = null;

  let [print, setPrint] = React.useState<any>(  );
  // useEffect(() => {
  //
  //   console.log('all', all)
  //
  //   if(all?.length > 0){
  //     except = all.filter((i:any, index:any) => JSON.stringify(i) != JSON.stringify(item[num]?.saleInfo));
  //     //랜덤으로 1개
  //     if(except){
  //       if(except?.length > 1){
  //         except = except[Math.floor(Math.random() * except?.length)];
  //       }else{
  //         except = except[0];
  //       }
  //     }
  //   }
  //   if(except){
  //     setPrint(except);
  //   }
  // }, [item, all]);

  useEffect(() => {
    setPrint(item[num]?.saleInfo)
  }, [item, num]);

  useEffect(() => {
    if (item) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [item]);

  useEffect(() => {
    if(ok){ return; }
    if (!loading) {
      //높이

      const height =  ref.current?.offsetHeight || 389;
      let zoom =  389 / height;
      //setZoom(Math.min(zoom, 1));

      setOk(true);
    }
  }, [loading, ok]);


  // useEffect(() => {
  //   console.log('print', print)
  // }, [print]);


  if (loading) {
    if (type == "bottom") {
      return (
        <TwoBottomTextContainer ref={ref} zoom={zoom}>
          <Spin size="large" />
        </TwoBottomTextContainer>
      );
    } else {
      return (
        <TwoTopTextContainer ref={ref} zoom={zoom}>
          <Spin size="large" />
        </TwoTopTextContainer>
      );
    }
  }

  if (type == "bottom") {
    return (
      <>
        {print?.saleType ?
          <TwoBottomTextContainer ref={ref} zoom={zoom}>
            <TwoBottomTitle>
              {print?.saleType}
              <TwoBottomColorTitle>
                {print?.transactionType}
              </TwoBottomColorTitle>
            </TwoBottomTitle>
            <TwoBottomAddress>{print?.address}</TwoBottomAddress>
            <TwoBottomPrice>{print?.price}</TwoBottomPrice>
            <TwoBottomLabel>{print?.size}</TwoBottomLabel>
          </TwoBottomTextContainer>

          :

          <TwoBottomTextContainer ref={ref} zoom={zoom}>
            <FullImageList
              imagelist={item[num]?.imageUrlList}
              isWidth={true}
              setItem={item}
              type={"two"}
              num={num}
              width={'467px'}
              height={'389px'}
            />
          </TwoBottomTextContainer>
        }
      </>
    );
  }

  return (
    <TwoTopTextContainer ref={ref} zoom={zoom}>
      <TwoTopTitle>
        {print?.saleType}
        <TwoTopColorTitle>
          {print?.transactionType}
        </TwoTopColorTitle>
      </TwoTopTitle>
      <TwoTopAddress>{print?.address}</TwoTopAddress>
      <TwoTopPrice>{print?.price}</TwoTopPrice>
      <TwoTopLabel>{print?.size}</TwoTopLabel>
      <TwoTopDesc>{print?.desc}</TwoTopDesc>
    </TwoTopTextContainer>
  );
};
const TwoBottomTextContainer = styled.div<{ zoom? }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 467px;
  height: 389px;
  border: 6px solid #222222;
    overflow: hidden;

  & div {
    zoom: ${(props) => props.zoom || 1};
  }
`;

const TwoBottomTitle = styled.div<{ textlength? }>`
  font-weight: 700;
  font-size: 58px;
  line-height: 58px;
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
`;

const TwoBottomColorTitle = styled.div`
  color: #f33636;
`;

const TwoBottomAddress = styled.div`
  font-weight: 800;
  font-size: 34px;
  line-height: 35.24px;
  margin-bottom: 21.76px;
`;

const TwoBottomPrice = styled.div`
  font-weight: 800;
  font-size: 42px;
  line-height: 47.67px;
  color: #125de6;
  margin-bottom: 16px;
`;

const TwoBottomLabel = styled.div`
  font-weight: 700;
  font-size: 34px;
  line-height: 35.7px;
  color: #000000cc;
  display: flex;
  gap: 8px;
  margin-bottom: 8.52px;
  white-space: pre-wrap;
`;

const TwoBottomValue = styled.div`
  font-weight: 800;
  color: #000000;
`;

const TwoTopTextContainer = styled.div<{ zoom? }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 464px;

  & > div {
    zoom: ${(props) => props.zoom || 1};
  }
`;

const TwoTopTitle = styled.div<{ textlength? }>`
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  display: flex;
  gap: 10px;
  margin-bottom: 56px;
`;

const TwoTopColorTitle = styled.div`
  color: #f33636;
  word-break: break-all;
`;

const TwoTopAddress = styled.div`
  font-weight: 800;
  font-size: 35px;
  line-height: 27.24px;
  margin-bottom: 21.76px;
  word-break: break-all;
`;

const TwoTopPrice = styled.div`
  font-weight: 800;
  font-size: 50px;
  line-height: 56.75px;
  color: #125de6;
  margin-bottom: 23.31px;
`;

const TwoTopLabel = styled.div`
  font-weight: 700;
  font-size: 35px;
  line-height: 37.24px;
  color: #000000cc;
  display: flex;
  gap: 11.21px;
  white-space: pre-wrap;
`;

const TwoTopValue = styled.div`
  font-weight: 800;
  color: #000000;
`;

const TwoTopDesc = styled.div`
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  color: #000000cc;
  margin-top: 29px;
  white-space: pre-wrap;
  text-align: center;
`;
