import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {PaymentDetail} from "./blocks/PaymentDetail";
import {numberWithCommas} from "../../../utils/ciutils";


export const PaymentManageTable = ({
                                     adminPaymentADList,
                                     mutateAdminPaymentADList,
                                     adminPaymentAgentList,
                                     adminPaymentReturnList,
                                     categoryItem,
                                     selectedRows,
                                     setSelectedRows,
                                     isAllChecked,
                                     setIsAllChecked,
                                     isSelectItem,
                                     adTotal, agentTotal, returnTotal,
                                     page, setPage, pageSize, setPageSize, isLoadingReturn, isLoadingAgent, isLoadingAD
                                   }) => {
  const [userId, setUserId] = useState(null);
  const {data: token} = useToken();

  useEffect(() => {
    console.log('selectedRows', selectedRows)
  }, [selectedRows]);


  useEffect(() => {
    if(selectedRows === '광고주'){
      const allSelected = adminPaymentADList?.length > 0 && adminPaymentADList?.every(it => selectedRows?.includes(it.paymentId));
      setIsAllChecked(allSelected);
    }else if(selectedRows === '매체주') {
      const allSelected = adminPaymentAgentList?.length > 0 && adminPaymentAgentList?.every(it => selectedRows?.includes(it.paymentId));
      setIsAllChecked(allSelected);
    }else {
      const allSelected = adminPaymentReturnList?.length > 0 && adminPaymentReturnList?.every(it => selectedRows?.includes(it.paymentId));
      setIsAllChecked(allSelected);
    }
  }, [selectedRows, adminPaymentADList, adminPaymentReturnList, adminPaymentAgentList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    if(selectedRows === '광고주'){
      const ids = adminPaymentADList?.map(db => db.paymentId);
      setSelectedRows(e.target.checked ? ids : []);
    }else if(selectedRows === '매체주') {
      const ids = adminPaymentAgentList?.map(db => db.paymentId);
      setSelectedRows(e.target.checked ? ids : []);
    }else {
      const ids = adminPaymentReturnList?.map(db => db.paymentId);
      setSelectedRows(e.target.checked ? ids : []);
    }

  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const customerColumns = [
    {
      dataIndex: 'paymentId',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '아이디',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setUserId(data?.userId)} style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex'
          }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'type',
      title: '구분',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'content',
      title: '내역',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'amount',
      title: '금액',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(value)+' 원'}</div>
        )
      }
    },
    {
      dataIndex: 'payAt',
      title: '결제일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'memo',
      title: '영수증',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
  ];

  const realEstateColumns = [
    {
      dataIndex: 'paymentId',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '아이디',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setUserId(data?.userId)} style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex'
          }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'optionName',
      title: '권한',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'content',
      title: '내역',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'amount',
      title: '금액',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(value)+' 원'}</div>
        )
      }
    },
    {
      dataIndex: 'payAt',
      title: '결제일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex',}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'memo',
      title: '수단',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    }
  ];

  const userColumns = [
    {
      dataIndex: 'paymentId',
      title: isSelectItem ?
        (<Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data?.paymentId)} key={data?.paymentId}
                          onChange={(e) => handleCheckboxChange(e, data?.paymentId)}/>
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '아이디',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setUserId(data?.userId)} style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex'
          }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'account',
      title: '계좌정보',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'amount',
      title: '환급액',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'flex-end', display: 'flex'}}>{numberWithCommas(value)+' 원'}</div>
        )
      }
    },
    {
      dataIndex: 'createdAt',
      title: '신청일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'status',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='date'
               style={{justifyContent: 'center', display: 'flex'}}>{value === 0 ? '환급필요' : '환급완료'}</div>
        )
      }
    },
  ];

  const OutColumn = () => {
    if (categoryItem === '광고주') {
      return customerColumns
    } else if (categoryItem === '매체주') {
      return realEstateColumns
    } else {
      return userColumns
    }
  }

  const OutData = () => {
    if (categoryItem === '광고주') {
      return adminPaymentADList
    } else if (categoryItem === '매체주') {
      return adminPaymentAgentList
    } else {
      return adminPaymentReturnList
    }
  }

  const OutTotal = () => {
    if (categoryItem === '광고주') {
      return adTotal
    } else if (categoryItem === '매체주') {
      return agentTotal
    } else {
      return returnTotal
    }
  }


  const OutLoading = () => {
    if (categoryItem === '광고주') {
      return isLoadingAD
    } else if (categoryItem === '매체주') {
      return isLoadingAgent
    } else {
      return isLoadingReturn
    }
  }

  return (
    <DBFilterWrapper>
      <DBFilterCheckboxArea>

        {/*<div style={{display: 'flex', gap: 10, justifyContent: 'space-between', width: '100%',}}>*/}
        {/*  <CheckboxUnit>*/}
        {/*    <DBTableExcelBtn onClick={excelDownAll}>*/}
        {/*      <Icon><FaRegFileExcel /></Icon>*/}
        {/*      엑셀다운로드*/}
        {/*    </DBTableExcelBtn>*/}
        {/*    <DBTableExcelBtn onClick={excelDownSelect}>*/}
        {/*      <Icon><FaRegFileExcel /></Icon>*/}
        {/*      선택 다운로드*/}
        {/*    </DBTableExcelBtn>*/}
        {/*  </CheckboxUnit>*/}

        {/*  <DBTableExcelBtn onClick={clickDelete} style={{backgroundColor: '#f93e3e', color: '#fff'}}>*/}
        {/*    선택 삭제*/}
        {/*  </DBTableExcelBtn>*/}
        {/*</div>*/}

      </DBFilterCheckboxArea>
      <StyleTable columns={OutColumn()}
                  dataSource={OutData()}
                  bordered={true}
                  loading={OutLoading()}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: OutTotal(),
                  }}
                  onChange={handleTableChange}
      />

      {userId !== null && <PaymentDetail userId={userId} setUserId={setUserId} categoryItem={categoryItem}/>}

    </DBFilterWrapper>
  )
}

export default React.memo(PaymentManageTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
