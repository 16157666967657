import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";


const M_KEY = '/advertiser/ad/list' // 광고상세조회

const fetchKey = (id) =>
	`${M_KEY}/${id}`;

export function useAdvertiserADInfo(id) {
	const { data: token } = useToken();

	const KEY = fetchKey(id);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { advertiserADInfo: data, mutateAdvertiserADInfo: mutate};
}

async function fetchAPI(KEY:string, token:any)  {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return response.result?.result;
	} catch (error) {
		console.error('Failed to fetch Advertiser AD Info:', error);
		return { list: [], total: 0 };
	}
}