import React, {useState, useEffect, useContext, useMemo} from 'react'
import {Table, Checkbox, message, Input, Button} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {OutputCommercialDetail} from "./blocks/OutputCommercialDetail";
import {PreviewMonitor} from "./blocks/PreviewMonitor";
import {SalePreviewMonitor} from "./blocks/SalePreviewMonitor";
import { HolderOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import API from "../../../utils/api";


const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners }:any = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};


export const OutputManageTable = ({
                                    agentOutputList,
                                    mutateAgentOutputList,
                                    setPage,
                                    page,
                                    setPageSize,
                                    pageSize,
                                    total,
                                    changeOrder,
                                    setProgressList, isLoading
                                  }) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [previewId, setPreviewId] = useState(null);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [isSalePreviewModal, setSaleIsPreviewModal] = useState(false);

  // 데이터 조회
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = agentOutputList?.length > 0 && agentOutputList?.every(it => selectedRows?.includes(it.adId));
    setIsAllChecked(allSelected);
  }, [selectedRows, agentOutputList]);


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  // const addProgressData = (adId, order) => {
  //   const data = { adId, order };
  //
  //   setProgressList(prev => {
  //     const index = prev.findIndex(item => item.adId === adId);
  //
  //     // order가 빈 값이면 해당 데이터를 삭제
  //     if (order === 0 || order === null || order === undefined) {
  //       if (index !== -1) {
  //         const updatedList = [...prev];
  //         updatedList.splice(index, 1);
  //         return updatedList;
  //       } else {
  //         // 삭제할 항목이 없으면 원래 리스트 반환
  //         return prev;
  //       }
  //     }
  //
  //     // order가 빈 값이 아닌 경우 추가하거나 덮어쓰기
  //     if (index !== -1) {
  //       const updatedList = [...prev];
  //       updatedList[index] = data;
  //       return updatedList;
  //     } else {
  //       return [...prev, data];
  //     }
  //   });
  // }


  const columns = [
    {
      dataIndex: '',
      title: changeOrder ? '순서' : 'No',
      render: (value, data, index) => {
        return (
          <>
            {changeOrder ?
              // <div className='No.' style={{justifyContent: 'center', display: 'flex', alignItems: 'center', gap: '10px'}}>
              //   {data?.order}
              //   <Input style={{width:50}}  onChange={(e) => addProgressData(data?.adId, Number(e.target.value) | 0)}  />
              // </div>
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>
                <DragHandle />
              </div>
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.adId || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: '',
      title: '거래구분',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{data?.type || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '광고명/매물명',
      render: (value, data, index) => {
        return (
          <div className='No.' onClick={() => setItemId(data?.adId)} style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex'
          }}>{data?.title || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '시작일',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.startedAt || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '종료(예정)일',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.endedAt || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '파일',
      render: (value, data) => {
        return (
          <div className='date'
               onClick={() => {
                 if(data?.type === '광고'){
                   setIsPreviewModal(true);
                   setPreviewId(data?.adId)
                 } else {
                   setSaleIsPreviewModal(true);
                   setPreviewId(data?.adId)
                 }
               }}
               style={{justifyContent: 'center', display: 'flex', color: '#9747FF', cursor: 'pointer'}}>보기</div>
        )
      }
    },
    {
      dataIndex: 'status',
      title: '진행현황',
      render: (value, data) => {
        return (
          <div className='date' style={{
            justifyContent: 'center',
            display: 'flex'
          }}>{value || '-'}</div>
        )
      }
    },
  ];


  const Row = (props) => {

    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    });

    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
          position: 'relative',
          zIndex: 9999,
        }
        : {}),
    };


    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners],
    );

    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const postChangeOrder = (selectItem, targetItem) => {
    API.post('/agent/progress/list', token + '', {
      list: [
        selectItem,
        targetItem
      ]
    })
      .then((res: any) => {
        if (res.result.success) {
          mutateAgentOutputList();
          message.success(`순서가 변경되었습니다.`);
        } else {
          message.error(res.result.message || '실패하였습니다');
        }
      })
      .catch((err: any) => {
        message.error('실패하였습니다.')
      })
  }



  const onDragEnd = ({ active, over }) => {
    console.log('active',active)
    console.log('over',over)

    if (!active || !over) return;
    if (active?.id !== over?.id) {
      const selectItem = { adId: active?.id, order: over?.data?.current?.sortable?.index + 1 };
      const targetItem = { adId: over?.id, order: active?.data?.current?.sortable?.index + 1 };
      postChangeOrder(selectItem, targetItem);
    }
  };

  useEffect(() => {

    console.log('agentOutputList', agentOutputList)

  }, [agentOutputList]);

  return (
    <DBFilterWrapper>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={Array.isArray(agentOutputList) ? agentOutputList?.map(i => i.adId ) : []}
          strategy={verticalListSortingStrategy}
        >
          <StyleTable columns={columns}
                      dataSource={agentOutputList}
                      bordered={true}
                      loading={isLoading}
                      rowKey="adId"
                      components={{
                        body: {
                          row: Row,
                        },
                      }}
                      pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                      }}
                      onChange={handleTableChange}
          />
        </SortableContext>
      </DndContext>

      {itemId !== null && <OutputCommercialDetail itemId={itemId} setItemId={setItemId} mutate={mutateAgentOutputList}/>}
      {isPreviewModal &&  <PreviewMonitor setIsPreviewModal={setIsPreviewModal} itemId={previewId} agentOutputList={agentOutputList}  />}
      {isSalePreviewModal &&  <SalePreviewMonitor setIsPreviewModal={setSaleIsPreviewModal} itemId={previewId} agentSaleItem={null}  />}
    </DBFilterWrapper>
  )
}

export default React.memo(OutputManageTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
