import React, { useState, useEffect } from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {SalePropertyDetail} from "./blocks/SalePropertyDetail";
import {PreviewMonitor} from "./blocks/PreviewMonitor";



export const SalePropertyTable = ({setPage, isDetailModal, setIsDetailModal, page, setPageSize, pageSize, agentSaleList, mutateAgentSaleList, total, setIsSelectItem, isSelectItem, selectedRows, setSelectedRows, isAllChecked, setIsAllChecked, isLoading}) => {
  const [itemId, setItemId] = useState(null);
  const [previewId, setPreviewId] = useState(null);
  const [isPreviewModal, setIsPreviewModal] = useState(false);

  const { data: token } = useToken();



  useEffect(() => {
    const allSelected = agentSaleList?.length > 0 && agentSaleList?.every(it => selectedRows?.includes(it.adId));
    setIsAllChecked(allSelected);
  }, [selectedRows, agentSaleList]);

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = agentSaleList?.map(db => db.adId);
    setSelectedRows(e.target.checked ? ids : []);
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      dataIndex: '',
      title: isSelectItem ?
        ( <Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.adId)} key={data?.adId} onChange={(e) => handleCheckboxChange(e, data?.adId)} />
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.adId || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: '',
      title: '매물명',
      render: (value, data, index) => {
        return (
          <div onClick={() => setItemId(data?.adId)} className='No.' style={{textDecoration: 'underline', cursor: 'pointer', justifyContent: 'center', display: 'flex'}}>{data?.title || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '매물종류',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{data?.type || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '거래구분',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.transactionType || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '거래가',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.price || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '매물주소',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{data?.address || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '등록일',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{data?.createdAt || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '이미지',
      render: (value, data) => {
        return (
          <div className='date' onClick={() => {
            setIsPreviewModal(true);
            setPreviewId(data?.adId);
          }} style={{justifyContent: 'center', display: 'flex', color: '#9747FF', cursor: 'pointer'}}>미리보기</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '진행현황',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{data?.status || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'tempStatus',
      title: '임시저장상태',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value === 1 ? '임시저장중' : '-'}</div>
        )
      }
    },
  ];

  return (
    <DBFilterWrapper>
      <DBFilterCheckboxArea>

      </DBFilterCheckboxArea>
      <StyleTable columns={columns}
             dataSource={agentSaleList}
             bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

     {isDetailModal && <SalePropertyDetail setIsDetailModal={setIsDetailModal} itemId={itemId} setItemId={setItemId} mutate={mutateAgentSaleList}  />}
     {itemId !== null && <SalePropertyDetail setIsDetailModal={setIsDetailModal} itemId={itemId} setItemId={setItemId} mutate={mutateAgentSaleList} />}
      {isPreviewModal &&  <PreviewMonitor setIsPreviewModal={setIsPreviewModal} itemId={previewId} agentSaleItem={{}}  />}
    </DBFilterWrapper>
  )
}

export default React.memo(SalePropertyTable);

const ColumnHeader = styled.div`
  
`

const StyleTable = styled(Table)`
 .ant-table-thead > tr > th {
    background-color: #F4F2FF;
    color: #00000099;
     font-size: 14px;
     font-weight: 500;
     line-height: 16.71px;
  }
`
