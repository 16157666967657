import React, {startTransition, useEffect, useState} from 'react'
import {ReactComponent as ModalCloseIcon} from "../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as ImageDeleteIcon} from "../../../assets/HomeTownIcon/ImageDeleteIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {message, Select, TimePicker} from "antd";
import moment from 'moment';
import {useToken} from "../../../app/hooks";
import API from "../../../utils/api";
import {Blacked} from "../../../CRM/components/styles";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {useUserInfo} from "../../hooks/Auth/GetUserInfo";
import dayjs from "dayjs";
import {SelectAddressItem} from "../../Customer/MediaList/blocks/SelectAddressItem";



export const AgentDetail = ({agentDetailModal, setAgentDetailModal}) => {
  // 유틸리티
  const [transWeeks, setTransWeeks] = React.useState('');
  const [openTime, setOpenTime] = React.useState('');
  const [closeTime, setCloseTime] = React.useState('');
  const [imageUrl, setImageUrl] = React.useState<any>('');
  const [fileInputKey, setFileInputKey] = useState(Date.now()); // 파일 초기화


  // 회원가입 정보
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [store, setStore] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [email, setEmail] = useState('');
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [mediaInfo, setMediaInfo] = React.useState(0);
  const [mediaType, setMediaType] = React.useState(0);
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = React.useState('');
  const [footTrafficGrade, setFootTrafficGrade] = React.useState('');
  const [region, setRegion] = useState('');
  const [fileList, setFileList] = React.useState<any>([]);


  // 수정 정보
  const [deleteFileList, setDeleteFileList] = React.useState<any>([]);
  const [localFileList, setLocalFileList] = React.useState<any>([]);


  // 데이터 조회
  const { userInfo, mutateUserInfo } = useUserInfo();
  const {data: token} = useToken();


  // 주소 검색
  const [selectedFirstName, setSelectedFirstName] = React.useState<any>(null);
  const [selectedSecondName, setSelectedSecondName] = React.useState<any>(null);
  const [selectedThirdName, setSelectedThirdName] = React.useState<any>(null);


  useEffect(() => {
    const fullAddress = `${selectedFirstName || ''} ${selectedSecondName || ''} ${selectedThirdName || ''}`;
    // console.log('fullAddress', fullAddress)

    if (selectedFirstName || selectedSecondName || selectedThirdName) {
      setRegion(fullAddress);
    }
  }, [selectedFirstName, selectedSecondName, selectedThirdName]);


  useEffect(() => {
    console.log('userInfo', userInfo)

    const MEDIA_INFO_TO_INDEX = {
      '40인치이하': 1,
      '40인치이상': 40,
      '50인치이상': 50,
      '60인치이상': 60,
    };

    const MEDIA_TYPE_TO_INDEX = {
      '실내': 1,
      '실외': 2,
    };

    const getMediaIndex = (mediaInfo) => MEDIA_INFO_TO_INDEX[mediaInfo] || 0;
    const getMediaTypeIndex = (mediaInfo) => MEDIA_TYPE_TO_INDEX[mediaInfo] || 0;

    const parsedSchedule = parseScheduleString(userInfo?.businessHours);

    setName(userInfo?.name);
    setPhone(userInfo?.phone);
    setStore(userInfo?.store);
    setAddress(userInfo?.address);
    setDetailAddress(userInfo?.detailAddress);
    setEmail(userInfo?.email);
    setAccount(userInfo?.account);
    setPassword(userInfo?.password);
    setMediaInfo(getMediaIndex(userInfo?.mediaInfo));
    setMediaType(getMediaTypeIndex(userInfo?.mediaType));
    setBusinessRegistrationNumber(userInfo?.businessRegistrationNumber);
    setFootTrafficGrade(userInfo?.footTrafficGrade);
    setLocalFileList(userInfo?.fileList);

    setTransWeeks(parsedSchedule?.days);
    setOpenTime(parsedSchedule?.startHour);
    setCloseTime(parsedSchedule?.endHour);

    if(userInfo?.region){
      const AddressArray = userInfo?.region?.split(' ')
      setSelectedFirstName(AddressArray[0]);
      setSelectedSecondName(AddressArray[1]);
      setSelectedThirdName(AddressArray[2]);
    }
  }, [userInfo]);


  const parseScheduleString = (scheduleString) => {
    const daysMapping = ['월', '화', '수', '목', '금', '토', '일'];

    // 문자열을 분리
    const daysString = scheduleString.slice(0, 7);
    const startHour = scheduleString.slice(11, 13);
    const endHour = scheduleString.slice(14, 16);

    console.log(daysString, startHour, endHour)

    // 요일 변환
    const daysArray = daysString.split('');
    const selectedDays = daysArray.map((day, index) => (day === '1' ? daysMapping[index] : null)).filter(Boolean);

    console.log(selectedDays.join(''), startHour, endHour)

    return {
      days: selectedDays.join(''),
      startHour,
      endHour,
    };
  };


  const UploadData = (file, type, fileType) => {
    if (!file) {
      message.error('파일을 선택하세요');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);


    API.postImage('POST', '/content?type=user', '', formData)
      .then((res) => {
        if (res.message === 'success') {
          message.success('파일이 추가되었습니다.');
          console.log(res)

          const url = res?.result?.url;
          const name = res?.result?.name;

          setFileList([...fileList, {type, fileType, url, name}])
          setFileInputKey(Date.now())
        } else {
          message.error('파일이 잘못되었습니다');
        }
      })
      .catch((err) => {
        message.error('업로드 중 오류가 발생했습니다');
        console.log('Upload Data ERROR',err)
      });
  };

  const openPostcode = () => {
    new window.daum.Postcode({
      oncomplete: function (data: any) {
        const fullAddress = data?.address;
        startTransition(() => {
          setAddress(fullAddress);
          // // 좌표 변환 API 호출
          // handleGeocode(fullAddress);
        });
      }
    }).open();
  };


  const MediaInfomation = [
    {
      name: '40인치 이하',
      value: 1
    },
    {
      name: '40인치 이상',
      value: 40
    },
    {
      name: '50인치 이상',
      value: 50
    },
    {
      name: '60인치 이상',
      value: 60
    },
  ]

  const TransferWeek = (days) => {
    const dayMap = {
      '월': 0,
      '화': 1,
      '수': 2,
      '목': 3,
      '금': 4,
      '토': 5,
      '일': 6
    };

    let binaryArray = new Array(7).fill('0');

    for (let day of days) {
      if (dayMap[day] !== undefined) {
        binaryArray[dayMap[day]] = '1';
      }
    }

    return binaryArray.join('');
  }


  const putEditAgent = () => {
    const shouldEdit = window.confirm(`수정하시겠습니까?`);
    const weeks = TransferWeek(transWeeks);

    if(shouldEdit) {
      const businessHours = weeks + '/*-/' + openTime + '~' + closeTime;
      const IMGCount = fileList.filter(file => file.type === 'IMG').length > 1


      if(!name && !phone && !store && !address && !email && !account && !password && !mediaInfo && !mediaType && !businessHours && !businessRegistrationNumber && !IMGCount && !address) {
        message.error('필수 사항을 입력해주세요.')
        // console.log(name, phone , store , address , email , account , password , mediaInfo , mediaType , businessHours , businessRegistrationNumber , IMGCount , ACount , BCount)
      } else {
        API.put('/user/info', token + '', {
         datas: {
           email,
           password,
           name,
           phone,
           store,
           businessType,
           address,
           detailAddress,
           email2:email,
           account,
           mediaInfo,
           mediaType,
           businessHours,
           businessRegistrationNumber,
           footTrafficGrade,
           fileList,
           deleteFileList,
           region
         }
        })
          .then((res: any) => {
            if (res.result.success) {
              message.success('수정하였습니다.');
              setAgentDetailModal(false);
              mutateUserInfo();
            } else {
              message.error( res.result.message || '실패하였습니다.');
            }
          })
          .catch((err: any) => {
            message.error('실패하였습니다');
          })
      }
    }
  }


  const deleteUser = () => {
    const shouldEdit = window.confirm(`탈퇴하시겠습니까?`);
    if(shouldEdit) {
      API.delete('/user/info', token + '', {})
        .then((res: any) => {
          if (res.result.success) {
            message.success('탈퇴되었습니다.');
            setAgentDetailModal(false);
            mutateUserInfo();
          } else {
            message.error(res.result.message ||  '실패하였습니다.');
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다.');
        })
    }
  }


  return (
    <>
      <Blacked onClick={() => setAgentDetailModal(false)}/>
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>설정</ModalTitle>
          <CloseIconSVG as={ModalCloseIcon} onClick={() => {setAgentDetailModal(false)}  }/>
        </ModalHeader>

        <Table>
          <tbody>
          <TableRow>
            <Th>
              <ThLabel>
                이름
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" value={name} onChange={e => setName(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                연락처
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents style={{paddingRight: 167}}>
                <Input type="text" value={phone} onChange={e => setPhone(e.target.value)} placeholder={'ex. 010-1234-1234'}/>
                {/*<GreyButton>휴대폰인증</GreyButton>*/}
              </TdRowContents>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                상호
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" value={store} onChange={e => setStore(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                지역
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <SelectAddressItem selectedFirstName={selectedFirstName} setSelectedFirstName={setSelectedFirstName}
                                 selectedSecondName={selectedSecondName} setSelectedSecondName={setSelectedSecondName}
                                 selectedThirdName={selectedThirdName} setSelectedThirdName={setSelectedThirdName}/>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                주소
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents>
                <GreyButton onClick={() => openPostcode()}>주소검색</GreyButton>
                <Input type="text" value={address} disabled/>
              </TdRowContents>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                세부주소
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" value={detailAddress} onChange={e => setDetailAddress(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                메일(아이디)
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" placeholder={'test@gmail.com'} value={email} onChange={e => setEmail(e.target.value)} disabled/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                비밀번호
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text"  value={password} onChange={e => setPassword(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                계좌번호
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td><Input type="text" placeholder={'한국은행/123-45-678/홍길동 | 광고수익 송금시 사용됩니다.'} value={account} onChange={e => setAccount(e.target.value)}/></Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                매체정보
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents style={{gap: 10}}>
                <StyledSelect value={mediaInfo} onChange={value => setMediaInfo((value as number))}>
                  {MediaInfomation.map((item, index) => (
                    <Select.Option value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </StyledSelect>

                <HomeTownRoundCheckbox text={'실내'} checked={mediaType === 1} onClick={() => setMediaType(1)} />
                <HomeTownRoundCheckbox text={'실외'} checked={mediaType === 2} onClick={() => setMediaType(2)}/>
              </TdRowContents>
            </Td>
          </TableRow>
          <TableRow>
          <Th>
              <ThLabel>
                매체 운영시간
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents style={{gap: 10}}>
                <Input type="text" placeholder={'ex. 월화수목금토일'} style={{width:'40%'}} value={transWeeks} onChange={e => setTransWeeks(e.target.value)}/>
                <div style={{display: "flex", alignItems: "center"}}>
                  <StyleTimePicker
                    format="HH"
                    placeholder="오픈시간"
                    onChange={e => setOpenTime(e ? e.format('HH') : '')}
                    value={dayjs(openTime, 'HH')}
                  />
                  -
                  <StyleTimePicker
                  format="HH"
                  placeholder="마감시간"
                  onChange={e => setCloseTime(e ? e.format('HH') : '')}
                  value={dayjs(closeTime, 'HH')}
                  />
                </div>
              </TdRowContents>
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                중개소 등록증
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <FileInput key={fileInputKey} type="file" onChange={({target: {files}}) => {
              files?.length && UploadData(files[0], 'A', 0)
            }}/>

              {localFileList?.length > 0 &&
                localFileList?.filter(it => it?.type === 'A').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setLocalFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }

              {fileList?.length > 0 &&
                fileList?.filter(it => it?.type === 'A').map((item, index) => (
                  // <div style={{position: 'relative', width: 120}}>
                  //   <ImageDeleteIcon style={{position: 'absolute', right: 0, cursor: 'pointer'}} onClick={() => {
                  //     setDeleteFileList([...deleteFileList, item?.urlId]);
                  //     setFileList(prev => {
                  //       const prevIndex =  prev.findIndex(i => i.urlId === item?.urlId);
                  //       prev.splice(prevIndex, 1);
                  //
                  //       return prev
                  //     });
                  //   }}/>
                  //   <img src={"https://api.myhometown-ad.co.kr/" + item?.url} key={`${item?.name}_${index}`} style={{width: 120}}/>
                  // </div>

                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                사업자등록증
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents style={{gap: 4}}>
                <FileInput key={fileInputKey} type="file" onChange={({target: {files}}) => {
                  files?.length && UploadData(files[0], 'B', 0)
                }}/>
                <Input type="text" placeholder={'ex. 861-30-01283'} value={businessRegistrationNumber} onChange={e => setBusinessRegistrationNumber(e.target.value)}/>
              </TdRowContents>

              {localFileList?.length > 0 &&
                localFileList?.filter(it => it?.type === 'B').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setLocalFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }

              {fileList?.length > 0 &&
                fileList?.filter(it => it?.type === 'B').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }
            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                사진
                <RequireIcon>*</RequireIcon>
              </ThLabel>
            </Th>
            <Td>
              <FileInput key={fileInputKey} type="file" onChange={({target: {files}}) => {
                files?.length && UploadData(files[0], 'IMG', 0);
              }}/>

              {localFileList?.length > 0 &&
                localFileList?.filter(it => it?.type === 'IMG').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setLocalFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }

              {fileList?.length > 0 &&
                fileList?.filter(it => it?.type === 'IMG').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }


              <DescText>
                {`사진 2매 이상 필수 
1. 부동산중개소 정면사진
2. 매체포함 사진`}
              </DescText>

            </Td>
          </TableRow>
          <TableRow>
            <Th>
              <ThLabel>
                유동인구 및 상권
              </ThLabel>
            </Th>
            <Td>
              <TdRowContents>
                1. 유동인구
                <FileInput key={fileInputKey} type="file" style={{width: '50%', marginLeft: 6}} onChange={({target: {files}}) => {
                  files?.length && UploadData(files[0], 'FT', 0)
                }}/>
              </TdRowContents>

              {localFileList?.length > 0 &&
                localFileList?.filter(it => it?.type === 'FT').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setLocalFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }

              {fileList?.length > 0 &&
                fileList?.filter(it => it?.type === 'FT').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }

              <TdRowContents style={{marginTop: 13.5}}>
                일평균 유동인구 :
                <Input type="text" style={{width: '50%', marginLeft: 6}} placeholder={'유동인구 등급'} value={footTrafficGrade} onChange={e => setFootTrafficGrade(e.target.value)}/>
              </TdRowContents>

              <DescText>
                {`나이스비즈맵(www.nicebizmap.co.kr) 참조
나이스비즈맵 > 상권분석 > 분석지역 > 유동인구`}
              </DescText>


              <TdRowContents>
                2. 상권특성
                <FileInput key={fileInputKey} type="file" style={{width: '50%', marginLeft: 6}} onChange={({target: {files}}) => {
                  files?.length && UploadData(files[0], 'F', 0)
                }}/>
              </TdRowContents>

              {localFileList?.length > 0 &&
                localFileList?.filter(it => it?.type === 'F').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setLocalFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }

              {fileList?.length > 0 &&
                fileList?.filter(it => it?.type === 'F').map((item, index) => (
                  <DataText key={item?.url}>
                    {item?.name}
                    <DeleteItemIconSVG as={ModalCloseIcon} onClick={() => {
                      setDeleteFileList([...deleteFileList, item?.urlId]);
                      setFileList(prev => {
                        const newFileList = [...prev];
                        const prevIndex = newFileList.findIndex(i => i.urlId === item?.urlId);
                        if (prevIndex !== -1) {
                          newFileList.splice(prevIndex, 1);
                        }
                        return newFileList;
                      });
                    }}/>
                  </DataText>
                ))
              }

              <DescText>
                {`나이스비즈맵(m.nicebizmap.co.kr)참조
상권분석>분석지역 > 분석업종 > 보고서 보기 > 상권특성, 유동인구 > 
분석업종 대분류(음식) > 중분류(간이주점)> 소분류(호프/맥주)`}
              </DescText>


            </Td>
          </TableRow>

          {/*<TableRow>*/}
          {/*  <Th>프로필 사진</Th>*/}
          {/*  <Td><Input type="file"/></Td>*/}
          {/*</TableRow>*/}
          {/*<TableRow>*/}
          {/*  <Td colSpan={2}>*/}
          {/*    <Button type="submit">회원가입</Button>*/}
          {/*  </Td>*/}
          {/*</TableRow>*/}
          </tbody>
        </Table>


        <ButtonWrapper>
          <SignUpButton onClick={() => putEditAgent()}>
            수정
          </SignUpButton>
          <DeleteButton onClick={() => deleteUser()}>
            탈퇴
          </DeleteButton>
        </ButtonWrapper>
        {/*<DescText>*/}
        {/*  가입후 계약서 작성 등의 별도의 절차가 있습니다*/}
        {/*</DescText>*/}

      </ModalWrapper>
    </>
  )
}


const DataText = styled.div`
    font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #000000;
    margin-top: 8px;
    white-space: pre-wrap;
`


const DeleteItemIconSVG = styled.svg`
    width: 12px;
    cursor: pointer;
    margin-left: 10px;
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    width: 800px;
    min-height: 411px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Table = styled.table`
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 22%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    width: 78%;
    border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #000000;
`

const DescText = styled.div`
  font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #00000099;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const Input = styled.input`
    width: 100%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const FileInput = styled.input`
    width: 100%;
    padding: 2px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const GreyButton = styled.div`
    height: 31px;
    width: 120px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000001A;
    cursor: pointer;
    color: #000000CC;
    margin: 0 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
`

const StyledSelect = styled(Select)`
    width: 160px;
    border: 1px solid #00000033;
    border-radius: 7px;
`

const SignUpButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const StyleTimePicker = styled(TimePicker)`
    width: 107px;
    
    &::placeholder {
        color: #000000B2;
    }
`

const DeleteButton = styled.div`
    width: 100px;
    padding: 11px;
    background-color: white;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 5px;
`