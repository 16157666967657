import React, {useState} from 'react'
import {Blacked} from "../../../../CRM/components/styles";
import {ReactComponent as ModalCloseIcon} from "../../../../assets/HomeTownIcon/ModalCloseIcon.svg";
import {ReactComponent as CustomerCard} from "../../../../assets/HomeTownLogin/CustomerCard.svg";
import {ReactComponent as RealEstateCard} from "../../../../assets/HomeTownLogin/RealEstateCard.svg";
import styled from "styled-components";
import {Select} from "antd";


export const CommercialEdit = ({setIsEditModal, EditRealEstate}) => {

    return (
      <>
          <Blacked onClick={() => setIsEditModal(false)}/>
          <ModalWrapper>
              <ModalHeader>
                  <ModalTitle>광고수정</ModalTitle>
                  <CloseIconSVG as={ModalCloseIcon} onClick={() => {setIsEditModal(false)}  }/>
              </ModalHeader>

              <Contents>
                  {`광고를 수정하시겠습니까?
(익일 0시에 반영됩니다)`}
              </Contents>

              <ButtonsWrapper>
                  <SignUpButton onClick={() => EditRealEstate()}>
                      확인
                  </SignUpButton>

                  <CancelButton onClick={() => setIsEditModal(false)}>
                      취소
                  </CancelButton>
              </ButtonsWrapper>
          </ModalWrapper>
      </>
    )
}

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 220px;
    width: 330px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const CloseIconSVG = styled.svg`
    width: 24px;
    cursor: pointer;
`

const ModalTitle = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.48px;
    color: #2E2559;
`

const Contents = styled.div`
    margin-top: 45px;
    font-weight: 400;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
    color: #000000;
    white-space: pre-wrap;
    text-align: center;
`

const ButtonsWrapper = styled.div`
    margin-top: 36px;
    display: flex;
    gap: 6px;
`

const SignUpButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    background-color: #530DAD;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;

const CancelButton = styled.div`
    width: 100px;
    height: 40px;
    padding: 11px;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 500;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard, sans-serif;
`;