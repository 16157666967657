import useSWR from 'swr'
import {useToken} from "../../../../app/hooks";
import API from "../../../../utils/api";
import {useEffect} from "react";

interface ResponseTypes {
	list: any[];
	total: number;
}

const M_KEY = '/board/inquiry/list' // 게시글 목록조회

const fetchKey = (page, maxData, date, type, searchWord) =>
	`${M_KEY}?${page && 'page=' + page}${maxData && '&maxData=' + maxData}${type > 0 ? '&type=' + type : ''}${date && '&date=' + date }${searchWord && '&searchWord=' + searchWord}`;

export function useAgentInquireList(page, maxData, type, date, searchWord) {
	const { data: token } = useToken();

	const KEY = fetchKey(page, maxData, type, date, searchWord);


	const fetcher = () => fetchAPI(KEY, token);
	const { data, mutate, isLoading } = useSWR(token ? `${KEY}` : null, fetcher, { revalidateOnFocus: true });

	return { agentInquireList: data?.list, mutateAgentInquireList: mutate, total: data?.total, isLoading };
}

async function fetchAPI(KEY:string, token:any): Promise<ResponseTypes> {
	try {
		const response = await API.get(`${KEY}`, token + '', {});
		return {list: response.result?.result?.list || [], total: response.result?.result?.totalCount}
	} catch (error) {
		console.error('Failed to fetch Agent Inquire List:', error);
		return { list: [], total: 0 };
	}
}