import React, {useEffect, useRef, useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {OutputManageFilter} from "./OutputManageFilter";
import {OutputManageTable} from "./OutputManageTable";
import { useOrderList } from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAgentOutputList} from "../../hooks/Agent/OutputManage/GetAgentOutputList";
import {useNavigate} from "react-router";
import {useRecoilValue} from "recoil";
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';
import Whammy from 'whammy';
import {videoFunctionAtom} from "../../../utils/recoil/capture";
import {PreviewFile} from "./blocks/PreviewFile";
import {fetchFile, toBlobURL} from "@ffmpeg/util";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import {setTimeout} from "worker-timers";
import {Input, TimePicker} from "antd";
import moment from "moment";

const ffmpeg = new FFmpeg();


export const OutputManage = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [type, setType] = useState(''); // ad, sale
  const [status, setStatus] = useState(0); // 1.광고중 | 2.출력대기 | 3.광고중(종료예정)
  const [order, setOrder] = useState('latest'); // latest, oldest
  const [searchWord, setSearchWord] = useState('');

  // 유틸리티
  const [changeOrder, setChangeOrder] = useState(false);
  const [progressList, setProgressList] = useState<any>([]);
  const navigate = useNavigate();

  // 데이터 조회
  const { agentOutputList, mutateAgentOutputList, total, isLoading } = useAgentOutputList(page, pageSize, type, status, order, searchWord)


  // 파일로 추출
  const [isPreview, setIsPreview] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [fileLoadedCheck, setFileLoadedCheck] = useState(0);
  const captureRef = useRef<HTMLDivElement | null>(null);
  const [progress, setProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);


  // useEffect(() => {
  //   console.log('fileLoaded', fileLoaded)
  // }, [fileLoaded]);

  // const loadFFmpeg = async () => {
  //   const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
  //
  //   await ffmpeg.load({
  //     coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
  //     wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
  //   });
  //   setProgress(0);
  //   setFileLoadedCheck(1);
  //   setTimeout(() => setFileLoaded(true), 1000);
  // };


  // useEffect(() => {
  //   if(fileLoaded && (fileLoadedCheck === 1)){
  //     generateVideo();
  //   }
  // }, [fileLoaded, fileLoadedCheck]);


  // const generateVideo = async () => {
  //   if (!captureRef.current) {
  //     console.error("Capture ref is not set");
  //     return;
  //   }
  //
  //   setFileLoadedCheck(0);
  //
  //   const frames = [];
  //   const duration = videoDuration; // videoDuration
  //   const frameRate = 30; // 30fps
  //   const totalFrames = (duration / 1000) * frameRate;
  //
  //   // const canvasElement = captureRef.current;
  //   // const canvasWidth = canvasElement.clientWidth % 2 === 0 ? canvasElement.clientWidth : canvasElement.clientWidth - 1;
  //   // const canvasHeight = canvasElement.clientHeight % 2 === 0 ? canvasElement.clientHeight : canvasElement.clientHeight - 1;
  //
  //   for (let i = 0; i < totalFrames; i++) {
  //     try {
  //       await new Promise((resolve) => setTimeout(resolve, 100)); // 잠시 대기 (프레임별 시간 간격)
  //
  //       const canvas = await html2canvas(captureRef.current, {
  //         width: 1920,
  //         height: 1080,
  //         useCORS: true
  //       });
  //       const dataUrl = canvas.toDataURL("image/png");
  //       const binary = atob(dataUrl.split(",")[1]);
  //       const array = [];
  //
  //       for (let j = 0; j < binary.length; j++) {
  //         // @ts-ignore
  //         array.push(binary.charCodeAt(j));
  //       }
  //
  //       const blob = new Blob([new Uint8Array(array)], { type: "image/png" });
  //       const file = new File([blob], `frame_${i}.png`);
  //       // @ts-ignore
  //       frames.push(file);
  //
  //       setProgress(Math.round((i / totalFrames) * 50));
  //     } catch (error) {
  //       console.error("Canvas generation error:", error);
  //       setFileLoaded(false);
  //       setIsPreview(false);
  //       setFileLoadedCheck(0);
  //     }
  //   }
  //
  //   console.log("frames", frames);
  //
  //   for (let i = 0; i < frames.length; i++) {
  //     console.log(`Writing frame_${i}.png to FFmpeg`);
  //     await ffmpeg.writeFile(`frame_${i}.png`, await fetchFile(frames[i]));
  //
  //     setProgress(Math.round(50 + (i / frames.length) * 25));
  //   }
  //
  //   const files = await ffmpeg.listDir('/');
  //   console.log("Files in FFmpeg file system before exec:", files);
  //
  //   let ffmpegLogs = '';
  //   ffmpeg.on("log", ({ type, message }) => {
  //     ffmpegLogs += `[${type}] ${message}\n`;
  //   });
  //
  //   console.log("Executing FFmpeg command");
  //
  //   try {
  //     await ffmpeg.exec([
  //       "-r", "30", "-f", "image2", "-s", `${1920}x${1080}`, "-i", "frame_%d.png",
  //       "-vcodec", "libx264", "-crf", "25", "-pix_fmt", "yuv420p", "output.mp4",
  //     ]);
  //     setProgress(90);
  //   } catch (error) {
  //     console.error("FFmpeg exec error:", error);
  //     console.log("FFmpeg logs:", ffmpegLogs);
  //
  //     setFileLoaded(false);
  //     setIsPreview(false);
  //     setFileLoadedCheck(0);
  //   }
  //
  //   const filesAfterExec = await ffmpeg.listDir('/');
  //   console.log("Files in FFmpeg file system after exec:", filesAfterExec);
  //
  //   console.log("Reading output.mp4 from FFmpeg");
  //   try {
  //     const fileData = await ffmpeg.readFile("output.mp4");
  //     if (fileData.length === 0) {
  //       console.error("Output video file is empty");
  //       console.log("FFmpeg logs:", ffmpegLogs);
  //       return;
  //     }
  //     // @ts-ignore
  //     const data = new Uint8Array(fileData);
  //     const videoBlob = new Blob([data.buffer], { type: "video/mp4" });
  //     const videoUrl = URL.createObjectURL(videoBlob);
  //
  //     console.log('data', data);
  //     console.log('videoBlob', videoBlob);
  //     console.log('videoUrl', videoUrl);
  //
  //     const link = document.createElement("a");
  //     link.href = videoUrl;
  //     link.download = "output.mp4";
  //     link.click();
  //
  //     setProgress(100);
  //   } catch (error) {
  //     setFileLoaded(false);
  //     setIsPreview(false);
  //     setFileLoadedCheck(0);
  //
  //     console.error("Error reading output.mp4:", error);
  //     console.log("FFmpeg logs:", ffmpegLogs);
  //   }
  //
  //   setFileLoaded(false);
  //   setIsPreview(false);
  //   setFileLoadedCheck(0);
  // };

  // const handleTimeChange = (time, timeString) => {
  //   if (time) {
  //     const momentTime = moment(timeString, 'HH:mm');
  //     const hours = momentTime.hours();
  //     const minutes = momentTime.minutes();
  //     const durationInSeconds = (hours * 3600 + minutes * 60) * 1000;
  //
  //     // console.log('momentTime', momentTime);
  //     // console.log('hours', hours);
  //     // console.log('minutes', minutes);
  //     // console.log('durationInSeconds', durationInSeconds);
  //     setVideoDuration(durationInSeconds);
  //   }
  // };




  return(
    <Display style={{position: 'relative'}}>

      {/*<PreviewFile  captureRef={captureRef}/>*/}

      <RegistSection>
        <ViewMonitor onClick={() => {
          navigate('/digitalSignage')
        }}>광고화면보기</ViewMonitor>

        {/*<StyledTimePicker*/}
        {/*  format="HH:mm"*/}
        {/*  onChange={handleTimeChange}*/}
        {/*  placeholder={'영상 시간 설정'}*/}
        {/*/>*/}

        {/*<OutputFileButton fileLoaded={fileLoaded} onClick={() => {*/}
        {/*  if(fileLoaded){*/}
        {/*    return*/}
        {/*  } else {*/}
        {/*    // setIsPreview(true);*/}
        {/*    loadFFmpeg();*/}
        {/*  }*/}
        {/*}}>파일로 추출*/}
        {/*  {fileLoaded &&  <ProgressBar progress={progress} />}*/}
        {/*</OutputFileButton>*/}

        {/*{isPreview &&*/}
        {/*  <PreviewFileWrapper>*/}
        {/*    <PreviewFile setFileLoaded={setFileLoaded} fileLoaded={fileLoaded} ffmpeg={ffmpeg} loadFFmpeg={loadFFmpeg} captureRef={captureRef}/>*/}
        {/*  </PreviewFileWrapper>*/}
        {/*}*/}

      </RegistSection>

        <OutputManageFilter
          setOrder={setOrder}
          order={order}
          setSearchWord={setSearchWord}
          setStatus={setStatus}
          setType={setType}
          setChangeOrder={setChangeOrder}
          changeOrder={changeOrder}
          progressList={progressList}
          mutateAgentOutputList={mutateAgentOutputList}
        />

        <OutputManageTable
          agentOutputList={agentOutputList}
          mutateAgentOutputList={mutateAgentOutputList}
          setPage={setPage}
          page={page}
          setPageSize={setPageSize}
          pageSize={pageSize}
          total={total}
          changeOrder={changeOrder}
          setProgressList={setProgressList}
          isLoading={isLoading}
        />
    </Display>
)
}

const StyledTimePicker = styled(TimePicker)`
    margin-right: 5px;

    .ant-picker-input input::placeholder {
        color: #535353; /* 원하는 색상으로 변경 */
    }
`

const RegistSection = styled.div`
display: flex;
justify-content: flex-end;
    position: relative;
    align-items: center;
`

const ViewMonitor = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
    color: #9747FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    cursor: pointer;
`

const OutputFileButton = styled.div<{fileLoaded:boolean}>`
    background-color: ${(props) => props.fileLoaded ? '#9c9b9b' : '#530DAD' };
    position: relative;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: ${(props) => props.fileLoaded ? 'wait' : 'pointer' };
    
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard ,sans-serif;
`


const PreviewFileWrapper = styled.div`
    position: absolute;
    top: 45px;
    right: -10px;
    box-shadow: 0px 0px 10px 0px #0000000D;
    border: 1px solid #eae5e5;
    z-index: 10;
`

const ProgressWrapper = styled.div`
    
`

const ProgressBar = styled.div<{ progress: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${(props) => props.progress}%;
  height: 10px;
  background-color: green;
`;