import React, {useEffect, useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {CommercialFilter} from "./CommercialFilter";
import {CommercialTable} from "./CommercialTable";
import { useOrderList } from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import {useAgentADList} from "../../hooks/Agent/Commercial/GetAgentADList";


export const Commercial = () => {

  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [order, setOrder] = useState('latest'); // latest, oldest
  const [status, setStatus] = useState(0); // 1: 승인대기 | 2: 승인완료 | 3: 거절 | 4: 재요청 | 5: 진행중 | 6: 광고종료

  // 유틸리티
  const [isSelectItem, setIsSelectItem] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // 데이터 조회
  const { agentADList, mutateAgentADList, total, isLoading } = useAgentADList(page, pageSize, status, order);


  return(
    <Display>
      <CommercialFilter
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        setOrder={setOrder}
        order={order}
        setStatus={setStatus}
        mutateAgentADList={mutateAgentADList}
      />

      <CommercialTable
        setIsSelectItem={setIsSelectItem}
        isSelectItem={isSelectItem}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isLoading={isLoading}
        setPage={setPage}
        page={page}
        setPageSize={setPageSize}
        pageSize={pageSize}
        agentADList={agentADList}
        mutateAgentADList={mutateAgentADList}
        total={total}
      />
    </Display>
  )
}

export default React.memo(Commercial)