import React, {useEffect, useState} from 'react'
import {
	DBFilterDateArea,
	DBFilterWrapper,
	DBTableExcelBtn,
	DateArea,
	GotoStatus,
} from '../../../CRM/organism/DB/styles'
import { Select, Input, ConfigProvider, DatePicker, Upload, message } from 'antd'
import { RangePickerProps } from 'rc-picker/es/RangePicker.d'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import locale from 'antd/lib/locale/ko_KR'
import { Icon } from '../../../CRM/organism/Main/styles'
import { IoSearch } from 'react-icons/io5'
import styled from "styled-components";
import {HomeTownRoundCheckbox} from "../../../app/organisms/Auth/Forms/HomeTownRoundCheckbox";
import {ReactComponent as SearchIcon} from "./assets/SearchIcon.svg";
import {useUserInfo} from "../../hooks/Auth/GetUserInfo";
import {useAdminAgentPriceList} from "../../hooks/Admin/CommercialFee/GetAdminAgentPriceList";
import {numberWithCommas} from "../../../utils/ciutils";


export const TransactionFilter = ({setStatus}) => {
	const [inputStatus, setInputStatus] = useState(0)
	const [usageItem, setUsageItem] = useState<any>({})

	// 데이터 조회
	const { userInfo } = useUserInfo();


	useEffect(() => {
		console.log('userInfo', userInfo);

	}, [userInfo]);




	const IsSearch = () => {
		setStatus(inputStatus);
	}



	const ProcessStateItem = [
		{
			name: '구분 전체',
			value : 0
		},
		{
			name: '결제필요',
			value : 1
		},
		{
			name: '결제완료',
			value : 2
		},
	]


	return (
		<DBFilterWrapper style={{ backgroundColor: '#FAFAFC' }}>


			<DBFilterDateArea style={{justifyContent: 'space-between', height: 60, padding: '0 16px'}}>
				<RoundCheckWrapper>
					<Title>
						이용중인 상품
						<BoldTitle>{`${userInfo?.itemName} : 월 ${numberWithCommas(userInfo?.itemPrice) || 0} 원`}</BoldTitle>
					</Title>
				</RoundCheckWrapper>

				<SearchWrapper>
					<StyledSelect value={inputStatus} onChange={(e) => setInputStatus(e as number)}>
						{ProcessStateItem?.map((item, index) => (
							<Select.Option value={item.value}>{item.name}</Select.Option>
						))}
					</StyledSelect>

					<SearchButton onClick={() => IsSearch()}>
						조회
					</SearchButton>
				</SearchWrapper>
			</DBFilterDateArea>
		</DBFilterWrapper>
	)
}

const Title = styled.div`
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	font-family: Pretendard, sans-serif;
	display: flex;
	align-items: center;
	gap: 6px;
`

const BoldTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	font-family: Pretendard, sans-serif;
`


const RoundCheckWrapper = styled.div`
	display: flex;
	gap: 14px;
`

const SearchWrapper = styled.div`
	display: flex;
	gap: 6px;
`


const SearchButton = styled.div`
	background-color: #FF6C4B;
	width: 66px;
	height: 36px;
	border-radius: 4px;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 15px;
	line-height: 17.9px;
	cursor: pointer;
`

const StyledSelect = styled(Select)`
	width: 170px;
	height: 36px;
	border-radius: 4px;
	
`

