import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import {Display} from "../../../CRM/organism/Main/styles";
import {useAdminADPriceList} from "../../hooks/Admin/CommercialFee/GetAdminADPriceList";
import {useAdminAgentPriceList} from "../../hooks/Admin/CommercialFee/GetAdminAgentPriceList";
import API from "../../../utils/api";
import {message} from "antd";
import {useToken} from "../../../app/hooks";
import {useAdminUserSettingList} from "../../hooks/Admin/SettingAdmin/GetAdminSettingList";

export const SettingAdmin = () => {
  // 상태값
  const [datas, setDatas] = useState<any>([]);
  const [id1, setID1] = useState<any>(0);
  const [id2, setID2] = useState<any>(0);
  const [id3, setID3] = useState<any>(0);
  // const [agent1, setAgent1] = useState<any>(0);
  // const [agent2, setAgent2] = useState<any>(0);
  // const [agent3, setAgent3] = useState<any>(0);


  // 데이터 조회
  const {adminUserSettingList, mutateAdminUserSettingList} = useAdminUserSettingList();
  // const {adminADPriceList, mutateAdminADPriceList} = useAdminADPriceList();
  // const {adminAgentPriceList, mutateAdminAgentPriceList} = useAdminAgentPriceList();
  const {data: token} = useToken();

  useEffect(() => {
    console.log('adminUserSettingList', adminUserSettingList);
  }, [adminUserSettingList]);

  useEffect(() => {
    setID1(adminUserSettingList?.find(item => item.id === 1)?.value);
    setID2(adminUserSettingList?.find(item => item.id === 2)?.value);
    setID3(adminUserSettingList?.find(item => item.id === 3)?.value);
  }, [adminUserSettingList]);




  const putAdminPrice = () => {
    const shouldEdit = window.confirm('변경하시겠습니까?');
    if(shouldEdit) {
      API.put('/admin/setting/ad', token + '', {
       datas : [
         {
           id: 1,
           value: id1
         },
         {
           id: 2,
           value: id2
         },
         {
           id: 3,
           value: id3
         }
       ]
      })
        .then((res: any) => {
          if (res.result.success) {
            message.success('변경되었습니다');
            mutateAdminUserSettingList();
          } else {
            message.error(res.result.message || '실패하였습니다');
          }
        })
        .catch((err: any) => {
          message.error('실패하였습니다');
        })
    }
  }


  return(
    <Display>

      <TableTitle>
        광고 최대 개수 설정
        <ChangeFeeButton onClick={() => putAdminPrice()}>
          수정적용
        </ChangeFeeButton>
      </TableTitle>

      <Table>
        <tbody>
        <TableRow>
          <Th>
            <ThLabel>
              외부
            </ThLabel>
          </Th>
          <Td>
            <TdRowContents>
              <Input type="text" value={id1} onChange={(e) => setID1(e.currentTarget.value)}/>
              <div style={{width: 60, marginLeft: 10, fontSize: 16}}>개</div>
            </TdRowContents>
          </Td>
        </TableRow>
        <TableRow>
          <Th>
            <ThLabel>
              매물
            </ThLabel>
          </Th>
          <Td>
            <TdRowContents>
              <Input type="text" value={id2} onChange={(e) => setID2(e.currentTarget.value)}/>
                <div style={{width: 60, marginLeft: 10, fontSize: 16}}>개</div>
            </TdRowContents>
          </Td>
        </TableRow>
        <TableRow>
          <Th>
            <ThLabel>
              외부+매물
            </ThLabel>
          </Th>
          <Td>
            <TdRowContents>
              <Input type="text" value={id3} onChange={(e) => setID3(e.currentTarget.value)}/>
              <div style={{width: 60, marginLeft: 10, fontSize: 16}}>개</div>
            </TdRowContents>
          </Td>
        </TableRow>
        </tbody>
      </Table>


      {/*<TableTitle>*/}
      {/*  매체주*/}
      {/*  /!*<ChangeFeeButton>*!/*/}
      {/*  /!*  요금변경*!/*/}
      {/*  /!*</ChangeFeeButton>*!/*/}
      {/*</TableTitle>*/}

      {/*<Table>*/}
      {/*  <tbody>*/}
      {/*  <TableRow>*/}
      {/*    <Th>*/}
      {/*      <ThLabel>*/}
      {/*        기본*/}
      {/*      </ThLabel>*/}
      {/*    </Th>*/}
      {/*    <Td>*/}
      {/*      <TdRowContents>*/}
      {/*        <Input type="text" value={agent1} onChange={(e) => setAgent1(e.currentTarget.value)}/>*/}
      {/*        <div style={{width: 60, marginLeft: 10, fontSize: 16}}>/월</div>*/}
      {/*      </TdRowContents>*/}
      {/*    </Td>*/}
      {/*  </TableRow>*/}
      {/*  <TableRow>*/}
      {/*    <Th>*/}
      {/*      <ThLabel>*/}
      {/*        인터넷, 스마트TV렌탈 시*/}
      {/*      </ThLabel>*/}
      {/*    </Th>*/}
      {/*    <Td>*/}
      {/*      <TdRowContents>*/}
      {/*        <Input type="text" value={agent2} onChange={(e) => setAgent2(e.currentTarget.value)}/>*/}
      {/*        <div style={{width: 60, marginLeft: 10, fontSize: 16}}>/월</div>*/}
      {/*      </TdRowContents>*/}
      {/*    </Td>*/}
      {/*  </TableRow>*/}
      {/*  <TableRow>*/}
      {/*    <Th>*/}
      {/*      <ThLabel>*/}
      {/*        스마트TV만 렌탈 시*/}
      {/*      </ThLabel>*/}
      {/*    </Th>*/}
      {/*    <Td>*/}
      {/*      <TdRowContents>*/}
      {/*        <Input type="text" value={agent3} onChange={(e) => setAgent3(e.currentTarget.value)}/>*/}
      {/*        <div style={{width: 60, marginLeft: 10, fontSize: 16}}>/월</div>*/}
      {/*      </TdRowContents>*/}
      {/*    </Td>*/}
      {/*  </TableRow>*/}
      {/*  </tbody>*/}
      {/*</Table>*/}

    </Display>
  )
}


const TableTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    color: #000000CC;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`


const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
`;

const TableRow = styled.tr`
    min-height: 40px;
`;

const Th = styled.th`
    padding: 10px;
    text-align: left;
    width: 30%;
    border: 1px solid #ccc;
    background-color: #F4F2FF;
`;

const ThLabel = styled.div`
    font-family: Pretendard, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    color: #00000099;
    display: flex;
    gap: 2px;
`

const RequireIcon = styled.p`
    color: #F15151;
`

const Td = styled.td`
    padding: 10px;
    text-align: left;
    width: 78%;
    border: 1px solid #ccc;
`;

const TdRowContents = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 16.71px;
    font-family: Pretendard, sans-serif;
    color: #000000;
`

const DescText = styled.div`
  font-weight: 400;
    font-size: 14px;
    font-family: Pretendard, sans-serif;
    line-height: 16.71px;
    color: #00000099;
    margin-top: 8px;
    margin-bottom: 20px;
    white-space: pre-wrap;
`

const Input = styled.input`
    width: 50%;
    height: 28px;
    padding-left: 10px;
    border: 1px solid #2E2559;
    border-radius: 4px;
    display: flex;
`;

const ChangeFeeButton = styled.div`
    background-color: #2a29e6;
    color: white;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;

`