import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {numberWithCommas} from "../../../utils/ciutils";


export const DepositTable = ({
                               advertiserTransactionList,
                               mutateAdvertiserTransactionList,
                               total,
                               page,
                               setPage,
                               pageSize,
                               setPageSize, isLoading
                             }) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = advertiserTransactionList?.length > 0 && advertiserTransactionList?.every(it => selectedRows?.includes(it.depositId));
    setIsAllChecked(allSelected);
  }, [selectedRows, advertiserTransactionList]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const columns = [
    {
      dataIndex: '',
      title: 'No',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{data?.depositId || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '구분',
      render: (value, data) => {
        return (
          <div className='email' style={{justifyContent: 'center', display: 'flex'}}>{data?.type || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'content',
      title: '예치금 현황',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '증가',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'flex-end', display: 'flex'}}>{`${numberWithCommas(data?.increaseAmount)|| 0} 원`}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '감소',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'flex-end', display: 'flex'}}>{`${numberWithCommas(data?.decreaseAmount) || 0} 원`}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '날짜',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{data?.createdAt || '-'}</div>
        )
      }
    },
    {
      dataIndex: '',
      title: '수단',
      render: (value, data) => {
        return (
          <div className='type' style={{justifyContent: 'center', display: 'flex', gap: 6, alignItems: 'center'}}>
            <div>{data?.payType  || '-'}</div>
            <div style={{fontSize: 14, color: '#9747FF', cursor: 'pointer'}}>{data?.memo}</div>
          </div>
        )
      }
    },
  ];

  return (
    <DBFilterWrapper>
      <DBFilterCheckboxArea>

        {/*<div style={{display: 'flex', gap: 10, justifyContent: 'space-between', width: '100%',}}>*/}
        {/*  <CheckboxUnit>*/}
        {/*    <DBTableExcelBtn onClick={excelDownAll}>*/}
        {/*      <Icon><FaRegFileExcel /></Icon>*/}
        {/*      엑셀다운로드*/}
        {/*    </DBTableExcelBtn>*/}
        {/*    <DBTableExcelBtn onClick={excelDownSelect}>*/}
        {/*      <Icon><FaRegFileExcel /></Icon>*/}
        {/*      선택 다운로드*/}
        {/*    </DBTableExcelBtn>*/}
        {/*  </CheckboxUnit>*/}

        {/*  <DBTableExcelBtn onClick={clickDelete} style={{backgroundColor: '#f93e3e', color: '#fff'}}>*/}
        {/*    선택 삭제*/}
        {/*  </DBTableExcelBtn>*/}
        {/*</div>*/}

      </DBFilterCheckboxArea>
      <StyleTable columns={columns}
                  dataSource={advertiserTransactionList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

      {/* {orderId !== null && <OrderEdit orderIdd={orderId} setOrderIdd={setOrderId} mutate={mutateOrderList} orderList={orderList} />}*/}

    </DBFilterWrapper>
  )
}

export default React.memo(DepositTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
