import styled from "styled-components";

export const TopBarWrapper = styled.div`
  width: calc(100vw - 280px);
  height: 50px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 700;
`
export const ToolTipWrapper = styled.div`
  background-color: #fff;
  padding: 4px 8px;
`
export const ToolTipDate = styled.p`
  font-size: 15px;
  font-weight: 500;
  span {
    min-width: 50px;
    display: inline-block;
  }
`
export const Blacked = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
`

export const BlackedTwo = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
`

export const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 800px;
  min-width: 800px;
  min-height: 500px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 8px;
  padding: 28px;
  z-index: 99;
`
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ModalTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #101010;
`
export const ModalSubmit = styled.button`
  font-family: 'Pretendard';
  border: 1px solid black;
  background-color: black;
  color: #fff;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
	font-weight: bold;
`

export const HeaderRightDummy = styled.div`
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
  padding: 20px;
`
export const ModalInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .chrome-picker {
    width: 100% !important;
    box-shadow: none !important;
    font-family: 'Pretendard' !important;
    border-radius: 4px !important;
  }
`
export const DBModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0px;
  border-left: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  .ant-pagination {
    display: none;
  }
`
export const DBModalInputLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-content {
    width: 100%;
    border: 1px solid #d8d8d8;
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #d8d8d8;
    &:first-child {
      width: 300px;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 0;
  }
  .ant-table-cell {
    border-radius: 0px !important;
  }
`
export const DBModalInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #d8d8d8;
  border-left: none;
  border-bottom: none;
  height: 100%;
  label {
    font-size: 14px;
    font-weight: 500;
    min-width: 160px;
    max-width: 160px;
    height: 100%;
    padding: 11px 0;
    text-align: center;
    background-color: #f7faf4;
    user-select: none;
  }
  .ant-select {
    width: 100%;
    font-family: Pretendard;
  }
`
export const DBModalInputWrap = styled.div`
  width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
  padding: 4px;
  padding-right: 8px;
  .ant-input {
    font-family: Pretendard;
  }
`
export const DBModalMemoArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`
export const MemoSubmitBtn = styled.button`
  padding: 16px 10px;
  width: 100px;
  border-radius: 4px;
  border: none;
  background-color: #0d6efd;
  color: #fff;
  font-family: Pretendard;
  font-weight: 500;
`
export const DepositWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const DepositInput = styled.div`
  width: 100%;;
  display: flex;
  align-items: center;
  gap: 8px;
  label {
    font-size: 14px;
    color: #696969;
    min-width: 50px;
    text-align: center;
    font-weight: 500;
  }
  span {
    background-color: #E9ECEF;
    padding: 12px;
    width: 300px;
    color: #696969;
  }
`
export const BoardViewInput = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  label {
    display: inline-block;
    min-width: 100px;
    background-color: #f7faf4;
    text-align: center;
    padding: 20px 10px;
  }
  p {
    padding: 20px;
    min-width: 300px;
  }
`
export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d8d8d8;
  padding: 10px;
  h1 {
    font-weight: normal;
    font-size: 16px;
  }
`
export const SetTemplateWrapper = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
`
export const SetTemplateChoose = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 30%;
  justify-content: flex-end;
  button {
    width: 100%;
    padding: 8px 22px;
    font-family: 'Pretendard';
    color: #fff;
    border: none;
    font-size: 18px;
    border-radius: 4px;
    font-weight: 600;
  }
`
export const SetTemplateSelect = styled.div`
  border: 1px solid #d8d8d8;
  width: 100%;
  min-height: 500px;
  border-radius: 8px;
  padding: 20px;
`