import React, {useState, useEffect} from 'react'
import {Table, Checkbox, message} from 'antd'
import {DBFilterCheckboxArea, DBFilterWrapper, DBTableExcelBtn} from '../../../CRM/organism/DB/styles';
import styled from "styled-components";
import {useToken} from "../../../app/hooks";
import {PaymentDetail} from "./blocks/CalculationDetail";
import {formatPhoneNumber} from "../../hooks/util/formatPhoneNumber";
import {numberWithCommas} from "../../../utils/ciutils";


export const AdminCalculationTable = ({
                                        adminRefundList,
                                        mutateAdminRefundList,
                                        selectedRows,
                                        setSelectedRows,
                                        isAllChecked,
                                        setIsAllChecked,
                                        isSelectItem,
                                        total,
                                        page,
                                        setPage,
                                        pageSize,
                                        setPageSize,
                                        isLoading
                                      }) => {
  const [userId, setUserId] = useState(null);
  const {data: token} = useToken();


  useEffect(() => {
    const allSelected = adminRefundList?.length > 0 && adminRefundList?.every(it => selectedRows?.includes(it.refundId));
    setIsAllChecked(allSelected);
  }, [selectedRows, adminRefundList]);


  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows?.filter(rowId => rowId !== id));
    }
  };


  const handleSelectAllChange = (e) => {
    setIsAllChecked(e.target.checked);
    const ids = adminRefundList?.map(db => db.refundId);
    setSelectedRows(e.target.checked ? ids : []);
  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const customerColumns = [
    {
      dataIndex: 'refundId',
      title: isSelectItem ?
        (<Checkbox
          checked={isAllChecked}
          onChange={handleSelectAllChange}
        />)
        :
        'No'
      ,
      render: (value, data, index) => {
        return (
          <>
            {isSelectItem ?
              (<div className='checkbox' style={{justifyContent: 'center', display: 'flex'}}>
                <Checkbox checked={selectedRows?.includes(data.refundId)} key={data?.refundId}
                          onChange={(e) => handleCheckboxChange(e, data?.refundId)}/>
              </div>)
              :
              <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
            }
          </>
        )
      }
    },
    {
      dataIndex: 'name',
      title: '이름',
      render: (value, data) => {
        return (
          <div className='email' onClick={() => setUserId(data?.userId)} style={{
            justifyContent: 'center',
            display: 'flex',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: '아이디',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'store',
      title: '상호명',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value || '-'}</div>
        )
      }
    },
    {
      dataIndex: 'phone',
      title: '휴대폰번호',
      render: (value, data, index) => {
        return (
          <div className='No.' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
        )
      }
    },
    // {
    //   dataIndex: 'account',
    //   title: '계좌정보',
    //   render: (value, data) => {
    //     return (
    //       <div className='type' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
    //     )
    //   }
    // },
    {
      dataIndex: 'amount',
      title: '정산액',
      render: (value, data) => {
        return (
          <div className='date' style={{justifyContent: 'flex-end', display: 'flex',}}>{numberWithCommas(value)+' 원'}</div>
        )
      }
    },
    // {
    //   dataIndex: 'createdAt',
    //   title: '신청일',
    //   render: (value, data) => {
    //     return (
    //       <div className='date' style={{justifyContent: 'center', display: 'flex'}}>{value}</div>
    //     )
    //   }
    // },
    {
      dataIndex: 'status',
      title: '상태',
      render: (value, data) => {
        return (
          <div className='date'
               style={{justifyContent: 'center', display: 'flex',}}>{value === 0 ? '정산필요' : '정산완료'}</div>
        )
      }
    },
  ];


  return (
    <DBFilterWrapper>

      <StyleTable columns={customerColumns}
                  dataSource={adminRefundList}
                  bordered={true}
                  loading={isLoading}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                  }}
                  onChange={handleTableChange}
      />

      {/*{orderId !== null && <OrderEdit orderIdd={orderId} setOrderIdd={setOrderId} mutate={mutateOrderList} orderList={orderList} />}*/}
      {userId !== null && <PaymentDetail userId={userId} setUserId={setUserId}/>}

    </DBFilterWrapper>
  )
}

export default React.memo(AdminCalculationTable);

const ColumnHeader = styled.div`

`

const StyleTable = styled(Table)`
    .ant-table-thead > tr > th {
        background-color: #F4F2FF;
        color: #00000099;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
    }
`
