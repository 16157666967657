import React, {useState} from 'react'
import {Display} from "../../../CRM/organism/Main/styles";
import {TransactionFilter} from "./TransactionFilter";
import {TransactionTable} from "./TransactionTable";
import { useOrderList } from '../../../CRM/hooks/Tosme/OperateManage/Sales/OrderManage/GetOrderList'
import styled from "styled-components";
import {useAgentPaymentList} from "../../hooks/Agent/Transaction/GetPaymentList";


export const Transaction = () => {
  // 필터링 관련
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [status, setStatus] = useState(0); // 1: 결제필요 | 2:결제완료


  const { agentPaymentList, mutateAgentPaymentList, total, isLoading } = useAgentPaymentList(page, pageSize, status)


  return(
    <Display>
      <TransactionFilter
        setStatus={setStatus}
      />
      <TransactionTable
        agentPaymentList={agentPaymentList}
        mutateAgentPaymentList={mutateAgentPaymentList}
        setPage={setPage}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={total}
        isLoading={isLoading}
      />
    </Display>
  )
}

const RegistSection = styled.div`
    display: flex;
    justify-content: flex-end;
`

const ViewMonitor = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19.09px;
    color: #9747FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    cursor: pointer;
`

const OutputFileButton = styled.div`
    background-color: #530DAD;
    box-shadow: 0px 0px 10px 0px #0000000D;
    width: 140px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    font-family: Pretendard ,sans-serif;
`

