import { useWindowDimensions } from '../app/hooks'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
export const wSizing = (width: any, { xs, sm, md, lg }: any) => {
	return width < 768 ? xs : width < 1024 ? sm : width < 1440 ? md : lg
}
export const useCheckTradePage = () => {
	const location = useLocation()
	const urlSegments = location.pathname?.split('/')
	const hasTradeSegment = urlSegments?.some(segment => segment.includes('trade'))
	return hasTradeSegment ? true : false
}
export const useWSize = (size?: any) => {
	const { width } = useWindowDimensions()
	const dynamicSize = size ? size : 0
	const wSize =
		width < 772
			? width / 1.2
			: width < 1400
			? width < 900
				? width / 1.5 - dynamicSize
				: width / 2 - dynamicSize
			: width / 3 - dynamicSize
	return wSize
}

export const SliderSize = () => {
	const { width } = useWindowDimensions()
	return width > 1400 ? 4 : width > 1200 ? 3 : width > 600 ? 2 : 1
}
export const WindowSize = (large: any, medium: any, small: any) => {
	const { width } = useWindowDimensions()
	return width > 900 ? large : width > 600 ? medium : small
}
export const checkEmail = email => {
	return true;
}
export const wait = timeout => {
	return new Promise(resolve => setTimeout(resolve, timeout))
}

export const apiError = (t, code) => {
	if (code === 1) {
		return t('LANG_NO_INPUT')
	} else if (code === 2) {
		return t('LANG_NO_USER')
	} else if (code === 3) {
		return t('LANG_FAIL_PASSWORD')
	} else if (code === 4) {
		return t('LANG_EXIST_USER')
	} else if (code === 5) {
		return t('LANG_NO_INPUT_EMAIL')
	} else if (code === 6) {
		return t('LANG_NO_INPUT_PHONE')
	} else if (code === 7) {
		return t('LANG_ERROR')
	} else if (code === 8) {
		return t('LANG_INVALID_REFERRAL')
	} else {
		return t('LANG_ERROR')
	}
}